<template>
  <main class="w-100 flex-grow-1 overflow-hidden">
    <router-view
      class="overflow-auto"
      :metric="metricFilters?.key"
      :fetch-key="fetchKey"
      :filters="filters"
      :period-filter="timeFilters?.periodFilter"
      :time-period="timeFilters?.dropDownLabel ?? 'Last 30 days'"
    ></router-view>
  </main>
</template>

<script>
import { useStore } from "vuex";
import { computed, ref, watch } from "vue";

export default {
  setup() {
    const store = useStore();
    const fetchKey = ref(0);

    const filters = computed(() => {
      return store.getters.filters;
    });

    const metricFilters = computed(
      () => store.state.overViewStore.metricFilters
    );

    const timeFilters = computed(() => store.state.overViewStore.timeFilters);

    watch(filters, () => {
      fetchKey.value += 1;
    });

    return {
      filters,
      metricFilters,
      timeFilters,
      fetchKey,
    };
  },
};
</script>

<style lang="scss" scoped></style>
