<template>
  <div class="h-100 pb-4 px-4 hide-scrollbar">
    <div>
      <div class="mt-4 pt-2 w-100 h-100 d-flex flex-column">
        <BaseViewInfoText
          v-if="tableData?.tenant_total_customers_count"
          class="mb-3"
          :text="`Showing ${compactNumber(
            tableData?.tenant_total_customers_count
          )} users from ${formatDate(
            timeFilters?.rangePeriodFilter?.from
          )} to ${formatDate(timeFilters?.rangePeriodFilter?.to)}`"
        />
        <UsersList :search-qry="searchQry" @data-updated="updateListData" />
      </div>
    </div>
  </div>
</template>
<script>
import BaseViewInfoText from "../components/helpers/BaseViewInfoText.vue";
import UsersSearch from "../components/UsersSearch.vue";
import UsersList from "../components/UsersList.vue";
import { useStore } from "vuex";
import { computed, onUnmounted, ref, inject } from "vue";
import moment from "moment";
export default {
  components: {
    BaseViewInfoText,
    UsersSearch,
    UsersList,
  },
  props: {
    categoryId: {
      type: String,
      default: "",
    },
  },
  setup() {
    const store = useStore();
    const tableData = ref({});
    const searchQry = ref("");
    const compactNumber = inject("compactNumber");
    // const filters = computed(() => {
    //   return store.getters.filters;
    // });
    // const metricFilters = computed(
    //   () => store.state.overViewStore.metricFilters
    // );
    const timeFilters = computed(() => store.state.overViewStore.timeFilters);

    //hooks
    onUnmounted(() => {
      store.dispatch("CANCEL_PENDING_REQUESTS");
    });

    // === functions ===
    const formatDate = (date) => {
      return moment(date).format("D MMM, YYYY");
    };
    const updateUserTable = (searchQry) => {
      console.log("Fetch List: ", searchQry);
    };

    const setSearchQry = (qry) => {
      searchQry.value = qry;
    };
    const updateListData = (data) => {
      tableData.value = { ...data };
    };

    //watchers
    // watch(
    //   () => filters.value,
    //   () => {
    //     //[1] Abort all previous pending requests
    //     store.dispatch("CANCEL_PENDING_REQUESTS");

    //     //[2] Fetch new requests
    //     fetchData();
    //   },
    //   {
    //     deep: true,
    //   }
    // );

    return {
      tableData,
      formatDate,
      updateUserTable,
      timeFilters,
      setSearchQry,
      updateListData,
      compactNumber,
      searchQry,
    };
  },
};
</script>
<style>
.hide-scrollbar {
  -ms-overflow-style: none; /* Internet Explorer 10+ */
  scrollbar-width: none; /* Firefox */
}
.hide-scrollbar::-webkit-scrollbar {
  display: none; /* Safari and Chrome */
}
</style>
