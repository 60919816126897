<template>
  <div>
    <h2 v-if="!brandLoading">
      {{ brandDetails?.name ?? "--" }}
    </h2>
    <h2 v-else class="opacity-75">loading...</h2>
  </div>
</template>

<script>
import { computed } from "vue";
import { useStore } from "vuex";

export default {
  setup() {
    const store = useStore();

    const brandDetails = computed(
      () => store.state.allBrandsStore.specificBrandDetails
    );
    const brandLoading = computed(
      () => store.state.allBrandsStore.specificBrandLoading
    );

    return { brandDetails, brandLoading };
  },
};
</script>

<style lang="scss" scoped></style>
