<template>
  <div
    class="
      px-4
      w-100
      d-flex
      flex-column
      justify-content-between
      align-items-start
      mt-1
    "
  >
    <div class="d-flex flex-column justify-content-start w-100">
      <!-- Base back button -->
      <BasePageBackButton v-if="$route.meta?.showBackButton" />
      <!-- Base back button -->

      <!-- Base bread crumb -->
      <BaseBreadCrumb v-if="!$route.meta?.showBackButton" />
      <!-- Base bread crumb -->

      <!-- Page title bar -->
      <BasePageTitleBar />
      <!-- Page title bar -->
    </div>
  </div>
</template>

<script>
import BasePageBackButton from "./BasePageBackButton.vue";
import BasePageTitleBar from "./BasePageTitleBar.vue";
import BaseBreadCrumb from "./BaseBreadCrumb.vue";
import { watch } from "vue";
import { useStore } from "vuex";
import { useRoute } from "vue-router";
export default {
  components: { BaseBreadCrumb, BasePageTitleBar, BasePageBackButton },
  setup() {
    const store = useStore();
    const route = useRoute();

    function getSpecificBrandDetails() {
      store.dispatch("getSpecificBrandDetails", route.params?.brandId);
    }

    watch(
      () => route.name,
      (currentRoute) => {
        if (["SpecificBrand"].includes(currentRoute)) {
          //fetch customer name
          getSpecificBrandDetails();
        }
      },
      {
        immediate: true,
      }
    );

    return {};
  },
};
</script>

<style lang="scss" scoped></style>
