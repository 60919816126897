<template>
  <li
    v-for="(item, index) in breadCrumbList"
    :key="item?.name"
    class="p-0 m-0"
    :class="{ 'last-item': index + 1 === breadCrumbList.length }"
    @click="routeTo(index + 1 === breadCrumbList.length ? null : item)"
  >
    <span class="li-label-ba"> {{ item?.label }}</span>
    <span v-if="index + 1 < breadCrumbList.length" class="ps-2"> > </span>
  </li>
</template>

<script>
import { useRouter } from "vue-router";

export default {
  props: {
    breadCrumbList: {
      type: Array,
      default: () => [],
    },
  },
  setup() {
    const router = useRouter();

    const routeTo = (selectedRoute) => {
      if (selectedRoute?.name)
        router.push({
          name: selectedRoute?.name,
        });
    };

    return { routeTo };
  },
};
</script>

<style lang="scss" scoped></style>
