<template>
  <div class="position-relative copy-popover-main">
    <div v-if="!customBody" class="text-truncate">
      {{ text ?? "--" }}
    </div>
    <slot name="body"></slot>
    <CopyPopover
      v-if="!disable"
      :data="text"
      :toggle="toggle"
      :copy-icon="showCopy"
    />
  </div>
</template>

<script>
import CopyPopover from "@/components/CopyPopover.vue";
export default {
  components: { CopyPopover },
  props: {
    text: {
      type: String,
      default: "",
    },
    toggle: {
      type: Boolean,
      default: false,
    },
    showCopy: {
      type: Boolean,
      default: true,
    },
    customBody: {
      type: Boolean,
      default: false,
    },
    disable: {
      type: Boolean,
      default: false,
    },
  },
};
</script>

<style lang="scss" scoped></style>
