<template>
  <div>
    <div class="d-flex gap-5 justify-content-start align-items-start">
      <BaseSortButton
        :label="
          (metricFilters?.key === 'average' ? 'Average' : 'Total') + ' Amount'
        "
        :sort-is-active="sortOption == sortOptions.spend"
        :reverse-sorting="sortOption == 'reverse-0'"
        @click="sortData(sortOptions.spend)"
      />
      <BaseSortButton
        label="No. of customers"
        :sort-is-active="sortOption == sortOptions.customers"
        :reverse-sorting="sortOption == 'reverse-1'"
        @click="sortData(sortOptions.customers)"
      />
    </div>
  </div>
</template>

<script>
import { useStore } from "vuex";
import BaseSortButton from "./helpers/BaseSortButton.vue";
import { ref, reactive, computed, watch } from "vue";
export default {
  components: { BaseSortButton },
  props: {
    modelValue: {
      type: [String, Number],
      default: 0,
    },
  },
  emits: ["update:modelValue"],

  setup(props, { emit }) {
    const store = useStore();
    const sortOption = ref(0);
    const sortOptions = reactive({
      spend: 0,
      customers: 1,
    });
    const metricFilters = computed(
      () => store.state.overViewStore.metricFilters
    );

    function sortData(option) {
      sortOption.value =
        sortOption.value === option ? `reverse-${option}` : option;
    }

    watch(
      sortOption,
      (sort) => {
        emit("update:modelValue", sort);
      },
      { immediate: true }
    );

    return { sortOption, sortOptions, sortData, metricFilters };
  },
};
</script>

<style lang="scss" scoped></style>
