<template>
  <div v-if="(breadCrumbList ?? []).length !== 0" class="mb-1 breadcrumb-list">
    <nav>
      <ol class="d-flex justify-content-start align-items-center gap-2">
        <BaseBreadCrumpListItem :bread-crumb-list="breadCrumbList" />
      </ol>
    </nav>
  </div>
</template>

<script>
import BaseBreadCrumpListItem from "../BaseBreadCrumpListItem.vue";

export default {
  components: { BaseBreadCrumpListItem },
  props: {
    breadCrumbList: {
      type: Array,
      default: () => [],
    },
  },
};
</script>

<style lang="scss">
.breadcrumb-list {
  ol {
    list-style-type: none;
    padding: 0;
    margin: 0;

    li {
      color: var(--sea);
      cursor: pointer;
      font-size: 0.94rem;

      &.last-item .li-label-ba {
        color: var(--dark-green-60, #66827f);
        text-decoration: none;
        cursor: auto;
      }

      .li-label-ba {
        transition: var(--d-ease);
        text-decoration: underline;
        &:hover {
          color: var(--green);
        }
      }
    }
  }
}
</style>
