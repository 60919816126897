<template>
  <LogoIcon
    :src="getIcon(brandDetails?.logo)"
    brand-icon-size="43px"
    :is-brand-icon="true"
    loading="lazy"
    :custom-loading="brandLoading"
  />
</template>

<script>
import LogoIcon from "./LogoIcon.vue";
import { computed } from "vue";
import { useStore } from "vuex";

export default {
  components: { LogoIcon },
  setup() {
    const store = useStore();

    const getIcon = (url) => {
      return `${process.env.VUE_APP_IMG_ORIGINAL_BASE_URL}${url}`;
    };

    const brandDetails = computed(
      () => store.state.allBrandsStore.specificBrandDetails
    );
    const brandLoading = computed(
      () => store.state.allBrandsStore.specificBrandLoading
    );

    return { brandDetails, brandLoading, getIcon };
  },
};
</script>

<style lang="scss" scoped></style>
