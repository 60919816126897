<template>
  <div v-if="!isChartEmpty" class="d-flex flex-column w-100">
    <div class="d-flex flex-nowrap align-items-center">
      <div class="flex-grow-1">
        <CashflowLabel
          color="#469B93"
          title="Inflow"
          :amount="inflow"
          :currency="$store.state.currency ?? 'AED'"
          :diff-sign="inflowDiff?.diffSign"
        />
      </div>
      <div class="mx-5">
        <img
          src="@/assets/images/svg/components/v-line-md.svg"
          alt="vertical line"
          height="40"
        />
      </div>
      <div class="flex-grow-1">
        <CashflowLabel
          color="#E86161"
          title="Outflow"
          :amount="outflow"
          :currency="$store.state.currency ?? 'AED'"
          :diff-sign="outflowDiff?.diffSign"
        />
      </div>
    </div>
    <div class="flex-grow-1"><BaseDoughnutChart :data="chartData" /></div>
    <div>
      <h6
        class="m-0 pt-2"
        :style="`visibility: ${footerMsg?.message ? 'visible' : 'hidden'}`"
      >
        {{ footerMsg?.message }} <strong>{{ footerMsg?.value }}</strong>
      </h6>
    </div>
  </div>
  <BaseEmptyState v-else min-height="45vh" />
</template>
<script>
import { computed } from "vue";
import CashflowLabel from "@/components/CashflowLabel.vue";
import BaseDoughnutChart from "@/components/charts/BaseDoughnutChart.vue";
import BaseEmptyState from "@/components/helpers/BaseEmptyState.vue";
import { useDiffCalculator } from "@/composables/Helpers";

export default {
  components: { CashflowLabel, BaseDoughnutChart, BaseEmptyState },
  props: {
    data: {
      type: Object,
      default: () => {},
    },
  },
  setup(props) {
    const chartData = computed(() => ({
      datasets: [
        {
          data: [
            Math.abs(props.data?.cashflow?.inflow ?? 0),
            Math.abs(props.data?.cashflow?.outflow ?? 0),
          ],
          backgroundColor: ["#335855", "#FFEBEB"],
          borderWidth: 0,
        },
      ],
    }));

    const inflow = computed(() => Math.abs(props.data?.cashflow?.inflow ?? 0));
    const relInflow = computed(() =>
      Math.abs(props.data?.cashflow?.rel_inflow ?? 0)
    );
    const outflow = computed(() =>
      Math.abs(props.data?.cashflow?.outflow ?? 0)
    );
    const relOutflow = computed(() =>
      Math.abs(props.data?.cashflow?.rel_outflow ?? 0)
    );
    const inflowDiff = computed(() =>
      useDiffCalculator(inflow.value, relInflow.value)
    );
    const outflowDiff = computed(() =>
      useDiffCalculator(outflow.value, relOutflow.value)
    );
    const isChartEmpty = computed(
      () => inflow.value === 0 && outflow.value === 0
    );

    const percentages = computed(() => {
      let sum = 0;
      let dataArr = [inflow.value, outflow.value];
      dataArr.map((data) => {
        sum += data;
      });
      let inflowPerc = Math.abs(((inflow.value * 100) / sum).toFixed(0)) + "%";
      let outflowPerc =
        Math.abs(((outflow.value * 100) / sum).toFixed(0)) + "%";
      return { inflowPerc, outflowPerc };
    });

    const footerMsg = computed(() => {
      let foot = { message: "", value: 0 };

      switch (true) {
        case inflow.value > outflow.value:
          foot.message = "Inflows higher than outflow by ";
          foot.value = percentages.value?.inflowPerc;
          break;
        case outflow.value > inflow.value:
          foot.message = "Outflows higher than inflow by ";
          foot.value = percentages.value?.outflowPerc;
          break;
        case outflow.value !== 0 &&
          inflow.value !== 0 &&
          outflow.value === inflow.value:
          foot.message = "Inflows & Outflows are equal by";
          foot.value = 50 + "%";
          break;
      }
      return foot;
    });

    return {
      inflow,
      outflow,
      inflowDiff,
      outflowDiff,
      footerMsg,
      isChartEmpty,
      chartData,
    };
  },
};
</script>
<style lang=""></style>
