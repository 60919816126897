export default {
  state() {
    return {
      selectedCurrencyOverview: {},
      selectedCurrency: {},
      currencyCancelTokens: [],
    };
  },
  getters: {
    currencyCancelTokens(state) {
      return state.currencyCancelTokens;
    },
  },
  mutations: {
    CURRENCY_ADD_CANCEL_TOKEN(state, token) {
      state.currencyCancelTokens.push(token);
    },
    CURRENCY_CLEAR_CANCEL_TOKENS(state) {
      state.currencyCancelTokens = [];
    },
    updateSelectedCurrency(state, payload) {
      state.selectedCurrency = { ...payload };
    },
    updateSelectedCurrencyOverview(state, payload) {
      state.selectedCurrencyOverview = { ...payload };
    },
  },
  actions: {
    CURRENCY_CANCEL_PENDING_REQUESTS(context, forceAbort) {
      // only cancel when both needed requests are added
      if (context.state.currencyCancelTokens?.length === 2 || forceAbort) {
        // Cancel all request where a token exists
        context.state.currencyCancelTokens.forEach((request) => {
          request?.abort();
        });

        // Reset the currencyCancelTokens store
        context.commit("CURRENCY_CLEAR_CANCEL_TOKENS");
      }
    },
  },
};
