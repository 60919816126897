<template>
  <BaseFilterDropDown
    v-show="
      !'hiddenFilters' in $route.meta ||
      !$route.meta?.hiddenFilters?.includes('metric')
    "
    v-model="selectedMetricState"
    :options="metricsOptions"
  />
</template>

<script>
import BaseFilterDropDown from "./BaseFilterDropDown.vue";
import { computed, onMounted, ref, watch } from "vue";
import { useStore } from "vuex";
import { metrics } from "@/data/options";

export default {
  components: { BaseFilterDropDown },
  emits: ["metric-change"],
  setup(props, { emit }) {
    const store = useStore();

    const selectedMetricState = ref();

    const metricFilters = computed(
      () => store.state.overViewStore.metricFilters
    );

    const metricsOptions = computed(() => {
      let metricsList = [];
      for (let key in metrics) {
        metricsList.push({
          key,
          value: metrics[key]?.label,
        });
      }
      return metricsList;
    });

    watch(
      () => selectedMetricState.value,
      (newVal, oldVal) => {
        if (newVal?.key !== oldVal?.key) {
          //[1] Update store
          store.commit("updateSelectedMetrics", { ...newVal });

          //[2] emit
          emit("metric-change", newVal);
        }
      }
    );

    watch(
      () => metricFilters.value,
      (newState, oldState) => {
        if (newState && oldState && newState !== oldState) {
          selectedMetricState.value = { ...newState };
        }
      },
      {
        immediate: true,
      }
    );

    return {
      selectedMetricState,
      metricFilters,
      metricsOptions,
    };
  },
};
</script>

<style lang="scss" scoped></style>
