<template>
  <div v-if="isFiltersToggleBtnShown" class="pt-1 pb-3 px-4">
    <TopBarButtons />
  </div>

  <div v-show="isFiltersShown" class="w-100 filters-top-bar px-4">
    <FiltersTopBar class="py-3" />
  </div>
</template>

<script>
import FiltersTopBar from "../FiltersTopBar.vue";
import TopBarButtons from "../TopBarButtons.vue";
import { computed } from "vue";
import { useRoute } from "vue-router";
import { useStore } from "vuex";

export default {
  components: { TopBarButtons, FiltersTopBar },
  setup() {
    const store = useStore();
    const route = useRoute();

    const showFiltersBar = computed(
      () => store.state.overViewStore.showFiltersBar
    );

    const isFiltersToggleBtnShown = computed(() => {
      return !route.meta?.hideAllMainFilters;
    });

    const isFiltersShown = computed(() => {
      let show = showFiltersBar.value;

      if (route.meta?.hideAllMainFilters) {
        show = false;
      }

      return show;
    });

    return { isFiltersShown, isFiltersToggleBtnShown };
  },
};
</script>

<style lang="scss" scoped></style>
