<template>
  <a class="back-btn" @click="routeBack">&#x3c; Back</a>
</template>

<script>
import { useRouter } from "vue-router";

export default {
  setup() {
    const router = useRouter();

    function hasHistory() {
      return window.history.length > 2;
    }

    function routeBack() {
      hasHistory() ? router.go(-1) : router.push("/");
    }
    return { routeBack };
  },
};
</script>

<style lang="scss" scoped>
a.back-btn {
  width: fit-content;
  color: var(--sea);
  cursor: pointer;
  font-size: 0.94rem;

  &:hover {
    color: var(--green);
  }
}
</style>
