import { useDiffCalculator } from "./Helpers";
import moment from "moment";
import store from "../store/index";
import { countries } from "@/data/options";

function compactNumber(number, digits = 0) {
  const formatter = Intl.NumberFormat("en", {
    notation: "compact",
    ...(digits && { minimumFractionDigits: digits }),
    ...(digits && { maximumFractionDigits: digits }),
  });
  let n = formatter.format(number);
  return n;
}

function getCountry(countryKey) {
  return countries[countryKey] ?? null;
}

// === [1] Setting up the Tooltip===
const getOrCreateTooltip = (chart) => {
  //Create tooltip root element
  let tooltipEl = chart.canvas.parentNode.querySelector("div");

  if (!tooltipEl) {
    tooltipEl = document.createElement("div");
    tooltipEl.style.background = "#ffffff";
    tooltipEl.style.borderRadius = "10px";
    tooltipEl.style.color = "#002E2A";
    tooltipEl.style.boxShadow = "0px 4px 50px 0px rgba(236, 236, 236, 0.85)";
    tooltipEl.style.opacity = 1;
    tooltipEl.style.position = "absolute";
    tooltipEl.style.zIndex = "10";
    tooltipEl.style.transform = "translate(-50%, 0)";
    tooltipEl.style.minWidth = "245px";
    tooltipEl.style.maxWidth = "245px";
    tooltipEl.style.transition = "all .2s ease";

    const table = document.createElement("table");
    table.style.width = "100%";

    const tDiv = document.createElement("div");
    tDiv.style.padding = "6px";
    tDiv.appendChild(table);
    tooltipEl.appendChild(tDiv);
    chart.canvas.parentNode.appendChild(tooltipEl);
  }

  return tooltipEl;
};

// === [2] Default configs Tooltip===
const setDefaultConfigs = (context) => {
  const { chart, tooltip } = context;
  const tooltipEl = getOrCreateTooltip(chart);

  // Hide if no tooltip
  if (tooltip.opacity === 0) {
    tooltipEl.style.opacity = 0;
    return {};
  }
  tooltipEl.style.pointerEvents = "auto";

  //== Magic Keep showing the tooltip on Hovering on it  ==
  tooltipEl.addEventListener("mouseout", () => {
    tooltipEl.style.opacity = 0;
    tooltipEl.style.pointerEvents = "none";
  });

  tooltipEl.addEventListener("mouseover", () => {
    tooltipEl.style.opacity = 1;
    tooltipEl.style.pointerEvents = "auto";
  });

  return { chart, tooltip, tooltipEl };
};

function userBalanceChartTooltip(data) {
  return (context) => {
    const { chart, tooltip, tooltipEl } = setDefaultConfigs(context);
    //if tooltip is hidden just exit
    if (!chart) return;

    // Set Text
    const pointKey = tooltip?.dataPoints[0]?.raw?.x;

    if (pointKey) {
      //filter and selected current point
      let selectedDataPoint = data?.balances?.filter(
        (point) => point.date === pointKey
      );

      if (selectedDataPoint.length !== 0)
        selectedDataPoint = selectedDataPoint[0];
      else return;

      //[1] current balance
      let currentBalance = selectedDataPoint?.value;

      //[2] previous balance
      let relBalance = selectedDataPoint?.rel_value;

      //[3] balance diff
      const balanceDiff = useDiffCalculator(
        currentBalance ?? 0,
        relBalance ?? 0
      );

      //   console.log(currentBalance);
      //   console.log(relBalance);
      //   console.log(balanceDiff);

      const tableBody = document.createElement("tbody");
      tableBody.classList.add("tooltip-tbody");

      //Row 1
      const tRow_1 = document.createElement("tr");
      const tData_01 = document.createElement("td");
      tData_01.setAttribute("colspan", 2);

      //wrapper
      const tData_01_Div = document.createElement("div");
      //Set Date
      const tData_01_H5 = document.createElement("h5");
      tData_01_H5.innerHTML = moment(selectedDataPoint.date).format(
        "D MMMM, YYYY"
      );

      //Set Image
      const tData_01_Img = document.createElement("img");
      tData_01_Img.src = require(`@/assets/images/svg/components/${
        balanceDiff?.diffSign ? "Trending-UP.svg" : "Trending-Down.svg"
      }`);

      tData_01_Div.appendChild(tData_01_H5);
      tData_01_Div.appendChild(tData_01_Img);
      tData_01.appendChild(tData_01_Div);
      tRow_1.appendChild(tData_01);

      //Row 2
      const tRow_2 = document.createElement("tr");
      const tData_11 = document.createElement("td");
      tData_11.setAttribute("colspan", 2);
      //   const tData_12 = document.createElement("td");

      //Wrapper
      const tData_11_Temp = document.createElement("div");
      tData_11_Temp.innerHTML = `
              <div class='d-flex flex-column'>
                <h6>Balance</h6>
                <h5>${compactNumber(currentBalance, 2)} ${
        data.currency ?? "AED"
      }</h5>
              </div>`;

      //   const tData_12_Temp = document.createElement("div");
      //   tData_12_Temp.innerHTML = `
      //           <div class='d-flex flex-column ps-4'>
      //             <h6>Previous Balance</h6>
      //             <h5>${currentBalance}</h5>
      //           </div>`;

      tData_11.appendChild(tData_11_Temp);
      //   tData_12.appendChild(tData_12_Temp);

      tRow_2.appendChild(tData_11);
      //   tRow_2.appendChild(tData_12);

      //Row 3
      const tRow_3 = document.createElement("tr");
      const tData_21 = document.createElement("td");
      tData_21.setAttribute("colspan", 2);

      //Wrapper
      const tData_21_Temp = document.createElement("div");
      tData_21_Temp.innerHTML = `
              <div class='d-flex flex-column'>
                <h6>Relative Balance</h6>
                <h5>${compactNumber(relBalance, 2)} ${
        data.currency ?? "AED"
      }</h5>
              </div>`;

      tData_21.appendChild(tData_21_Temp);

      tRow_3.appendChild(tData_21);

      //Row 4
      //   const tRow_4 = document.createElement("tr");
      //   const tData_31 = document.createElement("td");
      //   tData_31.setAttribute("colspan", 2);

      //   //Wrapper
      //   const tData_31_Temp = document.createElement("div");
      //   tData_31_Temp.innerHTML = `<div></div>`;

      //   const toolTipButton = document.createElement("button");
      //   toolTipButton.innerHTML = "View transactions";
      //   // toolTipButton.addEventListener("click", () => {
      //   //   tooltipBtnClicked(selectedDataPoint);
      //   // });

      //   tData_31_Temp.appendChild(toolTipButton);
      //   tData_31.appendChild(tData_31_Temp);

      //   tRow_4.appendChild(tData_31);
      //   // tRow_4.appendChild(tData_32);

      //Add all rows to tBody
      tableBody.appendChild(tRow_1);
      tableBody.appendChild(tRow_2);
      tableBody.appendChild(tRow_3);
      //   tableBody.appendChild(tRow_4);

      //Add tBody to root table
      const tableRoot = tooltipEl.querySelector("table");
      tableRoot.appendChild(tableBody);

      // Remove old children from previous hovering event
      while (tableRoot.firstChild) {
        tableRoot.firstChild.remove();
      }
      // Add new children
      tableRoot.appendChild(tableBody);
    }

    // == Postioning it  ==
    const { offsetLeft: positionX, offsetTop: positionY } = chart?.canvas;

    let leftPosition = positionX + tooltip?.caretX;
    //left start point
    if (leftPosition < 75) leftPosition += 50;
    else if (leftPosition > 800) leftPosition -= 80;

    // Display, position, and set styles for font
    tooltipEl.style.opacity = 1;
    tooltipEl.style.left = leftPosition + "px";
    tooltipEl.style.top = positionY + tooltip.caretY + 27 + "px";
    tooltipEl.style.font = tooltip.options.bodyFont.string;
    tooltipEl.style.padding =
      tooltip.options.padding + "px " + tooltip.options.padding + "px";
  };
}
function userCategoriesSpendChartTooltip(data, viewAllCallback) {
  return (context) => {
    const { chart, tooltip, tooltipEl } = setDefaultConfigs(context);
    //if tooltip is hidden just exit
    if (!chart) return;

    // Set Text
    const pointKey = tooltip?.dataPoints[0]?.raw?.x;

    if (pointKey) {
      //filter and selected current point
      // console.log(data?.categories, pointKey); //category_desc
      let selectedDataPoint = Object.entries(data?.categories)?.filter(
        (point) => {
          return point[1].name === pointKey;
        }
      );

      if (selectedDataPoint.length !== 0) {
        selectedDataPoint = selectedDataPoint[0][1];
      } else return;
      const metricKey =
        store.state?.overViewStore?.metricFilters.key ?? "average";
      const userCurrency = store.getters.selectedUserCurrency ?? "AED";

      //[1] current amount
      const amountLabel =
        metricKey === "average" ? "Average Tx Amount" : "Total Amount";
      const amountValue =
        metricKey === "average"
          ? selectedDataPoint?.avg_spend_per_tx
          : selectedDataPoint?.total_spend;

      //[2] Get top sub-category
      const subCategoriesList = selectedDataPoint?.sub_categories ?? [];
      let topSubCat = null;
      let topSubCatPrecent = 0;

      if (subCategoriesList.length !== 0) {
        const subCatAmountKey =
          metricKey === "average" ? "avg_tx_spend" : "total_spend";
        topSubCat = subCategoriesList.reduce((prev, curr) => {
          //this will return the object that has the highest top spend + prev starts with first object
          return (prev[subCatAmountKey] ?? 0) > (curr[subCatAmountKey] ?? 0)
            ? prev
            : curr;
        });

        const subCatSum = subCategoriesList.reduce((prev, curr) => {
          return prev + curr[subCatAmountKey] ?? 0;
        }, 0);

        topSubCatPrecent = (topSubCat[subCatAmountKey] ?? 0) / subCatSum;
        // console.log(isFinite(topSubCatPrecent));
        topSubCatPrecent = isFinite(topSubCatPrecent)
          ? Math.round(topSubCatPrecent * 100)
          : 0;

        // console.log("Top Spending Cat: ", topSubCat?.sub_category_name);
        // console.log("Top Spending Cat: ", subCatSum);
      }

      const tableBody = document.createElement("tbody");
      tableBody.classList.add("tooltip-tbody");

      //Row 1
      const tRow_1 = document.createElement("tr");
      const tData_01 = document.createElement("td");
      tData_01.setAttribute("colspan", 2);

      //wrapper
      const tData_01_Div = document.createElement("div");
      //Set Date
      const tData_01_H5 = document.createElement("h5");
      tData_01_H5.innerHTML = selectedDataPoint?.category_desc;

      tData_01_Div.appendChild(tData_01_H5);
      tData_01.appendChild(tData_01_Div);
      tRow_1.appendChild(tData_01);

      //Row 2
      const tRow_2 = document.createElement("tr");
      const tData_11 = document.createElement("td");
      tData_11.setAttribute("colspan", 2);
      //   const tData_12 = document.createElement("td");

      //Wrapper
      const tData_11_Temp = document.createElement("div");
      tData_11_Temp.innerHTML = `
              <div class='d-flex flex-column'>
                <h6>${amountLabel}</h6>
                <h5>${compactNumber(amountValue, 2)} ${userCurrency}</h5>
              </div>`;

      tData_11.appendChild(tData_11_Temp);
      tRow_2.appendChild(tData_11);

      //Row 3
      const tRow_3 = document.createElement("tr");
      const tData_21 = document.createElement("td");
      tData_21.setAttribute("colspan", 2);

      //Wrapper
      const tData_21_Temp = document.createElement("div");
      tData_21_Temp.innerHTML = `
              <div class='d-flex flex-column'>
                <h6>Top Sub category</h6>
                <div class="d-flex justify-content-between align-items-center w-100">
                <h5>${topSubCat?.sub_category_name ?? "--"}</h5>
                <h5 class="tooltip-percent me-2">${topSubCatPrecent}%</h5>
                </div>
                
              </div>`;

      tData_21.appendChild(tData_21_Temp);
      tRow_3.appendChild(tData_21);

      //Row 4
      const tRow_4 = document.createElement("tr");
      const tData_31 = document.createElement("td");
      tData_31.setAttribute("colspan", 2);

      //Wrapper
      const tData_31_Temp = document.createElement("div");
      tData_31_Temp.innerHTML = `<div></div>`;

      const toolTipButton = document.createElement("button");
      toolTipButton.innerHTML = "View transactions";
      toolTipButton.addEventListener("click", () => {
        viewAllCallback(selectedDataPoint);
      });

      tData_31_Temp.appendChild(toolTipButton);
      tData_31.appendChild(tData_31_Temp);

      tRow_4.appendChild(tData_31);

      //Add all rows to tBody
      tableBody.appendChild(tRow_1);
      tableBody.appendChild(tRow_2);
      // tableBody.appendChild(tRow_3);
      // tableBody.appendChild(tRow_4);

      //Add tBody to root table
      const tableRoot = tooltipEl.querySelector("table");
      tableRoot.appendChild(tableBody);

      // Remove old children from previous hovering event
      while (tableRoot.firstChild) {
        tableRoot.firstChild.remove();
      }
      // Add new children
      tableRoot.appendChild(tableBody);
    }

    // == Postioning it  ==
    const { offsetLeft: positionX, offsetTop: positionY } = chart?.canvas;

    let leftPosition = positionX + tooltip?.caretX;
    //left start point
    if (leftPosition < 75) leftPosition += 50;
    else if (leftPosition > 800) leftPosition -= 80;

    // Display, position, and set styles for font
    tooltipEl.style.opacity = 1;
    tooltipEl.style.left = leftPosition + "px";
    tooltipEl.style.top = positionY + tooltip.caretY + 27 + "px";
    tooltipEl.style.font = tooltip.options.bodyFont.string;
    tooltipEl.style.padding =
      tooltip.options.padding + "px " + tooltip.options.padding + "px";
  };
}
function ageBarChartTooltip(data, viewAllCallback) {
  return (context) => {
    const { chart, tooltip, tooltipEl } = setDefaultConfigs(context);
    //if tooltip is hidden just exit
    if (!chart) return;

    // Set Text
    const pointKey = tooltip?.dataPoints[0]?.raw?.x;

    if (pointKey) {
      //filter and selected current point
      let selectedDataPoint = data.filter((point) => {
        return point.tenant_customers_age_label === pointKey;
      });

      if (selectedDataPoint.length !== 0) {
        selectedDataPoint = selectedDataPoint[0];
      } else return;

      //[1] User currency
      const userCurrency = store.state.currency ?? "AED";

      //[2] Total Amount
      const totalAmount = Math.abs(
        selectedDataPoint?.tenant_customers_total_spend ?? 0
      );

      //[3] Customer Count
      const customersCount = selectedDataPoint?.tenant_customers_count ?? 0;

      //[4] Avg Per Customer
      const avgSpendPerCustomer = Math.abs(
        selectedDataPoint?.tenant_customers_avg_spend ?? 0
      );

      //[5] Calc Value Diff
      // const userCountDiff = useDiffCalculator(
      //   selectedDataPoint?.value ?? 0,
      //   selectedDataPoint?.rel_value ?? 0
      // );
      // console.log(selectedDataPoint);

      const tableBody = document.createElement("tbody");
      tableBody.classList.add("tooltip-tbody");

      //Row 1
      const tRow_1 = document.createElement("tr");
      const tData_01 = document.createElement("td");
      tData_01.setAttribute("colspan", 2);

      //wrapper
      const tData_01_Div = document.createElement("div");
      //Set Date
      const tData_01_H5 = document.createElement("h5");
      tData_01_H5.innerHTML =
        selectedDataPoint?.tenant_customers_age_label.toUpperCase() ?? "--";

      //Set Image
      // const tData_01_Img = document.createElement("img");
      // tData_01_Img.src = require(`@/assets/images/svg/components/${
      //   userCountDiff?.diffSign ? "Trending-UP.svg" : "Trending-Down.svg"
      // }`);

      tData_01_Div.appendChild(tData_01_H5);
      // tData_01_Div.appendChild(tData_01_Img);
      tData_01.appendChild(tData_01_Div);
      tRow_1.appendChild(tData_01);

      //Row 2
      const tRow_2 = document.createElement("tr");
      const tData_11 = document.createElement("td");
      tData_11.setAttribute("colspan", 2);
      //   const tData_12 = document.createElement("td");

      //Wrapper
      const tData_11_Temp = document.createElement("div");
      tData_11_Temp.innerHTML = `
      <div class="d-flex">
      
          <div class='d-flex flex-column w-60'>
          <h6>Total Amount</h6>
          <h5>${
            totalAmount
              ? compactNumber(totalAmount, 2) + " " + userCurrency
              : "--"
          }</h5>
        </div>
        
        <div class='d-flex flex-column w-40'>
        <h6>Customers</h6>
        <h5>${compactNumber(customersCount)}</h5>
      </div>
      
      </div>
            `;

      tData_11.appendChild(tData_11_Temp);
      tRow_2.appendChild(tData_11);

      //Row 3
      const tRow_3 = document.createElement("tr");
      const tData_21 = document.createElement("td");
      tData_21.setAttribute("colspan", 2);

      //Wrapper
      const tData_21_Temp = document.createElement("div");
      tData_21_Temp.innerHTML = `
              <div class='d-flex flex-column'>
                <h6>Average/Customer</h6>
                <div class="d-flex justify-content-between align-items-center w-100">
                <h5>${
                  avgSpendPerCustomer
                    ? compactNumber(avgSpendPerCustomer, 2) + " " + userCurrency
                    : "--"
                }</h5>
                </div>
              </div>`;

      tData_21.appendChild(tData_21_Temp);

      tRow_3.appendChild(tData_21);

      //Row 4
      const tRow_4 = document.createElement("tr");
      const tData_31 = document.createElement("td");
      tData_31.setAttribute("colspan", 2);

      //Wrapper
      const tData_31_Temp = document.createElement("div");
      tData_31_Temp.innerHTML = `<div></div>`;

      const toolTipButton = document.createElement("button");
      toolTipButton.innerHTML = "View transactions";
      toolTipButton.addEventListener("click", () => {
        viewAllCallback(selectedDataPoint);
      });

      tData_31_Temp.appendChild(toolTipButton);
      tData_31.appendChild(tData_31_Temp);

      tRow_4.appendChild(tData_31);

      //Add all rows to tBody
      tableBody.appendChild(tRow_1);
      tableBody.appendChild(tRow_2);
      tableBody.appendChild(tRow_3);
      tableBody.appendChild(tRow_4);

      //Add tBody to root table
      const tableRoot = tooltipEl.querySelector("table");
      tableRoot.appendChild(tableBody);

      // Remove old children from previous hovering event
      while (tableRoot.firstChild) {
        tableRoot.firstChild.remove();
      }
      // Add new children
      tableRoot.appendChild(tableBody);
    }

    // == Postioning it  ==
    const { offsetLeft: positionX, offsetTop: positionY } = chart?.canvas;

    let leftPosition = positionX + tooltip?.caretX;
    //left start point
    if (leftPosition < 79) leftPosition += 110;
    else if (leftPosition > 280) leftPosition -= 80;
    // leftPosition += 150; //show the tool-tip always on the right

    // Display, position, and set styles for font
    tooltipEl.style.opacity = 1;
    tooltipEl.style.left = leftPosition + "px";
    tooltipEl.style.top = positionY + tooltip.caretY + 55 + "px";
    tooltipEl.style.font = tooltip.options.bodyFont.string;
    tooltipEl.style.padding =
      tooltip.options.padding + "px " + tooltip.options.padding + "px";
  };
}
function categorySpendOverTimeTooltip(data, currency, viewAllCallback) {
  return (context) => {
    const { chart, tooltip, tooltipEl } = setDefaultConfigs(context);
    //if tooltip is hidden just exit
    if (!chart) return;

    // Set Text
    const pointKey = tooltip?.dataPoints[0]?.dataIndex ?? null;

    if (pointKey !== null) {
      //filter and selected current point
      const selectedPoint = data?.[pointKey] ?? {};
      // console.log(data, selectedPoint);
      // console.log("selectedPoint: ", selectedPoint);

      const metricKey =
        store.state?.overViewStore?.metricFilters.key ?? "average";
      const userCurrency = currency ?? "AED";
      const timeDaysCount = store.getters.timeDaysCount;
      // console.log(timeDaysCount);

      //[1] current amount
      const amountLabel =
        metricKey === "average" ? "Average Tx Amount" : "Total Spend Amount";
      let amountValue =
        metricKey === "average"
          ? selectedPoint?.avg_txn_value ?? 0
          : selectedPoint?.tenant_customer_total_spend ?? 0;
      amountValue = Math.abs(amountValue);

      //[2] date label
      let dateLabel = moment(
        selectedPoint?.tenant_customer_transaction_count_date
      ).format(timeDaysCount.key === "monthly" ? "MMMM, YYYY" : "D MMMM, YYYY");

      // === Tooltip ===
      const tableBody = document.createElement("tbody");
      tableBody.classList.add("tooltip-tbody");

      //Row 1
      const tRow_1 = document.createElement("tr");
      const tData_01 = document.createElement("td");
      tData_01.setAttribute("colspan", 2);

      //wrapper
      const tData_01_Div = document.createElement("div");
      //Set Date
      const tData_01_H5 = document.createElement("h5");
      tData_01_H5.innerHTML = dateLabel;

      tData_01_Div.appendChild(tData_01_H5);
      tData_01.appendChild(tData_01_Div);
      tRow_1.appendChild(tData_01);

      //Row 2
      const tRow_2 = document.createElement("tr");
      const tData_11 = document.createElement("td");
      tData_11.setAttribute("colspan", 2);
      //   const tData_12 = document.createElement("td");

      //Wrapper
      const tData_11_Temp = document.createElement("div");
      tData_11_Temp.innerHTML = `
              <div class='d-flex flex-column'>
                <h6>${amountLabel}</h6>
                <h5>${compactNumber(amountValue, 2)} ${userCurrency}</h5>
              </div>`;

      tData_11.appendChild(tData_11_Temp);
      tRow_2.appendChild(tData_11);

      //Row 4
      const tRow_3 = document.createElement("tr");
      const tData_31 = document.createElement("td");
      tData_31.setAttribute("colspan", 2);

      //Wrapper
      const tData_31_Temp = document.createElement("div");
      tData_31_Temp.innerHTML = `<div></div>`;

      const toolTipButton = document.createElement("button");
      toolTipButton.innerHTML = "View transactions";
      toolTipButton.addEventListener("click", () => {
        viewAllCallback(selectedPoint);
      });

      tData_31_Temp.appendChild(toolTipButton);
      tData_31.appendChild(tData_31_Temp);

      tRow_3.appendChild(tData_31);

      //Add all rows to tBody
      tableBody.appendChild(tRow_1);
      tableBody.appendChild(tRow_2);
      // tableBody.appendChild(tRow_3);

      //Add tBody to root table
      const tableRoot = tooltipEl.querySelector("table");
      tableRoot.appendChild(tableBody);

      // Remove old children from previous hovering event
      while (tableRoot.firstChild) {
        tableRoot.firstChild.remove();
      }
      // Add new children
      tableRoot.appendChild(tableBody);
    }

    // == Postioning it  ==
    const { offsetLeft: positionX, offsetTop: positionY } = chart?.canvas;

    let leftPosition = positionX + tooltip?.caretX;
    //left start point
    if (leftPosition < 75) leftPosition += 50;
    else if (leftPosition > 800) leftPosition -= 80;

    // Display, position, and set styles for font
    tooltipEl.style.opacity = 1;
    tooltipEl.style.left = leftPosition + "px";
    tooltipEl.style.top = positionY + tooltip.caretY + 27 + "px";
    tooltipEl.style.font = tooltip.options.bodyFont.string;
    tooltipEl.style.padding =
      tooltip.options.padding + "px " + tooltip.options.padding + "px";
  };
}
function userSubCategoriesSpendChartTooltip(data, viewAllCallback) {
  return (context) => {
    const { chart, tooltip, tooltipEl } = setDefaultConfigs(context);
    //if tooltip is hidden just exit
    if (!chart) return;

    // Set Text
    const pointKey = tooltip?.dataPoints[0]?.raw?.x;

    if (pointKey) {
      //[1] filter and selected current point
      let selectedDataPoint = data?.find((point) => {
        return point?.sub_category_name === pointKey;
      });

      //[2] set the values
      const metricKey =
        store.state?.overViewStore?.metricFilters.key ?? "average";
      const userCurrency = store.getters.selectedUserCurrency ?? "AED";

      //[2.1] current amount
      const amountLabel =
        metricKey === "average" ? "Average Tx Amount" : "Total Amount";
      const amountValue =
        metricKey === "average"
          ? selectedDataPoint?.avg_tx_spend
          : selectedDataPoint?.total_spend;

      //[2.2] Get top sub-brand
      const topBrand = { ...selectedDataPoint?.top_brand };

      const tableBody = document.createElement("tbody");
      tableBody.classList.add("tooltip-tbody");

      //Row 1
      const tRow_1 = document.createElement("tr");
      const tData_01 = document.createElement("td");
      tData_01.setAttribute("colspan", 2);

      //wrapper
      const tData_01_Div = document.createElement("div");
      //Set Date
      const tData_01_H5 = document.createElement("h5");
      tData_01_H5.innerHTML = selectedDataPoint?.sub_category_name ?? "--";

      tData_01_Div.appendChild(tData_01_H5);
      tData_01.appendChild(tData_01_Div);
      tRow_1.appendChild(tData_01);

      //Row 2
      const tRow_2 = document.createElement("tr");
      const tData_11 = document.createElement("td");
      tData_11.setAttribute("colspan", 2);
      //   const tData_12 = document.createElement("td");

      //Wrapper
      const tData_11_Temp = document.createElement("div");
      tData_11_Temp.innerHTML = `
              <div class='d-flex flex-column'>
                <h6>${amountLabel}</h6>
                <h5>${compactNumber(amountValue, 2)} ${userCurrency}</h5>
              </div>`;

      tData_11.appendChild(tData_11_Temp);
      tRow_2.appendChild(tData_11);

      //Row 3
      const tRow_3 = document.createElement("tr");
      const tData_21 = document.createElement("td");
      tData_21.setAttribute("colspan", 2);

      //Wrapper
      const tData_21_Temp = document.createElement("div");
      tData_21_Temp.innerHTML = `
              <div class='d-flex flex-column'>
                <h6>Top Brand</h6>
                <div class="d-flex justify-content-start gap-2 align-items-center w-100">
  
                <img class="circle-brand-icon p-0 m-0" src="${
                  topBrand?.logo
                }" alt="Brand Icon" />

                <h5>${topBrand?.brand_name ?? "--"}</h5>
                </div>
                
              </div>`;

      tData_21.appendChild(tData_21_Temp);
      tRow_3.appendChild(tData_21);

      //Row 4
      const tRow_4 = document.createElement("tr");
      const tData_31 = document.createElement("td");
      tData_31.setAttribute("colspan", 2);

      //Wrapper
      const tData_31_Temp = document.createElement("div");
      tData_31_Temp.innerHTML = `<div></div>`;

      const toolTipButton = document.createElement("button");
      toolTipButton.innerHTML = "View transactions";
      toolTipButton.addEventListener("click", () => {
        viewAllCallback(selectedDataPoint);
      });

      tData_31_Temp.appendChild(toolTipButton);
      tData_31.appendChild(tData_31_Temp);

      tRow_4.appendChild(tData_31);

      //Add all rows to tBody
      tableBody.appendChild(tRow_1);
      tableBody.appendChild(tRow_2);
      tableBody.appendChild(tRow_3);
      // tableBody.appendChild(tRow_4);

      //Add tBody to root table
      const tableRoot = tooltipEl.querySelector("table");
      tableRoot.appendChild(tableBody);

      // Remove old children from previous hovering event
      while (tableRoot.firstChild) {
        tableRoot.firstChild.remove();
      }
      // Add new children
      tableRoot.appendChild(tableBody);
    }

    // == Postioning it  ==
    const { offsetLeft: positionX, offsetTop: positionY } = chart?.canvas;

    let leftPosition = positionX + tooltip?.caretX;
    //left start point
    if (leftPosition < 75) leftPosition += 50;
    else if (leftPosition > 800) leftPosition -= 80;

    // Display, position, and set styles for font
    tooltipEl.style.opacity = 1;
    tooltipEl.style.left = leftPosition + "px";
    tooltipEl.style.top = positionY + tooltip.caretY + 27 + "px";
    tooltipEl.style.font = tooltip.options.bodyFont.string;
    tooltipEl.style.padding =
      tooltip.options.padding + "px " + tooltip.options.padding + "px";
  };
}
function currencyCountryTooltip(data, viewAllCallback) {
  return (context) => {
    const { chart, tooltip, tooltipEl } = setDefaultConfigs(context);
    //if tooltip is hidden just exit
    if (!chart) return;

    // Set Text
    const pointKey = tooltip?.dataPoints[0]?.raw?.y;

    if (pointKey) {
      //filter and selected current point
      let selectedDataPoint = data.filter((point) => {
        return point.y === pointKey;
      });

      if (selectedDataPoint.length !== 0) {
        selectedDataPoint = selectedDataPoint[0];
      } else return;

      //[1] Customer Count
      const customersCount = selectedDataPoint?.x ?? 0;

      //[2] Country Label
      const countryLabel = selectedDataPoint?.y ?? "--";

      const tableBody = document.createElement("tbody");
      tableBody.classList.add("tooltip-tbody");

      //Row 1
      const tRow_1 = document.createElement("tr");
      const tData_01 = document.createElement("td");
      tData_01.setAttribute("colspan", 2);

      //Wrapper
      const tData_01_Temp = document.createElement("div");
      tData_01_Temp.innerHTML = `
      <div class="d-flex flex-column align-items-start">

        <h6 class="m-0 p-0">${getCountry(countryLabel)}</h6>

        <div class='p-0 m-0 d-flex flex-row gap-2 w-100'>
         <h5>${compactNumber(customersCount)}</h5><h5>Customers</h5>
        </div>

      </div>
            `;

      tData_01.appendChild(tData_01_Temp);
      tRow_1.appendChild(tData_01);

      //Row 2
      const tRow_2 = document.createElement("tr");
      const tData_02 = document.createElement("td");
      tData_02.setAttribute("colspan", 2);

      //Wrapper
      const tData_02_Temp = document.createElement("div");
      tData_02_Temp.innerHTML = `<div class="text-start"></div>`;

      const toolTipButton = document.createElement("button");
      toolTipButton.innerHTML = "View transactions";
      toolTipButton.addEventListener("click", () => {
        viewAllCallback(selectedDataPoint);
      });

      tData_02_Temp.appendChild(toolTipButton);
      tData_02.appendChild(tData_02_Temp);

      tRow_2.appendChild(tData_02);

      //Add all rows to tBody
      tableBody.appendChild(tRow_1);
      tableBody.appendChild(tRow_2);

      //Add tBody to root table
      const tableRoot = tooltipEl.querySelector("table");
      tableRoot.appendChild(tableBody);

      // Remove old children from previous hovering event
      while (tableRoot.firstChild) {
        tableRoot.firstChild.remove();
      }
      // Add new children
      tableRoot.appendChild(tableBody);
    }

    // == Postioning it  ==
    const { offsetLeft: positionX, offsetTop: positionY } = chart?.canvas;

    let leftPosition = positionX + tooltip?.caretX;
    //left start point
    if (leftPosition < 112) leftPosition += 115;
    else if (leftPosition > 420) leftPosition -= 80;
    // leftPosition += 110; //show the tool-tip always on the right

    // Display, position, and set styles for font
    tooltipEl.style.opacity = 1;
    tooltipEl.style.left = leftPosition + "px";
    tooltipEl.style.top = positionY + tooltip.caretY + 17 + "px";
    tooltipEl.style.font = tooltip.options.bodyFont.string;
    tooltipEl.style.padding =
      tooltip.options.padding + "px " + tooltip.options.padding + "px";
  };
}

function currencyGenderTooltip(data, viewAllCallback) {
  return (context) => {
    const { chart, tooltip, tooltipEl } = setDefaultConfigs(context);
    //if tooltip is hidden just exit
    if (!chart) return;

    // Set Text
    const pointKey = tooltip?.dataPoints[0]?.raw;

    if (pointKey) {
      const dataList = data.datasets?.[0]?.data ?? [];
      //filter and selected current point
      let selectedDataPoint = dataList.filter((point) => {
        return point?.label === pointKey?.label;
      });

      if (selectedDataPoint.length !== 0) {
        selectedDataPoint = selectedDataPoint[0];
      } else return;

      //[1] Customer Count
      const customersCount = selectedDataPoint?.value ?? 0;

      //[2] Country Label
      const genderlabel = selectedDataPoint?.name ?? "--";

      const tableBody = document.createElement("tbody");
      tableBody.classList.add("tooltip-tbody");

      //Row 1
      const tRow_1 = document.createElement("tr");
      const tData_01 = document.createElement("td");
      tData_01.setAttribute("colspan", 2);

      //Wrapper
      const tData_01_Temp = document.createElement("div");
      tData_01_Temp.innerHTML = `
      <div class="d-flex flex-column align-items-start">

        <h6 class="m-0 p-0">${genderlabel}</h6>

        <div class='p-0 m-0 d-flex flex-row gap-2 w-100'>
         <h5>${compactNumber(customersCount)}</h5><h5>Customers</h5>
        </div>

      </div>
            `;

      tData_01.appendChild(tData_01_Temp);
      tRow_1.appendChild(tData_01);

      //Row 2
      const tRow_2 = document.createElement("tr");
      const tData_02 = document.createElement("td");
      tData_02.setAttribute("colspan", 2);

      //Wrapper
      const tData_02_Temp = document.createElement("div");
      tData_02_Temp.innerHTML = `<div></div>`;

      const toolTipButton = document.createElement("button");
      toolTipButton.innerHTML = "View transactions";
      toolTipButton.addEventListener("click", () => {
        viewAllCallback(selectedDataPoint);
      });

      tData_02_Temp.appendChild(toolTipButton);
      tData_02.appendChild(tData_02_Temp);

      tRow_2.appendChild(tData_02);

      //Add all rows to tBody
      tableBody.appendChild(tRow_1);
      tableBody.appendChild(tRow_2);

      //Add tBody to root table
      const tableRoot = tooltipEl.querySelector("table");
      tableRoot.appendChild(tableBody);

      // Remove old children from previous hovering event
      while (tableRoot.firstChild) {
        tableRoot.firstChild.remove();
      }
      // Add new children
      tableRoot.appendChild(tableBody);
    }

    // == Postioning it  ==
    const { offsetLeft: positionX, offsetTop: positionY } = chart?.canvas;

    let leftPosition = positionX + tooltip?.caretX;
    //left start point
    if (leftPosition < 79) leftPosition += 110;
    else if (leftPosition > 420) leftPosition -= 80;
    // leftPosition += 110; //show the tool-tip always on the right

    // Display, position, and set styles for font
    tooltipEl.style.opacity = 1;
    tooltipEl.style.left = leftPosition + "px";
    tooltipEl.style.top = positionY + tooltip.caretY + 17 + "px";
    tooltipEl.style.font = tooltip.options.bodyFont.string;
    tooltipEl.style.padding =
      tooltip.options.padding + "px " + tooltip.options.padding + "px";
  };
}

export {
  userBalanceChartTooltip,
  userCategoriesSpendChartTooltip,
  ageBarChartTooltip,
  categorySpendOverTimeTooltip,
  userSubCategoriesSpendChartTooltip,
  currencyCountryTooltip,
  currencyGenderTooltip,
};
