<template>
  <div
    class="page-title-tag d-flex justify-content-start align-items-center gap-2"
  >
    <template v-if="brandLoading">
      <p>Loading...</p>
    </template>

    <template v-else>
      <img :src="catIcon" alt="Route Icon" class="tag-icon" />
      <p>{{ catName }}</p>
    </template>
  </div>
</template>

<script>
import { computed } from "vue";
import { useStore } from "vuex";

import { categories } from "@/data/options";

export default {
  setup() {
    const store = useStore();

    const brandDetails = computed(
      () => store.state.allBrandsStore.specificBrandDetails
    );
    const brandLoading = computed(
      () => store.state.allBrandsStore.specificBrandLoading
    );

    const catIcon = computed(() => {
      let icon = null;

      if (!brandLoading.value) {
        icon =
          categories[brandDetails.value.category_id]?.icon ??
          require("@/assets/images/svg/components/no_brand_logo.svg");
      }

      return icon;
    });

    const catName = computed(() => {
      let name = null;

      if (!brandLoading.value) {
        name = brandDetails.value?.category?.name ?? "--";
      }

      return name;
    });

    return { brandLoading, catIcon, catName };
  },
};
</script>

<style lang="scss">
.page-title-tag {
  background-color: #def1ef;
  padding: 0.5rem 1.5rem;
  border-radius: 50px;
  p {
    margin: 0;
    padding: 0;
    color: var(--green);
    font-size: 0.88rem;
  }

  .tag-icon {
    width: 18px;
    height: 18px;
  }
}
</style>
