<template>
  <div
    class="
      d-flex
      justify-content-start
      align-items-center
      gap-1
      page-title-container
    "
  >
    <!-- Page title icon-->
    <PageTitleIcon v-if="icon" :icon="icon" class="pe-3" />
    <!-- Page title icon-->

    <!-- Page title user name-->
    <PageTitleUserName v-if="isUserDetailsRoute" />
    <!-- Page title user name-->

    <!-- Page title specific brand-->
    <PageTitleSpecificBrand v-else-if="isSpecificBrandRoute" />
    <!-- Page title specific brand-->

    <!-- Default page title-->
    <h2 v-else>
      {{ pageTitle }}
    </h2>
    <!-- Default page title-->
  </div>
</template>
<script>
import PageTitleSpecificBrand from "../PageTitleSpecificBrand.vue";
import PageTitleIcon from "../PageTitleIcon.vue";
import PageTitleUserName from "../PageTitleUserName.vue";
import { computed } from "vue";
import { useRoute } from "vue-router";
import { categories } from "@/data/options";

export default {
  components: { PageTitleUserName, PageTitleIcon, PageTitleSpecificBrand },
  props: {
    title: {
      type: String,
      required: true,
      default: "Title",
    },
    icon: {
      type: String,
      default: "",
    },
  },
  setup(props) {
    const route = useRoute();

    const isUserDetailsRoute = computed(
      () =>
        route.name === "UserDetails" ||
        route.name === "UserDetailsFromTransactions"
    );

    const isSpecificBrandRoute = computed(() => route.name === "SpecificBrand");

    const pageTitle = computed(() => {
      let title = props.title;
      //[1] If specific cat route
      switch (route?.name) {
        case "UserCategoryDetails":
          title = categories[route.params?.catId]?.label ?? "----";
          break;
        case "UsersMainByCategory":
          title = `${categories[route.params?.categoryId]?.label} - Users`;
          break;
        case "UsersMainByBrand":
          title = `${route.params?.brandLabel} - Users`;
          break;
        case "SpecificCurrency":
          title = route.params?.currencyId;
          break;
      }

      return title;
    });

    return {
      pageTitle,
      isUserDetailsRoute,
      isSpecificBrandRoute,
    };
  },
};
</script>
<style>
.page-title-container h2 {
  color: var(--green);
  font-size: 2.05rem;
  font-weight: 600;
}
</style>
