import BrandsAPI from "@/services/api/BrandsAPI";

export default {
  state() {
    return {
      specificBrandDetails: {},
      specificBrandLoading: false,
    };
  },
  getters: {},
  mutations: {
    updateSpecificBrandDetails(state, payload) {
      state.specificBrandDetails = { ...payload };
    },
    updateSpecificBrandLoading(state, payload) {
      state.specificBrandLoading = payload;
    },
  },
  actions: {
    getSpecificBrandDetails({ commit }, brandId) {
      commit("updateSpecificBrandLoading", true);
      commit("updateSpecificBrandDetails", {});

      BrandsAPI.getSpecificBrandDetails(brandId)

        .then((res) => {
          commit("updateSpecificBrandDetails", {
            ...(res?.data ? res?.data?.data : res),
          });
          commit("updateSpecificBrandLoading", false);
        })

        .catch((error) => {
          if (error !== "canceled") {
            commit("updateSpecificBrandDetails", {});
            commit("updateSpecificBrandLoading", false);
            console.error("error: ", error);
          }
        });
    },
  },
};
