<template>
  <div>
    <h2 v-if="!customersDetailsLoading">
      {{ customersDetails?.CustomerName }}
    </h2>
    <h2 v-else class="opacity-75">loading...</h2>
  </div>
</template>

<script>
import { computed } from "vue";
import { useStore } from "vuex";

export default {
  setup() {
    const store = useStore();

    const customersDetails = computed(
      () => store.state.usersViewStore.specificCustomersNBFDetails
    );
    const customersDetailsLoading = computed(
      () => store.state.usersViewStore.specificCustomersNBFDetailsLoading
    );

    return { customersDetails, customersDetailsLoading };
  },
};
</script>

<style lang="scss" scoped></style>
