import { useMockFetch } from "@/composables/Helpers";
import RestService from "../RestService";

class BrandsAPI {
  // eslint-disable-next-line no-unused-vars
  getSpecificBrandDetails(brandId) {
    // return RestService.getWithParams(`/brands/${brandId}`);
    return useMockFetch({
      data: {
        success: true,
        message: null,
        data: {
          id: 7000,
          name: "National Bank of Fujairah NBF",
          category_id: 1,
          sub_category_id: 3,
          business_category_id: 1,
          sub_business_category_id: 4,
          logo: "brand_logos/nbf.png",
          website: "https://nbf.ae/",
          international_brand: false,
          country: null,
          secondary_type: "brand",
          old_id: 20476,
          archived: false,
          created_at: "2023-09-02T11:03:43.753Z",
          updated_at: "2023-09-02T11:03:43.753Z",
          searchable: "'bank':2 'fujairah':4 'nation':1 'nbf':5",
          recurring: false,
          category_v2_id: null,
          sub_category_v2_id: null,
          category: {
            id: 1,
            name: "Financial Services",
            old_id: "1",
            archived: false,
            created_at: "2023-09-02T11:00:45.891Z",
            updated_at: "2024-02-08T10:53:45.890Z",
            searchable: "'financi':1 'servic':2",
            co2_factor: "0.0",
            v2_name: "Bank Services",
            factor: 0.0,
          },
        },
      },
    });
  }
  getBrandsUserSummary(filters) {
    return RestService.getWithParams("/brands/spend/summary", filters);
  }
  getBrandsSpendChartData(filters, daysPeriod, brandId) {
    let timeInterval = "daily";

    switch (true) {
      case daysPeriod <= 31:
        timeInterval = "daily";
        break;

      case daysPeriod > 31 && daysPeriod < 365:
        timeInterval = "weekly";
        break;

      case daysPeriod >= 365:
        timeInterval = "monthly";
        break;
    }
    ///brands/:brand_id/spend/:interval_type
    const endpoint = `brands/${brandId}/spend/${timeInterval}`;

    return RestService.getWithParams(endpoint, filters);
  }
  getBrandsUsersCountriesData(filters) {
    return RestService.getWithParams("/brands/demographics/country", filters);
  }
  getBrandsUsersGendersData(filters) {
    return RestService.getWithParams("/brands/demographics/gender", filters);
  }
  getBrandsUsersAgesData(filters) {
    return RestService.getWithParams("/brands/demographics/age", filters);
  }
}

export default new BrandsAPI();
