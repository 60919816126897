<template>
  <div id="period-dropdown" class="postion-relative">
    <BaseFilterDropDown
      id="time-period-dd"
      v-model="selectedDropDownPeriod"
      :options="timePeriodDropDownOptions"
      :range-label="
        selectedDateRange?.dropDownLabel ?? selectedDropDownPeriod?.value
      "
    >
      <template #option="{ value }">
        <span :id="`dd-time-filter-${value?.key}`"
          >{{ value?.value }}
        </span></template
      >
    </BaseFilterDropDown>
    <TimeDatePicker
      :selected-option="periodsOptions[selectedDropDownPeriod?.key]"
      @time-changed="handleDatePickerChange"
    />
  </div>
</template>
<script>
import { computed, ref, onMounted, watch } from "vue";
import BaseFilterDropDown from "./BaseFilterDropDown.vue";
import { periods } from "@/data/options";
import TimeDatePicker from "../TimeDatePicker.vue";
import store from "../../store";
import { getSpecificCachedFilter } from "@/composables/Filters.js";

export default {
  components: { BaseFilterDropDown, TimeDatePicker },
  emits: ["period-change"],
  setup(props, { emit }) {
    const cachedTimeFilter = getSpecificCachedFilter("timeFilters") ?? {};

    const selectedDropDownPeriod = ref({
      key: cachedTimeFilter?.key ?? "month",
      value: cachedTimeFilter?.dropDownLabel ?? "Last 30 Days",
    });

    const selectedDateRange = ref();
    const periodsOptions = ref(periods);

    const timeFilters = computed(() => store.state.overViewStore.timeFilters);
    const appliedFilters = computed(
      () => store.state.overViewStore.appliedFilters
    );
    const timePeriodDropDownOptions = computed(() => {
      let periodsList = [];
      for (let key in periodsOptions.value) {
        periodsList.push({
          key,
          value: periodsOptions.value[key]?.label,
        });
      }
      return periodsList;
    });

    function handleDatePickerChange(selectedOpt) {
      const time = {
        key: selectedDropDownPeriod.value?.key,
        ...selectedOpt,
      };
      selectedDateRange.value = { ...time };
      emit("period-change", time);
    }

    function setNewTimeFilter(newTimeFilter) {
      //[1] Set new time to the dropdown component
      if (Object.keys(newTimeFilter)?.length !== 0) {
        selectedDropDownPeriod.value = {
          key: newTimeFilter?.key,
          value: newTimeFilter?.dropDownLabel,
        };
        selectedDateRange.value = newTimeFilter;
      }

      //[2] update appliedFilter
      store.commit("updateAppliedFilters", {
        ...appliedFilters.value,
        from_date: newTimeFilter?.rangePeriodFilter?.from,
        to_date: newTimeFilter?.rangePeriodFilter?.to,
      });
    }

    function setCachedTimeFilter() {
      const defaultSelectedTime = { ...periodsOptions.value?.month?.days };
      let cachedSelectedPeriod = {
        ...timeFilters.value,
      };

      if (Object.keys(cachedTimeFilter).length > 0) {
        cachedSelectedPeriod = { ...cachedTimeFilter };
      }

      //[1] Set cached Period Dropdown label and value
      if (Object.keys(cachedSelectedPeriod)?.length !== 0) {
        //change here
        selectedDropDownPeriod.value = {
          key: cachedSelectedPeriod?.key ?? "month",
          value: cachedSelectedPeriod?.dropDownLabel ?? "Last 30 Days",
        };
      }

      //[2] Set Default time to Last 30 Days or previous cached selected date
      if (Object.keys(defaultSelectedTime).length !== 0) {
        //check here
        //Set time filter
        store.commit("updateTimeFilters", {
          rangePeriodFilter: cachedSelectedPeriod?.rangePeriodFilter ?? {
            ...periodsOptions.value?.month?.days,
          },
          dropDownLabel: cachedSelectedPeriod?.dropDownLabel ?? "Last 30 Days",
          periodFilter: cachedSelectedPeriod?.periodFilter ?? 30,
          key: selectedDropDownPeriod.value?.key ?? "month",
        });

        //Set time filter to filters object
        store.commit("updateAppliedFilters", {
          ...appliedFilters.value,
          from_date:
            cachedSelectedPeriod?.rangePeriodFilter?.from ??
            defaultSelectedTime?.from,
          to_date:
            cachedSelectedPeriod?.rangePeriodFilter?.to ??
            defaultSelectedTime?.to,
        });
      }
    }

    // == Watchers ==
    watch(timeFilters, (newTimeFilter) => {
      setNewTimeFilter(newTimeFilter);
    });

    onMounted(() => {
      setCachedTimeFilter();
    });

    return {
      selectedDropDownPeriod,
      timePeriodDropDownOptions,
      selectedDateRange,
      periodsOptions,
      handleDatePickerChange,
    };
  },
};
</script>
<style></style>
