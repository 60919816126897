<template>
  <div class="box-container bg-white p-4 d-flex flex-column gap-4">
    <div class="d-flex align-items-center justify-content-between">
      <h5 class="fw-500">{{ data?.label ?? "--" }}</h5>
      <BaseSmallButton
        :label="`${txCount} Txns`"
        :under-lined="true"
        @click="handleViewAllTxs"
      />
    </div>
    <div>
      <div class="d-flex align-items-center justify-content-between">
        <BaseSmallAmountTitle
          :show-currency="true"
          :show-trend-sign="true"
          :amount="amount"
          :is-trending-positively="amountDiff?.diffSign"
          font-size="1.25rem"
          trending-icon-size="23px"
          custom-icon-size="23px"
        />
        <img
          src="@/assets/images/svg/components/v-line-md.svg"
          alt="vertical line"
          height="30"
          class="mx-3"
        />
        <BaseSmallAmountTitle
          :show-currency="false"
          :show-trend-sign="true"
          :amount="usersCount"
          :is-trending-positively="usersDiff?.diffSign"
          :custom-icon="require('@/assets/images/svg/components/Users.svg')"
          font-size="1.25rem"
          trending-icon-size="23px"
          custom-icon-size="20px"
        />
      </div>
    </div>
  </div>
</template>

<script>
import BaseSmallButton from "./helpers/BaseSmallButton.vue";
import { inject, computed } from "vue";
import BaseSmallAmountTitle from "./helpers/BaseSmallAmountTitle.vue";
import { useStore } from "vuex";
import { useDiffCalculator } from "@/composables/Helpers";
import { useRouter, useRoute } from "vue-router";
export default {
  components: { BaseSmallAmountTitle, BaseSmallButton },
  props: {
    data: {
      type: Object,
      default: () => {},
    },
  },
  setup(props) {
    const compactNumber = inject("compactNumber");
    const store = useStore();
    const router = useRouter();
    const route = useRoute();

    const metric = computed(() => store.state.overViewStore.metricFilters);
    const filters = computed(() => {
      return store.getters.filters;
    });
    const txCount = computed(() => {
      return compactNumber(props.data?.tx_count ?? 0);
    });
    const amount = computed(() => {
      if (metric.value.key === "average") {
        return compactNumber(
          Math.round(Math.abs(props.data?.avg_amount ?? 0)),
          2
        );
      }
      return compactNumber(
        Math.round(Math.abs(props.data?.total_amount ?? 0)),
        2
      );
    });
    const usersCount = computed(() => {
      return compactNumber(props.data?.users_count ?? 0);
    });
    const amountDiff = computed(() => {
      if (metric.value.key === "average") {
        return useDiffCalculator(
          props.data?.avg_amount ?? 0,
          props.data?.rel_avg_amount ?? 0
        );
      } else {
        return useDiffCalculator(
          props.data?.total_amount ?? 0,
          props.data?.rel_total_amount ?? 0
        );
      }
    });
    const usersDiff = computed(() => {
      return useDiffCalculator(
        props.data?.users_count,
        props.data?.rel_users_count
      );
    });

    function viewCurrencyTxs() {
      store.commit("updateShowFiltersBar", true);
      router.push({
        name: "MainTransactions",
        params: {
          filters: JSON.stringify({
            multi_currency: [
              { key: props.data?.label, value: props.data?.label },
            ],
          }),
        },
      });
    }

    function viewTxTypeTxs() {
      const currencyFilter =
        filters.value?.tenant_transaction_currency_codes?.[0];
      store.commit("updateShowFiltersBar", true);
      router.push({
        name: "MainTransactions",
        params: {
          filters: JSON.stringify({
            transaction_types: [
              {
                key: String(props.data?.tenant_transaction_type_id ?? ""),
                value: String(props.data?.label ?? ""),
              },
            ],
            ...(currencyFilter && {
              multi_currency: [{ key: currencyFilter, value: currencyFilter }],
            }),
          }),
        },
      });
    }

    function handleViewAllTxs() {
      if (route.meta?.for === "currencies") viewCurrencyTxs();
      else if (route.meta?.for === "types") viewTxTypeTxs();
    }

    return {
      compactNumber,
      txCount,
      amount,
      usersCount,
      amountDiff,
      usersDiff,
      handleViewAllTxs,
    };
  },
};
</script>

<style lang="scss" scoped>
.box-container {
  border-radius: 20px;
  background: #fff;
  box-shadow: 0px 4px 40px 0px rgba(204, 213, 212, 0.3);
}
</style>
