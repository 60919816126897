<template>
  <div class="d-flex flex-column gap-1 mt-1">
    <BasePageTitleTop />

    <div class="d-flex justify-content-between align-items-center w-100">
      <BasePageTitle
        :icon="routeIcon"
        :title="$route.meta?.title"
        class="py-2"
      />

      <BasePageFilters class="py-2" />
    </div>
  </div>
</template>

<script>
import BasePageTitleTop from "./BasePageTitleTop.vue";
import BasePageFilters from "./BasePageFilters.vue";
import BasePageTitle from "./BasePageTitle.vue";
import { computed } from "vue";
import { useRoute } from "vue-router";
import { useStore } from "vuex";

export default {
  components: { BasePageTitle, BasePageFilters, BasePageTitleTop },
  setup() {
    const route = useRoute();
    const store = useStore();

    const brandDetails = computed(
      () => store.state.allBrandsStore.specificBrandDetails
    );

    const specificBrandLoading = computed(
      () => store.state.allBrandsStore.specificBrandLoading
    );

    const routeIcon = computed(() => {
      return getRouteIcon(route.name);
    });

    function getRouteIcon(routeName) {
      let icon = null;

      switch (routeName) {
        case "UserCategoryDetails":
          icon = route.meta?.routeIcon;
          break;

        case "SpecificBrand":
          if (!specificBrandLoading.value)
            icon = brandDetails.value?.logo ?? "-no-logo-";
          else icon = null;
          break;
      }

      return icon;
    }

    return { routeIcon };
  },
};
</script>

<style lang="scss" scoped></style>
