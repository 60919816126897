// import { useMockFetch } from "@/composables/Helpers";
import { useMockFetch } from "@/composables/Helpers";
import RestService from "../RestService";

class OverviewAPI {
  getMinMaxdates(filters) {
    return RestService.getWithParams(
      "/tenant_transactions/date_range",
      filters
    );
  }
  getUserSummary(filters, options) {
    return RestService.getWithParams(
      "/tenant_customers/spend/summary",
      filters,
      null,
      {
        ...options,
      }
    );
  }
  getBrandsSummaryData(filters, page) {
    return RestService.getWithParams("/brands/spend/summary", filters, page);
  }
  getBrandsList(filters) {
    // console.log("Fetch Brands: ", filters)
    return RestService.getWithParams("/brands", filters);
    // return useMockFetch(
    //   {
    //     data: {
    //       brands: [
    //         {
    //           id: 1,
    //           name: "Gulf Legend",
    //           category_id: 9,
    //           sub_category_id: 56,
    //           business_category_id: 6,
    //           sub_business_category_id: 22,
    //           logo: null,
    //           website: null,
    //           international_brand: false,
    //           country: null,
    //           secondary_type: "brand",
    //           old_id: 34637,
    //           archived: false,
    //           created_at: "2023-09-04T08:20:20.135Z",
    //           updated_at: "2023-09-04T08:20:20.135Z",
    //           searchable: "'gulf':1 'legend':2",
    //         },
    //         {
    //           id: 438,
    //           name: "Gulf Waves Restaurants",
    //           category_id: 10,
    //           sub_category_id: 58,
    //           business_category_id: 8,
    //           sub_business_category_id: 37,
    //           logo: "brand_logos/Gulf_Waves_Restaurants.png",
    //           website: "https://www.instagram.com/amwajalkalij/",
    //           international_brand: false,
    //           country: null,
    //           secondary_type: "brand",
    //           old_id: 32732,
    //           archived: false,
    //           created_at: "2023-09-04T08:20:31.225Z",
    //           updated_at: "2023-09-04T08:20:31.225Z",
    //           searchable: "'gulf':1 'restaur':3 'wave':2",
    //         },
    //         {
    //           id: 741,
    //           name: "Gulf Palms Trading",
    //           category_id: 6,
    //           sub_category_id: 32,
    //           business_category_id: 6,
    //           sub_business_category_id: 21,
    //           logo: null,
    //           website: null,
    //           international_brand: false,
    //           country: null,
    //           secondary_type: "brand",
    //           old_id: 33983,
    //           archived: false,
    //           created_at: "2023-09-04T08:20:39.128Z",
    //           updated_at: "2023-09-04T08:20:39.128Z",
    //           searchable: "'gulf':1 'palm':2 'trade':3",
    //         },
    //         {
    //           id: 1285,
    //           name: "Gulf Gas Station",
    //           category_id: 14,
    //           sub_category_id: 91,
    //           business_category_id: 12,
    //           sub_business_category_id: 52,
    //           logo: null,
    //           website: null,
    //           international_brand: false,
    //           country: "SA",
    //           secondary_type: "brand",
    //           old_id: 33419,
    //           archived: false,
    //           created_at: "2023-09-04T08:20:53.284Z",
    //           updated_at: "2023-09-04T08:20:53.284Z",
    //           searchable: "'gas':2 'gulf':1 'station':3",
    //         },
    //         {
    //           id: 1120,
    //           name: "Emdad Gulf Catering",
    //           category_id: 10,
    //           sub_category_id: 63,
    //           business_category_id: 8,
    //           sub_business_category_id: 40,
    //           logo: "brand_logos/Emdad_Gulf_Catering.png",
    //           website: "http://www.egcl.ae/",
    //           international_brand: false,
    //           country: null,
    //           secondary_type: "brand",
    //           old_id: 22143,
    //           archived: false,
    //           created_at: "2023-09-04T08:20:48.962Z",
    //           updated_at: "2023-09-04T08:20:48.962Z",
    //           searchable: "'cater':3 'emdad':1 'gulf':2",
    //         },
    //         {
    //           id: 1177,
    //           name: "Gulf Touches Pharmacy",
    //           category_id: 7,
    //           sub_category_id: 39,
    //           business_category_id: 7,
    //           sub_business_category_id: 34,
    //           logo: null,
    //           website: "https://www.instagram.com/lmsat_alkhalij_mdena/",
    //           international_brand: false,
    //           country: null,
    //           secondary_type: "brand",
    //           old_id: 32746,
    //           archived: false,
    //           created_at: "2023-09-04T08:20:50.557Z",
    //           updated_at: "2023-09-04T08:20:50.557Z",
    //           searchable: "'gulf':1 'pharmaci':3 'touch':2",
    //         },
    //         {
    //           id: 2451,
    //           name: "Gulf Stores",
    //           category_id: 6,
    //           sub_category_id: 33,
    //           business_category_id: 6,
    //           sub_business_category_id: 21,
    //           logo: "brand_logos/Gulf_Stores_qCY8f3C.png",
    //           website: "https://www.instagram.com/gulf_city/",
    //           international_brand: false,
    //           country: "SA",
    //           secondary_type: "brand",
    //           old_id: 27419,
    //           archived: false,
    //           created_at: "2023-09-04T08:21:24.470Z",
    //           updated_at: "2023-09-04T08:21:24.470Z",
    //           searchable: "'gulf':1 'store':2",
    //         },
    //         {
    //           id: 2593,
    //           name: "Gulf Bridge",
    //           category_id: 9,
    //           sub_category_id: 55,
    //           business_category_id: 7,
    //           sub_business_category_id: 36,
    //           logo: null,
    //           website: null,
    //           international_brand: false,
    //           country: null,
    //           secondary_type: "brand",
    //           old_id: 33894,
    //           archived: false,
    //           created_at: "2023-09-04T08:21:28.402Z",
    //           updated_at: "2023-09-04T08:21:28.402Z",
    //           searchable: "'bridg':2 'gulf':1",
    //         },
    //         {
    //           id: 6904,
    //           name: "German Gulf Enterprises Ltd",
    //           category_id: 18,
    //           sub_category_id: 110,
    //           business_category_id: 15,
    //           sub_business_category_id: 76,
    //           logo: "brand_logos/German_Gulf_Enterprises_Ltd.png",
    //           website: null,
    //           international_brand: false,
    //           country: null,
    //           secondary_type: "brand",
    //           old_id: 27260,
    //           archived: false,
    //           created_at: "2023-09-04T08:23:27.133Z",
    //           updated_at: "2023-09-04T08:23:27.133Z",
    //           searchable: "'enterpris':3 'german':1 'gulf':2 'ltd':4",
    //         },
    //         {
    //           id: 8360,
    //           name: "Gulf Air",
    //           category_id: 15,
    //           sub_category_id: 98,
    //           business_category_id: 13,
    //           sub_business_category_id: 60,
    //           logo: "brand_logos/Gulf_Air_.png",
    //           website: "https://www.gulfair.com/",
    //           international_brand: true,
    //           country: null,
    //           secondary_type: "brand",
    //           old_id: 20713,
    //           archived: false,
    //           created_at: "2023-09-04T08:24:04.783Z",
    //           updated_at: "2023-09-04T08:24:04.783Z",
    //           searchable: "'air':2 'gulf':1",
    //         },
    //         {
    //           id: 8361,
    //           name: "Gulf Asian English School",
    //           category_id: 3,
    //           sub_category_id: 9,
    //           business_category_id: 7,
    //           sub_business_category_id: 31,
    //           logo: "brand_logos/Gulf_Asian_English_School.png",
    //           website: "https://gulfasianenglishschool.com/",
    //           international_brand: false,
    //           country: null,
    //           secondary_type: "brand",
    //           old_id: 22229,
    //           archived: false,
    //           created_at: "2023-09-04T08:24:04.806Z",
    //           updated_at: "2023-09-04T08:24:04.806Z",
    //           searchable: "'asian':2 'english':3 'gulf':1 'school':4",
    //         },
    //         {
    //           id: 8362,
    //           name: "Gulf Court Hotel",
    //           category_id: 15,
    //           sub_category_id: 97,
    //           business_category_id: 13,
    //           sub_business_category_id: 59,
    //           logo: "brand_logos/gulfhot.png",
    //           website: "https://www.gulfcourthotelbusinessbay.com/",
    //           international_brand: true,
    //           country: null,
    //           secondary_type: "brand",
    //           old_id: 20047,
    //           archived: false,
    //           created_at: "2023-09-04T08:24:04.835Z",
    //           updated_at: "2023-09-04T08:24:04.835Z",
    //           searchable: "'court':2 'gulf':1 'hotel':3",
    //         },
    //         {
    //           id: 8369,
    //           name: "MetLife Gulf",
    //           category_id: 17,
    //           sub_category_id: 104,
    //           business_category_id: 7,
    //           sub_business_category_id: 33,
    //           logo: "brand_logos/MetLife_Gulf.png",
    //           website: "https://www.metlife-gulf.com/en/",
    //           international_brand: true,
    //           country: null,
    //           secondary_type: "brand",
    //           old_id: 22345,
    //           archived: false,
    //           created_at: "2023-09-04T08:24:05.005Z",
    //           updated_at: "2023-09-04T08:24:05.005Z",
    //           searchable: "'gulf':2 'metlif':1",
    //         },
    //         {
    //           id: 8370,
    //           name: "Gulf Crispy",
    //           category_id: 10,
    //           sub_category_id: null,
    //           business_category_id: null,
    //           sub_business_category_id: null,
    //           logo: null,
    //           website:
    //             "https://hungerstation.com/sa-en/restaurant/gulf-crispy-2/dammam/al-manar/33279",
    //           international_brand: false,
    //           country: null,
    //           secondary_type: "brand",
    //           old_id: 29965,
    //           archived: false,
    //           created_at: "2023-09-04T08:24:05.030Z",
    //           updated_at: "2023-09-04T08:24:05.030Z",
    //           searchable: "'crispi':2 'gulf':1",
    //         },
    //         {
    //           id: 8371,
    //           name: "Gulf Diagnostic Center",
    //           category_id: 7,
    //           sub_category_id: 38,
    //           business_category_id: 7,
    //           sub_business_category_id: 34,
    //           logo: "brand_logos/Gulf_Diagnostic_Center.png",
    //           website: "http://www.gdc-hospital.com/",
    //           international_brand: false,
    //           country: null,
    //           secondary_type: "brand",
    //           old_id: 23103,
    //           archived: false,
    //           created_at: "2023-09-04T08:24:05.059Z",
    //           updated_at: "2023-09-04T08:24:05.059Z",
    //           searchable: "'center':3 'diagnost':2 'gulf':1",
    //         },
    //         {
    //           id: 8372,
    //           name: "Gulf Flower Bakery",
    //           category_id: 10,
    //           sub_category_id: 64,
    //           business_category_id: 8,
    //           sub_business_category_id: 41,
    //           logo: "brand_logos/Gulf_Flower_Bakery.png",
    //           website: "https://www.fujtown.com/listing/gulf-flower/",
    //           international_brand: false,
    //           country: null,
    //           secondary_type: "brand",
    //           old_id: 20049,
    //           archived: false,
    //           created_at: "2023-09-04T08:24:05.083Z",
    //           updated_at: "2023-09-04T08:24:05.083Z",
    //           searchable: "'bakeri':3 'flower':2 'gulf':1",
    //         },
    //         {
    //           id: 8373,
    //           name: "Gulf Food Investment",
    //           category_id: 10,
    //           sub_category_id: 58,
    //           business_category_id: 15,
    //           sub_business_category_id: 72,
    //           logo: "brand_logos/Gulf_Food_Investment.png",
    //           website:
    //             "https://manhom.com/%D8%B4%D8%B1%D9%83%D8%A7%D8%AA/%D8%B4%D8%B1%D9%83%D8%A9-%D8%A7%D9%84%D8%A7%D8%B3%D8%AA%D8%AB%D9%85%D8%A7%D8%B1-%D8%A7%D9%84%D8%AE%D9%84%D9%8A%D8%AC%D9%8A%D8%A9-%D9%84%D9%84%D8%A3%D8%BA%D8%B0%D9%8A%D8%A9/",
    //           international_brand: false,
    //           country: null,
    //           secondary_type: "brand",
    //           old_id: 30672,
    //           archived: false,
    //           created_at: "2023-09-04T08:24:05.112Z",
    //           updated_at: "2023-09-04T08:24:05.112Z",
    //           searchable: "'food':2 'gulf':1 'invest':3",
    //         },
    //         {
    //           id: 8374,
    //           name: "Gulf for Good",
    //           category_id: 2,
    //           sub_category_id: 5,
    //           business_category_id: 2,
    //           sub_business_category_id: 5,
    //           logo: "brand_logos/Gulf_for_Good.png",
    //           website: "https://www.gulf4good.org/",
    //           international_brand: false,
    //           country: null,
    //           secondary_type: "brand",
    //           old_id: 25765,
    //           archived: false,
    //           created_at: "2023-09-04T08:24:05.141Z",
    //           updated_at: "2023-09-04T08:24:05.141Z",
    //           searchable: "'good':3 'gulf':1",
    //         },
    //         {
    //           id: 8375,
    //           name: "Gulf Hospitality",
    //           category_id: 6,
    //           sub_category_id: 32,
    //           business_category_id: 6,
    //           sub_business_category_id: 21,
    //           logo: null,
    //           website: "http://www.gulfhospitality.com/",
    //           international_brand: false,
    //           country: null,
    //           secondary_type: "brand",
    //           old_id: 26797,
    //           archived: false,
    //           created_at: "2023-09-04T08:24:05.164Z",
    //           updated_at: "2023-09-04T08:24:05.164Z",
    //           searchable: "'gulf':1 'hospit':2",
    //         },
    //         {
    //           id: 8376,
    //           name: "Gulf Hypermarket",
    //           category_id: 6,
    //           sub_category_id: 32,
    //           business_category_id: 6,
    //           sub_business_category_id: 21,
    //           logo: "brand_logos/Gulf_Hypermarket.png",
    //           website: "https://www.facebook.com/gulfhypermarketrak/",
    //           international_brand: false,
    //           country: null,
    //           secondary_type: "brand",
    //           old_id: 22385,
    //           archived: false,
    //           created_at: "2023-09-04T08:24:05.189Z",
    //           updated_at: "2023-09-04T08:24:05.189Z",
    //           searchable: "'gulf':1 'hypermarket':2",
    //         },
    //         {
    //           id: 8377,
    //           name: "Gulf Industrial Group",
    //           category_id: 18,
    //           sub_category_id: 109,
    //           business_category_id: 15,
    //           sub_business_category_id: 72,
    //           logo: "brand_logos/download_46.png",
    //           website: "https://www.gulfindustrialgroup.com/businesses/",
    //           international_brand: false,
    //           country: null,
    //           secondary_type: "brand",
    //           old_id: 20890,
    //           archived: false,
    //           created_at: "2023-09-04T08:24:05.214Z",
    //           updated_at: "2023-09-04T08:24:05.214Z",
    //           searchable: "'group':3 'gulf':1 'industri':2",
    //         },
    //         {
    //           id: 8380,
    //           name: "Gulf Insurance Group",
    //           category_id: 17,
    //           sub_category_id: 105,
    //           business_category_id: 7,
    //           sub_business_category_id: 34,
    //           logo: "brand_logos/Gulf_Insurance_Group.png",
    //           website: "https://www.gulfinsgroup.com/",
    //           international_brand: true,
    //           country: null,
    //           secondary_type: "brand",
    //           old_id: 27189,
    //           archived: false,
    //           created_at: "2023-09-04T08:24:05.292Z",
    //           updated_at: "2023-09-04T08:24:05.292Z",
    //           searchable: "'group':3 'gulf':1 'insur':2",
    //         },
    //         {
    //           id: 8381,
    //           name: "Gulf Brands International",
    //           category_id: 6,
    //           sub_category_id: 34,
    //           business_category_id: 6,
    //           sub_business_category_id: 21,
    //           logo: "brand_logos/gulfbrands.png",
    //           website: "https://www.gulfbrandsinternational.com/",
    //           international_brand: false,
    //           country: null,
    //           secondary_type: "brand",
    //           old_id: 22716,
    //           archived: false,
    //           created_at: "2023-09-04T08:24:05.315Z",
    //           updated_at: "2023-09-04T08:24:05.315Z",
    //           searchable: "'brand':2 'gulf':1 'intern':3",
    //         },
    //         {
    //           id: 8382,
    //           name: "Gulf Investment Corporation",
    //           category_id: 8,
    //           sub_category_id: 46,
    //           business_category_id: 15,
    //           sub_business_category_id: 72,
    //           logo: "brand_logos/Gulf_Investment_Corporation.png",
    //           website: "https://www.gic.com.kw/",
    //           international_brand: false,
    //           country: null,
    //           secondary_type: "brand",
    //           old_id: 25231,
    //           archived: false,
    //           created_at: "2023-09-04T08:24:05.338Z",
    //           updated_at: "2023-09-04T08:24:05.338Z",
    //           searchable: "'corpor':3 'gulf':1 'invest':2",
    //         },
    //         {
    //           id: 8391,
    //           name: "Gulf Kitchens and Restaurants",
    //           category_id: 10,
    //           sub_category_id: 58,
    //           business_category_id: 8,
    //           sub_business_category_id: 37,
    //           logo: "brand_logos/Gulf_Kitchens_and_Restaurants.png",
    //           website:
    //             "https://hungerstation.com/sa-en/restaurant/gulf-kitchens-and-restaurants/mecca/Al%20Jumum/41436",
    //           international_brand: false,
    //           country: null,
    //           secondary_type: "brand",
    //           old_id: 29021,
    //           archived: false,
    //           created_at: "2023-09-04T08:24:05.552Z",
    //           updated_at: "2023-09-04T08:24:05.552Z",
    //           searchable: "'gulf':1 'kitchen':2 'restaur':4",
    //         },
    //         {
    //           id: 8392,
    //           name: "Gulf news",
    //           category_id: 18,
    //           sub_category_id: 109,
    //           business_category_id: 15,
    //           sub_business_category_id: 68,
    //           logo: "brand_logos/GULF_NEW.png",
    //           website: "https://gulfnews.com/",
    //           international_brand: true,
    //           country: null,
    //           secondary_type: "brand",
    //           old_id: 23270,
    //           archived: false,
    //           created_at: "2023-09-04T08:24:05.582Z",
    //           updated_at: "2023-09-04T08:24:05.582Z",
    //           searchable: "'gulf':1 'news':2",
    //         },
    //         {
    //           id: 8402,
    //           name: "Gulf Oil",
    //           category_id: 14,
    //           sub_category_id: 91,
    //           business_category_id: 12,
    //           sub_business_category_id: 52,
    //           logo: "brand_logos/Gulf_Oil.png",
    //           website: "https://www.gulfoilltd.com/",
    //           international_brand: true,
    //           country: null,
    //           secondary_type: "brand",
    //           old_id: 21648,
    //           archived: false,
    //           created_at: "2023-09-04T08:24:05.823Z",
    //           updated_at: "2023-09-04T08:24:05.823Z",
    //           searchable: "'gulf':1 'oil':2",
    //         },
    //         {
    //           id: 8403,
    //           name: "Gulfood",
    //           category_id: 10,
    //           sub_category_id: 58,
    //           business_category_id: 8,
    //           sub_business_category_id: 37,
    //           logo: "brand_logos/Gulfood.png",
    //           website: "https://www.gulfood.com/",
    //           international_brand: false,
    //           country: null,
    //           secondary_type: "brand",
    //           old_id: 28365,
    //           archived: false,
    //           created_at: "2023-09-04T08:24:05.862Z",
    //           updated_at: "2023-09-04T08:24:05.862Z",
    //           searchable: "'gulfood':1",
    //         },
    //         {
    //           id: 8404,
    //           name: "Gulf Pastry",
    //           category_id: 10,
    //           sub_category_id: 58,
    //           business_category_id: 8,
    //           sub_business_category_id: 37,
    //           logo: "brand_logos/gulf_pastry.png",
    //           website: "https://gulfpastry.ae/",
    //           international_brand: false,
    //           country: null,
    //           secondary_type: "brand",
    //           old_id: 21982,
    //           archived: false,
    //           created_at: "2023-09-04T08:24:05.888Z",
    //           updated_at: "2023-09-04T08:24:05.888Z",
    //           searchable: "'gulf':1 'pastri':2",
    //         },
    //         {
    //           id: 8405,
    //           name: "Gulf Pharmacy Express",
    //           category_id: 7,
    //           sub_category_id: 39,
    //           business_category_id: 7,
    //           sub_business_category_id: 34,
    //           logo: "brand_logos/gulf-pharmacy-3379880951.png",
    //           website: "https://thedubaimall.com/en/shop/gulf-pharmacy-express",
    //           international_brand: false,
    //           country: null,
    //           secondary_type: "brand",
    //           old_id: 20048,
    //           archived: false,
    //           created_at: "2023-09-04T08:24:05.911Z",
    //           updated_at: "2023-09-04T08:24:05.911Z",
    //           searchable: "'express':3 'gulf':1 'pharmaci':2",
    //         },
    //         {
    //           id: 8406,
    //           name: "Gulf Photo Plus",
    //           category_id: 18,
    //           sub_category_id: 109,
    //           business_category_id: 15,
    //           sub_business_category_id: 68,
    //           logo: null,
    //           website: "https://gulfphotoplus.com/",
    //           international_brand: false,
    //           country: null,
    //           secondary_type: "brand",
    //           old_id: 24990,
    //           archived: false,
    //           created_at: "2023-09-04T08:24:05.945Z",
    //           updated_at: "2023-09-04T08:24:05.945Z",
    //           searchable: "'gulf':1 'photo':2 'plus':3",
    //         },
    //         {
    //           id: 8407,
    //           name: "Gulf Resources Insurance",
    //           category_id: 17,
    //           sub_category_id: 107,
    //           business_category_id: 7,
    //           sub_business_category_id: 36,
    //           logo: "brand_logos/Gulf_Resources.png",
    //           website: "https://www.gulf-resources.net/",
    //           international_brand: false,
    //           country: null,
    //           secondary_type: "brand",
    //           old_id: 22230,
    //           archived: false,
    //           created_at: "2023-09-04T08:24:05.969Z",
    //           updated_at: "2023-09-04T08:24:05.969Z",
    //           searchable: "'gulf':1 'insur':3 'resourc':2",
    //         },
    //         {
    //           id: 8408,
    //           name: "Gulf Restaurants",
    //           category_id: 10,
    //           sub_category_id: 58,
    //           business_category_id: 8,
    //           sub_business_category_id: 37,
    //           logo: "brand_logos/Gulf_.png",
    //           website:
    //             "https://hungerstation.com/sa-en/restaurant/Gulf%20Restaurant%20/jeddah/al-falah/8378",
    //           international_brand: false,
    //           country: null,
    //           secondary_type: "brand",
    //           old_id: 29966,
    //           archived: false,
    //           created_at: "2023-09-04T08:24:05.993Z",
    //           updated_at: "2023-09-04T08:24:05.993Z",
    //           searchable: "'gulf':1 'restaur':2",
    //         },
    //         {
    //           id: 8409,
    //           name: "Gulf Royal Chinese",
    //           category_id: 10,
    //           sub_category_id: null,
    //           business_category_id: null,
    //           sub_business_category_id: null,
    //           logo: null,
    //           website:
    //             "https://hungerstation.com/sa-en/restaurant/%D9%85%D8%B7%D8%B9%D9%85-%D8%A7%D9%84%D8%AE%D9%84%D9%8A%D8%AC-%D8%A7%D9%84%D8%B5%D9%8A%D9%86%D9%8A/%D8%AC%D8%AF%D9%87/%D8%A7%D9%84%D8%B1%D9%88%D8%B6%D8%A9/4923",
    //           international_brand: false,
    //           country: null,
    //           secondary_type: "brand",
    //           old_id: 29436,
    //           archived: false,
    //           created_at: "2023-09-04T08:24:06.016Z",
    //           updated_at: "2023-09-04T08:24:06.016Z",
    //           searchable: "'chines':3 'gulf':1 'royal':2",
    //         },
    //         {
    //           id: 8410,
    //           name: "Gulf Shell Beauty Center",
    //           category_id: 7,
    //           sub_category_id: 41,
    //           business_category_id: 7,
    //           sub_business_category_id: 36,
    //           logo: "brand_logos/Gulf_Shell_Beauty_Center.png",
    //           website: "https://www.facebook.com/gulfshellbeautycenter/",
    //           international_brand: false,
    //           country: null,
    //           secondary_type: "brand",
    //           old_id: 26364,
    //           archived: false,
    //           created_at: "2023-09-04T08:24:06.048Z",
    //           updated_at: "2023-09-04T08:24:06.048Z",
    //           searchable: "'beauti':3 'center':4 'gulf':1 'shell':2",
    //         },
    //         {
    //           id: 8411,
    //           name: "Gulf Specialized Hospital",
    //           category_id: 7,
    //           sub_category_id: 38,
    //           business_category_id: 7,
    //           sub_business_category_id: 34,
    //           logo: "brand_logos/Gulf_Specialized_Hospital.png",
    //           website: "https://gshoman.com/",
    //           international_brand: false,
    //           country: null,
    //           secondary_type: "brand",
    //           old_id: 26798,
    //           archived: false,
    //           created_at: "2023-09-04T08:24:06.070Z",
    //           updated_at: "2023-09-04T08:24:06.070Z",
    //           searchable: "'gulf':1 'hospit':3 'special':2",
    //         },
    //         {
    //           id: 8419,
    //           name: "Gulftech Electric",
    //           category_id: 11,
    //           sub_category_id: 69,
    //           business_category_id: 6,
    //           sub_business_category_id: 26,
    //           logo: "brand_logos/Gulftech_Electric.png",
    //           website: "https://gulftechgroup.com/",
    //           international_brand: false,
    //           country: null,
    //           secondary_type: "brand",
    //           old_id: 26796,
    //           archived: false,
    //           created_at: "2023-09-04T08:24:06.260Z",
    //           updated_at: "2023-09-04T08:24:06.260Z",
    //           searchable: "'electr':2 'gulftech':1",
    //         },
    //         {
    //           id: 8420,
    //           name: "Gulf Village",
    //           category_id: 10,
    //           sub_category_id: null,
    //           business_category_id: null,
    //           sub_business_category_id: null,
    //           logo: null,
    //           website:
    //             "https://hungerstation.com/sa-en/restaurant/gulf%20village%20/hail/sababah/7364",
    //           international_brand: false,
    //           country: null,
    //           secondary_type: "brand",
    //           old_id: 29967,
    //           archived: false,
    //           created_at: "2023-09-04T08:24:06.287Z",
    //           updated_at: "2023-09-04T08:24:06.287Z",
    //           searchable: "'gulf':1 'villag':2",
    //         },
    //         {
    //           id: 8462,
    //           name: "Gulf Taleed Commercial",
    //           category_id: 18,
    //           sub_category_id: 109,
    //           business_category_id: 15,
    //           sub_business_category_id: 67,
    //           logo: "brand_logos/Gulf_Taleed_Commercial.png",
    //           website: "https://www.taleed.com.sa/",
    //           international_brand: false,
    //           country: null,
    //           secondary_type: "brand",
    //           old_id: 28564,
    //           archived: false,
    //           created_at: "2023-09-04T08:24:07.439Z",
    //           updated_at: "2023-09-04T08:24:07.439Z",
    //           searchable: "'commerci':3 'gulf':1 'tale':2",
    //         },
    //         {
    //           id: 9498,
    //           name: "Amazon Gulf",
    //           category_id: 11,
    //           sub_category_id: 65,
    //           business_category_id: 7,
    //           sub_business_category_id: 30,
    //           logo: null,
    //           website: null,
    //           international_brand: false,
    //           country: null,
    //           secondary_type: "brand",
    //           old_id: 23894,
    //           archived: false,
    //           created_at: "2023-09-04T08:24:35.220Z",
    //           updated_at: "2023-09-04T08:24:35.220Z",
    //           searchable: "'amazon':1 'gulf':2",
    //         },
    //         {
    //           id: 10034,
    //           name: "Gulf Experts Auto Services",
    //           category_id: 14,
    //           sub_category_id: 93,
    //           business_category_id: 12,
    //           sub_business_category_id: 55,
    //           logo: "brand_logos/gulf.png",
    //           website: "https://www.instagram.com/gulf.experts/?hl=en",
    //           international_brand: false,
    //           country: null,
    //           secondary_type: "brand",
    //           old_id: 20046,
    //           archived: false,
    //           created_at: "2023-09-04T08:24:49.239Z",
    //           updated_at: "2023-09-04T08:24:49.239Z",
    //           searchable: "'auto':3 'expert':2 'gulf':1 'servic':4",
    //         },
    //         {
    //           id: 10035,
    //           name: "Gulf Film",
    //           category_id: 4,
    //           sub_category_id: 14,
    //           business_category_id: 4,
    //           sub_business_category_id: 11,
    //           logo: "brand_logos/Gulf_Film.png",
    //           website: "https://www.gulffilm.com/",
    //           international_brand: false,
    //           country: null,
    //           secondary_type: "brand",
    //           old_id: 20548,
    //           archived: false,
    //           created_at: "2023-09-04T08:24:49.262Z",
    //           updated_at: "2023-09-04T08:24:49.262Z",
    //           searchable: "'film':2 'gulf':1",
    //         },
    //         {
    //           id: 10923,
    //           name: "Karan Gulf Services",
    //           category_id: 18,
    //           sub_category_id: 109,
    //           business_category_id: 15,
    //           sub_business_category_id: 75,
    //           logo: "brand_logos/Karan_Gulf_Services.png",
    //           website: null,
    //           international_brand: false,
    //           country: null,
    //           secondary_type: "brand",
    //           old_id: 27281,
    //           archived: false,
    //           created_at: "2023-09-04T08:25:11.799Z",
    //           updated_at: "2023-09-04T08:25:11.799Z",
    //           searchable: "'gulf':2 'karan':1 'servic':3",
    //         },
    //         {
    //           id: 11290,
    //           name: "Zegna Gulf Trading",
    //           category_id: 18,
    //           sub_category_id: 109,
    //           business_category_id: 15,
    //           sub_business_category_id: 76,
    //           logo: null,
    //           website:
    //             "https://connect.ae/profiles/abu-dhabi/zegna-gulf-trading-(l.l.c)/profile-id=er0203044",
    //           international_brand: false,
    //           country: null,
    //           secondary_type: "brand",
    //           old_id: 23440,
    //           archived: false,
    //           created_at: "2023-09-04T08:25:21.743Z",
    //           updated_at: "2023-09-04T08:25:21.743Z",
    //           searchable: "'gulf':2 'trade':3 'zegna':1",
    //         },
    //         {
    //           id: 11995,
    //           name: "Ziqitza Gulf Medical Response and Ambulance",
    //           category_id: 7,
    //           sub_category_id: 38,
    //           business_category_id: 7,
    //           sub_business_category_id: 34,
    //           logo: "brand_logos/Ziqitza_Gulf_Medical_Response_and_Ambulance.png",
    //           website: "https://www.zgmras.com/",
    //           international_brand: false,
    //           country: null,
    //           secondary_type: "brand",
    //           old_id: 23516,
    //           archived: false,
    //           created_at: "2023-09-04T08:25:40.397Z",
    //           updated_at: "2023-09-04T08:25:40.397Z",
    //           searchable:
    //             "'ambul':6 'gulf':2 'medic':3 'respons':4 'ziqitza':1",
    //         },
    //         {
    //           id: 12449,
    //           name: "Trans Gulf Restaurant",
    //           category_id: 10,
    //           sub_category_id: 58,
    //           business_category_id: 8,
    //           sub_business_category_id: 37,
    //           logo: null,
    //           website: null,
    //           international_brand: false,
    //           country: "BH",
    //           secondary_type: "brand",
    //           old_id: 34243,
    //           archived: false,
    //           created_at: "2023-09-04T08:25:51.978Z",
    //           updated_at: "2023-09-04T08:25:51.978Z",
    //           searchable: "'gulf':2 'restaur':3 'tran':1",
    //         },
    //         {
    //           id: 13090,
    //           name: "Gulf Union Insurance",
    //           category_id: 17,
    //           sub_category_id: 104,
    //           business_category_id: 7,
    //           sub_business_category_id: 33,
    //           logo: "brand_logos/Gulf_Union_Insurance.png",
    //           website:
    //             "https://www.online.gulfunion.com.bh/dvlive/Users/index.aspx",
    //           international_brand: false,
    //           country: null,
    //           secondary_type: "brand",
    //           old_id: 34711,
    //           archived: false,
    //           created_at: "2023-09-04T08:26:08.714Z",
    //           updated_at: "2023-09-04T08:26:08.714Z",
    //           searchable: "'gulf':1 'insur':3 'union':2",
    //         },
    //         {
    //           id: 13312,
    //           name: "Trans Gulf Tyres",
    //           category_id: 14,
    //           sub_category_id: 93,
    //           business_category_id: 12,
    //           sub_business_category_id: 55,
    //           logo: null,
    //           website: null,
    //           international_brand: false,
    //           country: null,
    //           secondary_type: "brand",
    //           old_id: 35139,
    //           archived: false,
    //           created_at: "2023-09-04T08:26:14.417Z",
    //           updated_at: "2023-09-04T08:26:14.417Z",
    //           searchable: "'gulf':2 'tran':1 'tyre':3",
    //         },
    //         {
    //           id: 13353,
    //           name: "Gulf Medical University",
    //           category_id: 3,
    //           sub_category_id: 8,
    //           business_category_id: 3,
    //           sub_business_category_id: 8,
    //           logo: "brand_logos/Gulf_Medical_University.png",
    //           website: "https://gmu.ac.ae/",
    //           international_brand: false,
    //           country: null,
    //           secondary_type: "brand",
    //           old_id: 23018,
    //           archived: false,
    //           created_at: "2023-09-04T08:26:15.431Z",
    //           updated_at: "2023-09-04T08:26:15.431Z",
    //           searchable: "'gulf':1 'medic':2 'univers':3",
    //         },
    //         {
    //           id: 13384,
    //           name: "Aldharis Gulf",
    //           category_id: 18,
    //           sub_category_id: 110,
    //           business_category_id: 15,
    //           sub_business_category_id: 75,
    //           logo: "brand_logos/Aldharis_Gulf.png",
    //           website: "https://aldharisgulf.ae/",
    //           international_brand: false,
    //           country: null,
    //           secondary_type: "brand",
    //           old_id: 35038,
    //           archived: false,
    //           created_at: "2023-09-04T08:26:16.179Z",
    //           updated_at: "2023-09-04T08:26:16.179Z",
    //           searchable: "'aldhari':1 'gulf':2",
    //         },
    //         {
    //           id: 13440,
    //           name: "Naukri Gulf",
    //           category_id: 18,
    //           sub_category_id: 108,
    //           business_category_id: 16,
    //           sub_business_category_id: 77,
    //           logo: "brand_logos/Naukri_Gulf.png",
    //           website: "https://www.naukrigulf.com/",
    //           international_brand: false,
    //           country: null,
    //           secondary_type: "brand",
    //           old_id: 35220,
    //           archived: false,
    //           created_at: "2023-09-04T08:26:17.578Z",
    //           updated_at: "2023-09-04T08:26:17.578Z",
    //           searchable: "'gulf':2 'naukri':1",
    //         },
    //         {
    //           id: 13814,
    //           name: "Gulfian Soul Grocery",
    //           category_id: 6,
    //           sub_category_id: 32,
    //           business_category_id: 6,
    //           sub_business_category_id: 21,
    //           logo: null,
    //           website: null,
    //           international_brand: false,
    //           country: null,
    //           secondary_type: "brand",
    //           old_id: 35616,
    //           archived: false,
    //           created_at: "2023-09-04T08:26:27.050Z",
    //           updated_at: "2023-09-04T08:26:27.050Z",
    //           searchable: "'groceri':3 'gulfian':1 'soul':2",
    //         },
    //       ],
    //       pagination: {
    //         current_page: 1,
    //         next_page: 2,
    //         previous_page: null,
    //         total_pages: 6,
    //         per_page: 10,
    //         total_entries: 52,
    //       },
    //     },
    //   },
    //   500
    // );
  }
  getCategoriesList(filters) {
    return RestService.getWithParams("/categories", filters);
    // return useMockFetch({
    //   data: {
    //     categories: [
    //       {
    //         id: 1,
    //         name: "Financial Services",
    //         old_id: "1",
    //         archived: false,
    //         created_at: "2023-09-04T08:20:15.105Z",
    //         updated_at: "2023-09-04T08:20:15.105Z",
    //         searchable: "'financi':1 'servic':2",
    //       },
    //       {
    //         id: 5,
    //         name: "Government Services",
    //         old_id: "5",
    //         archived: false,
    //         created_at: "2023-09-04T08:20:15.253Z",
    //         updated_at: "2023-09-04T08:20:15.253Z",
    //         searchable: "'govern':1 'servic':2",
    //       },
    //       {
    //         id: 18,
    //         name: "Services",
    //         old_id: "18",
    //         archived: false,
    //         created_at: "2023-09-04T08:20:15.528Z",
    //         updated_at: "2023-09-04T08:20:15.528Z",
    //         searchable: "'servic':1",
    //       },
    //     ],
    //     pagination: {},
    //   },
    // });
  }
  getBrandsData(filters, page) {
    return RestService.getWithParams("/brands/spend", filters, page);
    // return useMockFetch({
    //   data: {
    //     brands_count: 40,
    //     brands: [
    //       {
    //         id: "8",
    //         name: "National Bank of Fujairah NBF",
    //         logo: "brand_logos/nbf.png",
    //         tenant_customers_total_spend: -13230.0,
    //         tenant_customers_count: 100,
    //         tenant_customers_avg_spend: -20.0,
    //         tenant_customers_transactions_count: 23,

    //         comparison_period_tenant_customers_total_spend: 3423.0,
    //         comparison_period_tenant_customers_count: 200.0,
    //         comparison_period_tenant_customers_avg_spend: 423.0,
    //         comparison_period_tenant_customers_transactions_count: 327,
    //       },
    //       {
    //         id: "11",
    //         name: "Brand 123",
    //         logo: "brand_logos/arb.png",
    //         tenant_customers_total_spend: 230.0,
    //         tenant_customers_count: 5,
    //         tenant_customers_avg_spend: -2345.0,
    //         tenant_customers_transactions_count: 23,
    //         comparison_period_tenant_customers_total_spend: 2340.0,
    //         comparison_period_tenant_customers_count: 0.0,
    //         comparison_period_tenant_customers_avg_spend: -200.0,
    //         comparison_period_tenant_customers_transactions_count: 23,
    //       },
    //     ],
    //   },
    // });
  }
  getBrandSpending(brandId, filters, periodDays) {
    let timeInterval = "daily";

    switch (true) {
      case periodDays <= 31:
        timeInterval = "daily";
        break;

      case periodDays > 31 && periodDays < 365:
        timeInterval = "weekly";
        break;

      case periodDays >= 365:
        timeInterval = "monthly";
        break;
    }
    const endpoint = `/brands/${brandId}/spend/${timeInterval}`;

    return RestService.getWithParams(endpoint, filters);
  }
  getCategoriesSummaryData(filters) {
    return RestService.getWithParams("/categories/spend/summary", filters);
  }
  getCategoriesData(filters) {
    return RestService.getWithParams("/categories/spend", filters);
    // return useMockFetch({
    //   data: {
    //     categories: [
    //       {
    //         id: "1",
    //         name: "Financial Services",
    //         tenant_customers_total_spend: -100.0,
    //         tenant_customers_count: 53,
    //         tenant_customers_avg_spend: -220.0,
    //         tenant_customers_transactions_count: 23,
    //         comparison_period_tenant_customers_total_spend: 233.0,
    //         comparison_period_tenant_customers_count: 23.0,
    //         comparison_period_tenant_customers_avg_spend: 3.0,
    //         comparison_period_tenant_customers_transactions_count: 213,
    //       },
    //       {
    //         id: "2",
    //         name: "Charity Gifts",
    //         tenant_customers_total_spend: -100.0,
    //         tenant_customers_count: 5,
    //         tenant_customers_avg_spend: -20.0,
    //         tenant_customers_transactions_count: 23,
    //         comparison_period_tenant_customers_total_spend: 0.0,
    //         comparison_period_tenant_customers_count: 0.0,
    //         comparison_period_tenant_customers_avg_spend: 0.0,
    //         comparison_period_tenant_customers_transactions_count: 23,
    //       },
    //     ],
    //   },
    // });
  }
  getCategorySpending(categoryId, filters, periodDays) {
    let timeInterval = "day";

    switch (true) {
      case periodDays <= 31:
        timeInterval = "day";
        break;

      case periodDays > 31 && periodDays < 365:
        timeInterval = "week";
        break;

      case periodDays >= 365:
        timeInterval = "month";
        break;
    }
    const endpoint = `/categories/${categoryId}/spend/${timeInterval}`;

    return RestService.getWithParams(endpoint, filters);
    // return useMockFetch({
    //   data: {
    //     category: [
    //       {
    //         tenant_customers_transaction_count_date: "2020-04-07",
    //         comparison_period_tenant_customers_transaction_date: "2020-04-05",
    //         tenant_customers_total_spend: -234.55,
    //         tenant_customers_avg_spend: -434.55,
    //         comparison_period_tenant_customers_total_spend: 243.0,
    //         comparison_period_tenant_customers_avg_spend: 424.0,
    //       },
    //       {
    //         tenant_customers_transaction_count_date: "2020-04-08",
    //         comparison_period_tenant_customers_transaction_date: "2020-04-15",
    //         tenant_customers_total_spend: -225.55,
    //         tenant_customers_avg_spend: -5677.55,
    //         comparison_period_tenant_customers_total_spend: 754.0,
    //         comparison_period_tenant_customers_avg_spend: 154.0,
    //       },
    //     ],
    //   },
    // });
  }
  getTopBrandsPerCategory(filters, categoryId) {
    return RestService.getWithParams(
      `/categories/${categoryId}/brands/spend`,
      filters
    );
  }
  getSpendChartData(filters, daysPeriod, options) {
    let timeInterval = "daily";

    switch (true) {
      case daysPeriod <= 31:
        timeInterval = "daily";
        break;

      case daysPeriod > 31 && daysPeriod < 365:
        timeInterval = "weekly";
        break;

      case daysPeriod >= 365:
        timeInterval = "monthly";
        break;
    }
    const endpoint = `/tenant_customers/spend/${timeInterval}`;

    return RestService.getWithParams(endpoint, filters, null, { ...options });
  }
  getUsersAgesData(filters) {
    return RestService.getWithParams(
      "/tenant_customers/demographics/age",
      filters
    );
  }
  getOverviewCashFlowData(filters) {
    return RestService.getWithParams("/tenant_customers/cashflow", filters);
  }
  getSpendPerCurrencyData(filters) {
    return RestService.getWithParams(
      "/tenant_customers/spend/currency",
      filters
    );
  }
  getSpendPerTxTypeData(filters) {
    return RestService.getWithParams(
      "/tenant_customers/spend/transaction_type",
      filters
    );
    // return useMockFetch({
    //   data: {
    //     tenant_customers_spends: [
    //       {
    //         tenant_customers_transactions_count: "291.0",
    //         tenant_customers_count: 149,
    //         tenant_transaction_type: "Accnt Transfer",
    //         tenant_transaction_type_id: "1",
    //         tenant_customers_avg_spend: -507428.2087248323,
    //         tenant_customers_total_spend: -75606803.10000001,
    //         comparison_period_tenant_customers_avg_spend: -447980.4525850341,
    //         comparison_period_tenant_customers_total_spend: -131706253.06000002,
    //         comparison_period_tenant_customers_count: 294.0,
    //       },
    //       {
    //         tenant_customers_transactions_count: "81.0",
    //         tenant_customers_count: 40,
    //         tenant_transaction_type: "Acc Trf Sal",
    //         tenant_transaction_type_id: "2",
    //         tenant_customers_avg_spend: -1171.8882499999997,
    //         tenant_customers_total_spend: -46875.52999999999,
    //         comparison_period_tenant_customers_avg_spend: -1439.8045263157894,
    //         comparison_period_tenant_customers_total_spend: -136781.43,
    //         comparison_period_tenant_customers_count: 95.0,
    //       },
    //       {
    //         tenant_customers_transactions_count: "9.0",
    //         tenant_customers_count: 9,
    //         tenant_transaction_type: "Acct Trnsfr HR",
    //         tenant_transaction_type_id: "3",
    //         tenant_customers_avg_spend: -3673.4444444444443,
    //         tenant_customers_total_spend: -33061.0,
    //         comparison_period_tenant_customers_avg_spend: -221403.1875,
    //         comparison_period_tenant_customers_total_spend: -3542451.0,
    //         comparison_period_tenant_customers_count: 16.0,
    //       },
    //     ],
    //   },
    // });
  }
  getAllUsersTransactions(filters) {
    return RestService.getWithParams("/tenant_transactions", filters);
    // return useMockFetch({
    //   data: {
    //     tenant_customers_transactions_count: 3,
    //     tenant_customers_transactions: [
    //       {
    //         id: 17820536,
    //         tenant_customer_id: 23940,
    //         tenant_customer_cloud_id: "M20141231304485255813",
    //         raw_description:
    //           "DHL r fr frf erfmre feriomfrie feromferomfero ofmeromfer",
    //         amount: 13,
    //         global_tx_id: "e8917ee0-7afd-4e10-9fa3-5b87c8cdf5b1",
    //         transaction_id: "e8917ee0-7afd-4e10-9fa3-5b87c8cdf5b1",
    //         ref_id: "e8917ee0-7afd-4e10-9fa3-5b87c8cdf5b1",
    //         mcc: null,
    //         currency_code: "AED",
    //         transaction_date: "2022-12-25",
    //         category_id: null,
    //         brand_id: 13495,
    //         brand_compliant: true,
    //         brand_name: "DHL Express foremfoer ofmero omfre omfre",
    //         brand_logo_url: "brand_logos/DHL_Express.png",
    //         user_recommended_brand_name: null,
    //         is_invalid_brand: null,
    //         is_invalid_category: null,
    //         user_rec_category_id: null,
    //         user_rec_sub_category_id: null,
    //         user_rec_business_category_id: null,
    //         user_rec_business_sub_category_id: null,
    //         is_invalid_logo: null,
    //         user_rec_logo: null,
    //         invalid_logo_reason: null,
    //         invalid_category_reason: null,
    //         invalid_brand_reason: null,
    //         review: {
    //           status: null,
    //           report_open_date: null,
    //           report_close_date: null,
    //         },
    //         carbon_emission: "0.0",
    //         external: true,
    //         transfer_from: {
    //           id: 9045,
    //           name: "Al Shahbaa",
    //           logo: "brand_logos/DHL_Express.png",
    //         },
    //         transfer_to: {
    //           id: 9036,
    //           name: "HOA Beauty Salon",
    //           logo: "brand_logos/HOA_Beauty_Salon.png",
    //         },
    //         transfer_purpose: {
    //           id: 2,
    //           name: "Payment",
    //         },
    //       },
    //       {
    //         id: 17820537,
    //         tenant_customer_id: 23940,
    //         tenant_customer_cloud_id: "M20141231304485255813",
    //         raw_description: "DHL",
    //         amount: 13,
    //         global_tx_id: "ffb5fc97-c8bf-40f5-9062-873be7dea527",
    //         transaction_id: "ffb5fc97-c8bf-40f5-9062-873be7dea527",
    //         ref_id: "ffb5fc97-c8bf-40f5-9062-873be7dea527",
    //         mcc: null,
    //         currency_code: "AED",
    //         transaction_date: "2022-12-25",
    //         category_id: 14,
    //         brand_id: 13495,
    //         brand_compliant: true,
    //         brand_name: "DHL Express",
    //         brand_logo_url: "brand_logos/DHL_Express.png",
    //         user_recommended_brand_name: null,
    //         is_invalid_brand: null,
    //         is_invalid_category: null,
    //         user_rec_category_id: null,
    //         user_rec_sub_category_id: null,
    //         user_rec_business_category_id: null,
    //         user_rec_business_sub_category_id: null,
    //         is_invalid_logo: null,
    //         user_rec_logo: null,
    //         invalid_logo_reason: null,
    //         invalid_category_reason: null,
    //         invalid_brand_reason: null,
    //         review: {
    //           status: null,
    //           report_open_date: null,
    //           report_close_date: null,
    //         },
    //         carbon_emission: "0.0",
    //         external: true,
    //         transfer_from: "Adib",
    //         transfer_to: "NBD",
    //         transfer_purpose: {
    //           id: 2,
    //           name: "Payment",
    //         },
    //       },

    //       {
    //         id: 17820524,
    //         tenant_customer_id: 23940,
    //         tenant_customer_cloud_id: "M20141231304485255813",
    //         raw_description: "DHL",
    //         amount: 13,
    //         global_tx_id: "80f70518-7e09-4653-8d39-3640e5926237",
    //         transaction_id: "80f70518-7e09-4653-8d39-3640e5926237",
    //         ref_id: "80f70518-7e09-4653-8d39-3640e5926237",
    //         mcc: null,
    //         currency_code: "AED",
    //         transaction_date: "2022-12-25",
    //         category_id: 14,
    //         brand_id: 13495,
    //         brand_compliant: true,
    //         brand_name: "DHL Express",
    //         brand_logo_url: "brand_logos/DHL_Express.png",
    //         user_recommended_brand_name: null,
    //         is_invalid_brand: null,
    //         is_invalid_category: null,
    //         user_rec_category_id: null,
    //         user_rec_sub_category_id: null,
    //         user_rec_business_category_id: null,
    //         user_rec_business_sub_category_id: null,
    //         is_invalid_logo: null,
    //         user_rec_logo: null,
    //         invalid_logo_reason: null,
    //         invalid_category_reason: null,
    //         invalid_brand_reason: null,
    //         review: {
    //           status: null,
    //           report_open_date: null,
    //           report_close_date: null,
    //         },
    //         carbon_emission: "0.0",
    //         external: true,
    //       },
    //     ],
    //   },
    // });
  }
  reportTransaction(id, payload) {
    return RestService.apiClient.post("/tenant_enrichment_feedbacks", {
      ...payload,
      tenant_customer_transactions_id: id,
    });
  }
}

export default new OverviewAPI();
