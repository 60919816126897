// import { useMockFetch } from "@/composables/Helpers";
import RestService from "../RestService";

class DemographicsAPI {
  getUsersCountriesData(filters) {
    return RestService.getWithParams(
      "/tenant_customers/demographics/country",
      filters
    );
    // return useMockFetch({
    //   data: {
    //     demographics: [
    //       {
    //         tenant_customers_nationality: "AE",
    //         tenant_customers_count: 8229,
    //       },
    //       {
    //         tenant_customers_nationality: "AF",
    //         tenant_customers_count: 721,
    //       },
    //       {
    //         tenant_customers_nationality: "US",
    //         tenant_customers_count: 721,
    //       },
    //       {
    //         tenant_customers_nationality: "AR",
    //         tenant_customers_count: 721,
    //       },
    //       {
    //         tenant_customers_nationality: "FA",
    //         tenant_customers_count: 721,
    //       },
    //       {
    //         tenant_customers_nationality: "UE",
    //         tenant_customers_count: 721,
    //       },
    //       {
    //         tenant_customers_nationality: "UE",
    //         tenant_customers_count: 721,
    //       },
    //       {
    //         tenant_customers_nationality: "UE",
    //         tenant_customers_count: 721,
    //       },
    //       {
    //         tenant_customers_nationality: "UE",
    //         tenant_customers_count: 721,
    //       },
    //       {
    //         tenant_customers_nationality: "UE",
    //         tenant_customers_count: 721,
    //       },
    //     ],
    //   },
    // });
  }
  getUsersGendersData(filters) {
    return RestService.getWithParams(
      "/tenant_customers/demographics/gender",
      filters
    );
    // return useMockFetch({
    //   data: {
    //     demographics: [
    //       {
    //         male: {
    //           tenant_customers_gender: "Male",
    //           tenant_customers_count: 234,
    //           comparison_period_tenant_customers_count: 230,
    //         },
    //       },
    //       {
    //         female: {
    //           tenant_customers_gender: "Female",
    //           tenant_customers_count: 222222,
    //           comparison_period_tenant_customers_count: 33334,
    //         },
    //       },
    //     ],
    //   },
    // });
  }
}

export default new DemographicsAPI();
