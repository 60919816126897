<template>
  <div class="d-flex justify-content-start align-items-center">
    <PageTitleTopBrandTag v-if="route?.name === 'SpecificBrand'" class="mt-3" />
  </div>
</template>

<script>
import PageTitleTopBrandTag from "../PageTitleTopBrandTag.vue";
import { useRoute } from "vue-router";

export default {
  components: { PageTitleTopBrandTag },
  setup() {
    const route = useRoute();

    return { route };
  },
};
</script>

<style lang="scss" scoped></style>
