<template>
  <div class="ms-3 d-flex justify-content-between align-items-center gap-4">
    <div class="previous label">
      <div class="box"></div>
      <p>Previous period</p>
    </div>

    <div class="current label">
      <div class="box"></div>
      <p>Current Period</p>
    </div>
  </div>
</template>

<script>
export default {};
</script>

<style lang="scss" scoped>
.label {
  display: flex;
  align-items: center;
  gap: 0.35rem;

  p {
    margin: 0;
    padding: 0;
    font-size: 0.92rem;
  }
  .box {
    width: 10px;
    height: 10px;
    border-radius: 3px;
    background-color: var(--sea);
  }

  &.previous {
    .box {
      background-color: var(--DarkGreen-50, #809795);
    }
    p {
      color: var(--DarkGreen-50, #809795);
    }
  }

  &.current {
    .box {
      background-color: var(--sea);
    }
    p {
      color: var(--sea);
    }
  }
}
</style>
