<template>
  <div class="d-flex flex-row flex-wrap gap-3">
    <!-- appliedFilters:{{ appliedFilters }} -->
    <!-- <h4>finalFilters: {{ finalFilters }}</h4>
    <h6>timeFilters: {{ timeFilters }}</h6> -->
    <!-- <h6>selectedCategoriesFilter: {{ selectedCategoriesFilter }}</h6>
    <h6>selectedBrandsFilter: {{ selectedBrandsFilter }}</h6> -->

    <BaseMultiSelect
      v-show="!($route.meta?.hiddenFilters ?? []).includes('nationality')"
      v-model="selectedNationalities"
      label="Nationality"
      plural="Nationalities"
      :options="countriesOptions"
      place-holder="Search by country"
      :filter="true"
    />
    <BaseMultiSelect
      v-show="!($route.meta?.hiddenFilters ?? []).includes('gender')"
      v-model="selectedGenders"
      label="Gender"
      plural="Genders"
      :options="gendersOptions"
      :filter="false"
    />
    <BaseMultiSelect
      v-show="!($route.meta?.hiddenFilters ?? []).includes('segment')"
      v-model="selectedSegments"
      label="Segment"
      plural="Segments"
      :options="segmentsOptions"
      :filter="false"
      :loading-async="loadingSegments"
    />
    <BaseMultiSelect
      v-show="!($route.meta?.hiddenFilters ?? []).includes('account_category')"
      v-model="selectedCategories"
      label="Account Category"
      plural="Account Categories"
      :options="categoryOptions"
      :filter="false"
      :loading-async="loadingCategories"
    />
    <BaseMultiSelect
      v-show="($route.meta?.shownFilters ?? []).includes('transaction_types')"
      v-model="selectedTransactionTypes"
      label="Transaction Type"
      plural="Transaction Types"
      :options="transactionTypeOptions"
      :filter="true"
      :loading-async="loadingTransactionTypes"
    />

    <BaseMultiSelect
      v-show="($route.meta?.shownFilters ?? []).includes('transfer_purposes')"
      v-model="selectedTransferPurposes"
      label="Purpose of Transfer"
      plural="Transfer Purposes"
      :options="transferPurposeOptions"
      :filter="true"
      :loading-async="loadingTransferPurposes"
    />

    <BaseMultiSelect
      v-show="!($route.meta?.hiddenFilters ?? []).includes('currency')"
      v-model="selectedCurrency"
      label="Transaction Currency"
      plural="Currencies"
      :options="currencyOptions"
      :filter="false"
      :selection-limit="1"
    />

    <!--Multi currency-->
    <BaseMultiSelect
      v-show="($route.meta?.shownFilters ?? []).includes('multi_currency')"
      v-model="selectedMultiCurrencies"
      label="Transaction Currencies"
      plural="Currencies"
      :options="currencyOptions"
      :filter="true"
    />
    <!--Multi currency-->

    <BaseMultiSelect
      v-show="!($route.meta?.hiddenFilters ?? []).includes('age')"
      label="Age"
      plural="Ages"
      :options="[]"
      :range-inputs="true"
      :loading-async="false"
      :filter="false"
      :range-values="selectedAges"
      @range-change="setAgeRange"
    />
    <BaseMultiSelect
      v-show="!($route.meta?.hiddenFilters ?? []).includes('income')"
      label="Income"
      plural="Income"
      :options="[]"
      :range-inputs="true"
      :loading-async="false"
      :filter="false"
      :range-values="selectedIncome"
      @range-change="setIncomeRange"
    />

    <!-- === === User Filters === ===-->
    <BaseMultiSelect
      v-show="($route.meta?.shownFilters ?? []).includes('account_id')"
      v-model="selectedUserAccounts"
      label="Account Type"
      plural="Accounts"
      :options="userAccounts"
      :filter="false"
      :loading-async="loadingUserAccounts"
    />

    <BaseMultiSelect
      v-show="($route.meta?.shownFilters ?? []).includes('user_currency')"
      v-model="selectedUserCurrencies"
      label="Transaction Currency"
      plural="Currencies"
      :options="userCurrencies"
      :filter="false"
      :loading-async="loadingUserAccounts"
    />
    <!-- ==== ==== ==== ===-->

    <!-- ==== Brands ===-->
    <div v-show="($route.meta?.shownFilters ?? []).includes('brands')">
      <BrandsPopUpFilter />
    </div>
    <!-- ==== ==== ==== ===-->

    <!-- ==== Categories ===-->
    <div v-show="($route.meta?.shownFilters ?? []).includes('categories')">
      <CategoriesPopUpFilter />
    </div>
    <!-- ==== ==== ==== ===-->

    <ActionButton
      type="button"
      label="Apply"
      class="btn-primary filter-btn px-4"
      @click="applyFilters"
    />
    <ActionButton
      type="button"
      label="Clear"
      class="btn-transparent my-1 px-2"
      @click="clearFilters"
    />
  </div>
</template>

<script>
import CategoriesPopUpFilter from "./CategoriesPopUpFilter.vue";
import BrandsPopUpFilter from "./BrandsPopUpFilter.vue";
import BaseMultiSelect from "@/components/helpers/BaseMultiSelect.vue";
import ActionButton from "./helpers/ActionButton.vue";
import { countries, genders, currencies } from "@/data/options";
import { computed, onMounted, ref, watch } from "vue";
import { useStore } from "vuex";
import { storeMainFilters, retrieveMainFilters } from "@/composables/Filters";
import { useRoute } from "vue-router";

export default {
  components: {
    BaseMultiSelect,
    ActionButton,
    BrandsPopUpFilter,
    CategoriesPopUpFilter,
  },
  emits: ["applied-filters", "cleared-filters"],
  setup(props, { emit }) {
    const store = useStore();
    const route = useRoute();

    const timeFilters = computed(() => store.state.overViewStore.timeFilters);
    const metricFilters = computed(
      () => store.state.overViewStore.metricFilters
    );

    const selectedBrandsFilter = computed(() => {
      return store.state.overViewStore.selectedBrandsFilter;
    });
    const selectedCategoriesFilter = computed(() => {
      return store.state.overViewStore.selectedCategoriesFilter;
    });

    const selectedNationalities = ref([]);
    const selectedGenders = ref([]);
    const selectedSegments = ref([]);
    const selectedCategories = ref([]);
    const selectedTransactionTypes = ref([]);
    const selectedTransferPurposes = ref([]);
    const selectedCurrency = ref([]);
    const selectedAges = ref([]);
    const selectedIncome = ref([]);
    const selectedUserAccounts = ref([]);
    const selectedUserCurrencies = ref([]);
    const selectedMultiCurrencies = ref([]);

    const finalFilters = computed(() => store.getters.filters);

    //ref states
    const appliedFilters = computed(
      () => store.state.overViewStore.appliedFilters
    );

    //functions
    function applyFilters() {
      //Set global Currency
      const originCurrency =
        JSON.parse(localStorage.getItem("user"))?.preferred_currency ?? "AED";
      store.commit(
        "updateCurrency",
        selectedCurrency.value[0]?.key || originCurrency
      );

      store.commit("updateAppliedFilters", {
        from_date: appliedFilters.value?.from_date,
        to_date: appliedFilters.value?.to_date,
        nationality: [...selectedNationalities.value],
        gender: [...selectedGenders.value],
        segment: [...selectedSegments.value],
        account_category: [...selectedCategories.value],
        transaction_types: [...selectedTransactionTypes.value],
        transfer_purposes: [...selectedTransferPurposes.value],
        account_id: [...selectedUserAccounts.value],
        user_currency: [...selectedUserCurrencies.value],
        multi_currency: [...selectedMultiCurrencies.value],
        currency: [...selectedCurrency.value],
        age: [...selectedAges.value],
        income: [...selectedIncome.value],
      });

      emit("applied-filters", { ...appliedFilters.value });
    }
    function clearFilters() {
      //Rest Brands/Categories Popup filters
      store.commit("updateClearSelectedBrands", true);
      store.commit("updateSelectedBrandsFilter", []);

      store.commit("updateClearSelectedCategories", true);
      store.commit("updateSelectedCategoriesFilter", []);

      //Reset currency
      const originCurrency =
        JSON.parse(localStorage.getItem("user"))?.preferred_currency ?? "AED";
      store.commit("updateCurrency", originCurrency);

      store.commit("updateAppliedFilters", {
        from_date: appliedFilters.value?.from_date,
        to_date: appliedFilters.value?.to_date,
      });
      selectedNationalities.value = [];
      selectedGenders.value = [];
      selectedSegments.value = [];
      selectedCategories.value = [];
      selectedTransactionTypes.value = [];
      selectedTransferPurposes.value = [];
      selectedUserAccounts.value = [];
      selectedUserCurrencies.value = [];
      selectedMultiCurrencies.value = [];
      selectedCurrency.value = [];
      selectedAges.value = [];
      selectedIncome.value = [];
      emit("cleared-filters");
    }
    function setAgeRange(range) {
      selectedAges.value = range;
    }
    function setIncomeRange(range) {
      selectedIncome.value = range;
    }
    function getCachedFilters() {
      const cachedQueryFilters = retrieveMainFilters();

      //[1] Reterive cached filters
      selectedNationalities.value = [
        ...(cachedQueryFilters?.nationality ?? []),
      ];
      selectedGenders.value = [...(cachedQueryFilters?.gender ?? [])];
      selectedSegments.value = [...(cachedQueryFilters?.segment ?? [])];
      selectedCategories.value = [
        ...(cachedQueryFilters?.account_category ?? []),
      ];
      selectedTransactionTypes.value = [
        ...(cachedQueryFilters?.transaction_types ?? []),
      ];
      selectedTransferPurposes.value = [
        ...(cachedQueryFilters?.transfer_purposes ?? []),
      ];
      selectedUserAccounts.value = [...(cachedQueryFilters?.account_id ?? [])];
      selectedUserCurrencies.value = [
        ...(cachedQueryFilters?.user_currency ?? []),
      ];
      selectedMultiCurrencies.value = [
        ...(cachedQueryFilters?.multi_currency ?? []),
      ];
      selectedCurrency.value = [...(cachedQueryFilters?.currency ?? [])];
      selectedAges.value = [...(cachedQueryFilters?.age ?? [])];
      selectedIncome.value = [...(cachedQueryFilters?.income ?? [])];

      //[2] Reterive cahced metrics
      store.commit("updateSelectedMetrics", {
        ...(cachedQueryFilters?.metric ?? metricFilters.value),
      });

      //[3] Reterive cahced date filters
      store.commit("updateTimeFilters", {
        ...(cachedQueryFilters?.timeFilters ?? timeFilters.value),
      });

      // //[4] Reterive cached brands and categories
      store.commit("updateClearSelectedBrands", true);
      store.commit("updateSelectedBrandsFilter", [
        ...(cachedQueryFilters?.brands ?? []),
      ]);

      store.commit("updateClearSelectedCategories", true);
      store.commit("updateSelectedCategoriesFilter", [
        ...(cachedQueryFilters?.categories ?? []),
      ]);

      //[5] Apply Cached filters
      applyFilters();
    }
    function fetchOptions() {
      store.dispatch("getFilterSegements");
      store.dispatch("getFilterCategories");
    }
    function setFiltersState(newFilters) {
      selectedNationalities.value = [...(newFilters?.nationality ?? [])];
      selectedGenders.value = [...(newFilters?.gender ?? [])];
      selectedSegments.value = [...(newFilters?.segment ?? [])];
      selectedCategories.value = [...(newFilters?.account_category ?? [])];
      selectedTransactionTypes.value = [
        ...(newFilters?.transaction_types ?? []),
      ];
      selectedTransferPurposes.value = [
        ...(newFilters?.transfer_purposes ?? []),
      ];
      selectedUserAccounts.value = [...(newFilters?.account_id ?? [])];
      selectedUserCurrencies.value = [...(newFilters?.user_currency ?? [])];
      selectedMultiCurrencies.value = [...(newFilters?.multi_currency ?? [])];
      selectedCurrency.value = [...(newFilters?.currency ?? [])];
      selectedAges.value = [...(newFilters?.age ?? [])];
      selectedIncome.value = [...(newFilters?.income ?? [])];
    }

    const countriesOptions = computed(() => {
      let countriesList = [];
      for (let key in countries) {
        countriesList.push({
          key,
          value: countries[key],
        });
      }
      return countriesList;
    });

    const gendersOptions = computed(() => {
      let gendersList = [];
      for (let key in genders) {
        gendersList.push({
          key,
          value: genders[key],
        });
      }
      return gendersList;
    });

    const segmentsOptions = computed(() => store.state.overViewStore.segments);
    const loadingSegments = computed(
      () => store.state.overViewStore.loadingSegments
    );

    const categoryOptions = computed(
      () => store.state.overViewStore.categories
    );
    const loadingCategories = computed(
      () => store.state.overViewStore.loadingCategories
    );

    const transactionTypeOptions = computed(
      () => store.state.overViewStore.transactionTypes
    );
    const transferPurposeOptions = computed(
      () => store.state.overViewStore.transferPurposes
    );
    const loadingTransactionTypes = computed(
      () => store.state.overViewStore.loadingTransactionTypes
    );
    const loadingTransferPurposes = computed(
      () => store.state.overViewStore.loadingTransferPurposes
    );

    const userAccounts = computed(() => store.state.overViewStore.userAccounts);
    const loadingUserAccounts = computed(
      () => store.state.overViewStore.loadingUserAccounts
    );

    const userCurrencies = computed(() => {
      //[1] if empty
      const userAccounts = store.state.overViewStore.userAccounts;
      if (userAccounts.length === 0) return [];

      //[2] return filtered currencies by user account currencies
      let data = userAccounts.map((item) => ({
        value: item?.currency,
        key: item?.currency,
      }));

      // Remove dublicate currencies
      data = data.filter(
        (value, index, self) =>
          index === self.findIndex((currency) => currency.key === value.key)
      );

      return data;
    });

    const currencyOptions = computed(() => {
      let currenciesList = [];
      for (let key in currencies) {
        currenciesList.push({
          key,
          value: currencies[key],
        });
      }
      return currenciesList;
    });

    function setFiltersQuery(filters) {
      //encode the filters
      storeMainFilters({
        ...filters,
        ...{ metric: metricFilters.value },
        ...{ timeFilters: timeFilters.value },
        ...{ brands: selectedBrandsFilter.value },
        ...{ categories: selectedCategoriesFilter.value },
      });
    }

    //== Watchers ==
    watch(
      () => route.name,
      () => {
        if (!route.meta?.hideAllMainFilters)
          setFiltersQuery(appliedFilters.value);
      }
    );

    watch(
      [metricFilters, selectedCategoriesFilter, selectedBrandsFilter],
      () => {
        setFiltersQuery(appliedFilters.value);
      }
    );

    watch(appliedFilters, (newFilters) => {
      //[1] Set filters states
      setFiltersState(newFilters); //Add filter state to the components whenever the appliedFilters changes

      //[2] Set filters in the URL as query params
      setFiltersQuery(newFilters);
    });

    //== Hooks ==
    onMounted(() => {
      //[1] get cached filters
      getCachedFilters();

      //[2] fetch segments & entity categories
      fetchOptions();
    });

    return {
      appliedFilters,
      countriesOptions,
      gendersOptions,
      selectedNationalities,
      selectedGenders,
      selectedSegments,
      loadingSegments,
      segmentsOptions,
      selectedCategories,
      selectedTransactionTypes,
      selectedTransferPurposes,
      selectedUserAccounts,
      selectedUserCurrencies,
      selectedMultiCurrencies,
      categoryOptions,
      transactionTypeOptions,
      transferPurposeOptions,
      loadingCategories,
      loadingTransactionTypes,
      loadingTransferPurposes,
      currencyOptions,
      selectedCurrency,
      selectedAges,
      selectedIncome,
      finalFilters,
      userAccounts,
      userCurrencies,
      loadingUserAccounts,
      setAgeRange,
      setIncomeRange,
      applyFilters,
      clearFilters,
      timeFilters,
      selectedBrandsFilter,
      selectedCategoriesFilter,
    };
  },
};
</script>
<style scoped>
.filter-btn {
  font-size: var(--fs-input);
  margin-top: 0.1rem;
  margin-bottom: 0.1rem;
  min-height: 40px;
}
</style>
