<template>
  <div class="h-100 pb-4 px-4 hide-scrollbar">
    <div class="my-4 mt-3">
      <BaseViewInfoText />
    </div>

    <div class="w-100 h-100 d-flex flex-column">
      <!--  === Basic Info ===  -->
      <BaseCardView
        class="col-12 pb-4 pt-2"
        title="Basic Information"
        loading-height="12vh"
        loading-icon="1.8rem"
        :loading="userAccountsLoading"
      >
        <template #header>
          <div class="ms-auto d-flex align-items-center justify-content-end">
            <CustomerRMName
              :data="customersDetails"
              :loading="customersDetailsLoading"
            />
          </div>
        </template>

        <template #content
          ><UserDetailsBasicInfo
            :data="userAccounts"
            :selected-account="selectedUserAccount"
          />
        </template>
      </BaseCardView>
      <!--  ====== XXXXX =====  -->
      <!-- selectedUserAccount: {{ selectedUserAccount }} -->

      <!--  === User-Accounts ===  -->
      <div class="col-12 py-4">
        <UserAccountsBar :loading="userAccountsLoading" :data="userAccounts" />
      </div>

      <!--  ====== XXXXX =====  -->

      <!--  === Overview ===  -->
      <BaseCardView
        class="col-12 py-4"
        title="Overview"
        loading-height="9vh"
        loading-icon="1.8rem"
        :loading="userAccountsLoading"
      >
        <template #content>
          <UserOverviewSummaryCards
            :data="selectedUserAccount"
            @view-txs-clicked="routeToUserTxs"
          />
        </template>
      </BaseCardView>
      <!--  ====== XXXXX =====  -->

      <!--  === Balance Chart ===  -->
      <!-- <BaseCardView
        class="col-12 py-4"
        title="Balance"
        :loading="userBalancesLoading"
      >
        <template #content>
          <UserBalanceChart
            :data="userBalances"
            :selected-user-details="selectedUserDetails"
          />
        </template>
      </BaseCardView> -->
      <!--  ====== XXXXX =====  -->

      <div class="d-flex">
        <!--  === Cashflow ===  -->
        <BaseCardView
          class="col-6 py-4 pe-4"
          title="Cashflow"
          :loading="userCashflowLoading || userAccountsLoading"
        >
          <template #content>
            <UserCashFlow :data="userCashflow" />
          </template>
        </BaseCardView>
        <!--  ====== XXXXX =====  -->

        <!--  === Top Brands ===  -->
        <BaseCardView
          class="col-6 py-4 ps-4"
          title="Top Brands"
          :loading="userBrandsLoading || userAccountsLoading"
        >
          <template #content>
            <UserTopBrands :data="brandsList" />
          </template>
        </BaseCardView>
        <!--  ====== XXXXX =====  -->
      </div>

      <!--  === Categories Spending Chart ===  -->
      <BaseCardView
        class="col-12 py-4"
        title="Categories Spending"
        :loading="userCategoriesLoading || userAccountsLoading"
      >
        <template #content>
          <UserCategoriesSpending
            :data="userCategoriesList?.categories ?? {}"
          />
        </template>
      </BaseCardView>
      <!--  ====== XXXXX =====  -->

      <!--  === User transactions Table ===  -->
      <div class="pb-5">
        <BaseCardView
          class="col-12 py-4 pb-5 position-relative"
          title="Transactions"
        >
          <template #content>
            <UserTransactionsTable
              style="min-width: 700px; overflow: auto"
              :data="userTransactions ?? {}"
              :loading="userTransactionsLoading"
              @updateTable="updateTable"
              @onSortChange="onSortChange"
              @view-all-clicked="routeToUserTxs"
            />
          </template>
        </BaseCardView>
      </div>
      <!--  ====== XXXXX =====  -->
    </div>
  </div>
</template>
<script>
import CustomerRMName from "../components/CustomerRMName.vue";
import UserAccountsBar from "../components/UserAccountsBar.vue";
import { useStore } from "vuex";
import BaseViewInfoText from "../components/helpers/BaseViewInfoText";
import BaseCardView from "../components/helpers/BaseCardView";
import UserDetailsBasicInfo from "../components/UserDetailsBasicInfo.vue";
import UserCategoriesSpending from "../components/UserCategoriesSpending.vue";
import UserOverviewSummaryCards from "@/components/UserOverviewSummaryCards.vue";
import UserCashFlow from "@/components/UserCashFlow.vue";
// import UserBalanceChart from "../components/UserBalanceChart.vue";
import UserTopBrands from "../components/UserTopBrands.vue";
import UserTransactionsTable from "../components/UserTransactionsTable.vue";
import { computed, onUnmounted, ref, watch, onMounted } from "vue";
import UsersviewAPI from "../services/api/UsersviewAPI";
import { useRoute, useRouter } from "vue-router";
import { useDivideCalculator } from "@/composables/Helpers";

export default {
  components: {
    BaseViewInfoText,
    BaseCardView,
    UserDetailsBasicInfo,
    UserOverviewSummaryCards,
    UserTransactionsTable,
    // UserBalanceChart,
    UserCashFlow,
    UserTopBrands,
    UserCategoriesSpending,
    UserAccountsBar,
    CustomerRMName,
  },
  setup() {
    const store = useStore();
    const route = useRoute();
    const router = useRouter();
    const userAccountsLoading = ref(false);
    const sortObject = ref({
      field: "amount",
      order: -1,
    });
    const userAccounts = ref({});
    const userBalances = ref({});
    const userBalancesLoading = ref(false);
    const userCashflow = ref({});
    const userCashflowLoading = ref(false);
    const userBrandsList = ref({});
    const userBrandsLoading = ref(false);
    const userCategoriesList = ref({});
    const userCategoriesLoading = ref(false);
    const userTransactionsLoading = ref(false);
    const userTransactions = ref({});

    const filters = computed(() => {
      return store.getters.filters;
    });
    const requestFilters = computed(() => {
      const reqFilters = { ...filters.value };
      delete reqFilters.tenant_transaction_currency_codes;

      return reqFilters;
    });
    const userId = computed(() => route.params?.userId);

    const selectedUserAccount = computed(
      () => store.state.usersViewStore?.selectedAccount
    );
    // const metricFilters = computed(
    //   () => store.state.overViewStore.metricFilters
    // );
    const timeFilters = computed(() => store.state.overViewStore.timeFilters);

    const brandsList = computed(() => {
      let bList = [];
      for (let brand in userBrandsList.value?.brands) {
        bList.push({
          ...userBrandsList.value?.brands[brand],
        });
      }
      return bList;
    });

    const customersDetails = computed(
      () => store.state.usersViewStore.specificCustomersNBFDetails
    );
    const customersDetailsLoading = computed(
      () => store.state.usersViewStore.specificCustomersNBFDetailsLoading
    );

    //functions
    // === Parsers ===
    const parseBrandsList = (res) => {
      const brandIdList = res?.data?.brands;

      if (brandIdList.length !== 0) {
        let brandsObject = {};

        brandIdList.forEach((brandItem) => {
          const singleBrand = brandItem;
          brandsObject[brandItem?.id] = {
            ...singleBrand,

            total_spend: singleBrand?.tenant_customer_total_spend,
            rel_total_spend:
              singleBrand?.comparison_period_tenant_customer_total_spend,

            avg_spend_per_tx: singleBrand?.tenant_customer_avg_spend,
            rel_avg_spend_per_tx:
              singleBrand?.comparison_period_tenant_customer_avg_spend,

            tx_count: parseFloat(
              singleBrand?.tenant_customer_transactions_count ?? 0
            ),
            rel_tx_count:
              singleBrand?.comparison_period_tenant_customer_transactions_count ??
              0, //not exists cusstrntly
          };
        });

        return {
          brands: { ...brandsObject },
        };
      }

      return {};
    };
    const parseUserCategoriesList = (res) => {
      const catIdList = res?.data?.categories;
      const daysCount = timeFilters.value?.periodFilter;

      if (catIdList.length !== 0) {
        let catObject = {};

        catIdList.forEach((catItem) => {
          const singleCat = catItem;
          catObject[catItem?.id] = {
            ...singleCat,

            category_desc: singleCat?.name,
            category_id: singleCat?.id,

            total_spend: Math.abs(singleCat?.tenant_customer_total_spend),
            rel_total_spend: Math.abs(
              singleCat?.comparison_period_tenant_customer_total_spend
            ),

            avg_spend_per_tx: Math.abs(singleCat?.tenant_customer_avg_spend),

            rel_avg_spend_per_tx: Math.abs(
              singleCat?.comparison_period_tenant_customer_avg_spend
            ),

            avg_spend_per_day: Math.abs(
              useDivideCalculator(
                singleCat?.tenant_customer_total_spend,
                daysCount
              )
            ),

            rel_avg_spend_per_day: Math.abs(
              useDivideCalculator(
                singleCat?.comparison_period_tenant_customer_total_spend,
                daysCount
              )
            ),

            tx_count: parseFloat(
              singleCat?.tenant_customer_transactions_count ?? 0
            ),
            rel_tx_count:
              singleCat?.comparison_period_tenant_customer_transactions_count ??
              0,

            sub_categories: [...(singleCat?.sub_categories ?? [])],
          };
        });

        return {
          categories: { ...catObject },
        };
      }

      return {};
    };
    const parseCashflow = (res) => {
      const response = res?.data;

      return {
        cashflow: {
          inflow: response?.deposits ?? 0,
          outflow: response?.expenditure ?? 0,
          rel_inflow: response?.comparison_period_deposits ?? 0,
          rel_outflow: response?.comparison_period_expenditure ?? 0,
        },
      };
    };
    const parseTxData = (res) => {
      const reponse = res.data;

      return {
        transactions: reponse?.tenant_customers_transactions ?? [],
        total_records: reponse?.tenant_customers_transactions_count ?? 0,
      };
    };

    const updateTable = () => {
      getUserTransactions(false);
    };

    const getUserAccounts = () => {
      // if (Object.keys(userAccounts.value).length === 0)
      userAccountsLoading.value = true;

      UsersviewAPI.getUserAccounts(requestFilters.value, userId.value)
        .then((res) => {
          userAccounts.value = { ...res.data?.tenant_customer };
          userAccountsLoading.value = false;
        })
        .catch((error) => {
          if (error !== "canceled") {
            userAccounts.value = {};
            userAccountsLoading.value = false;

            //[1] check if user does not exists route to users table
            if (error.message === "User not found") {
              router.push({ name: "UsersView" });
              console.error("error: ", error);
            }
          }
        });
    };
    const getUserBalances = () => {
      userBalancesLoading.value = true;

      UsersviewAPI.getUserBalances(
        {
          ...requestFilters.value,
          tenant_customer_id: userId.value,
          tenant_customer_account_ids: [selectedUserAccount.value?.account_id],
        },
        timeFilters.value?.periodFilter ?? 30
      )
        .then((res) => {
          userBalances.value = { ...res.data };
          userBalancesLoading.value = false;
        })
        .catch((error) => {
          if (error !== "canceled") {
            userBalances.value = {};
            userBalancesLoading.value = false;
            console.error("error: ", error);
          }
        });
    };
    const getUserCashflow = () => {
      userCashflowLoading.value = true;

      UsersviewAPI.getUserCashflow(
        {
          ...requestFilters.value,
          tenant_customer_account_ids: [selectedUserAccount.value?.account_id],
        },
        userId.value
      )
        .then((res) => {
          userCashflow.value = parseCashflow(res);
          userCashflowLoading.value = false;
        })
        .catch((error) => {
          if (error !== "canceled") {
            userCashflow.value = {};
            userCashflowLoading.value = false;
            console.error("error: ", error);
          }
        });
    };
    const getUserBrands = () => {
      userBrandsLoading.value = true;

      UsersviewAPI.getUserBrands(
        {
          ...requestFilters.value,
          tenant_customer_account_ids: [selectedUserAccount.value?.account_id],
        },
        userId.value
      )
        .then((res) => {
          userBrandsList.value = parseBrandsList(res);
          userBrandsLoading.value = false;
        })
        .catch((error) => {
          if (error !== "canceled") {
            userBrandsList.value = {};
            userBrandsLoading.value = false;
            console.error("error: ", error);
          }
        });
    };
    const getUserCategories = () => {
      userCategoriesLoading.value = true;

      UsersviewAPI.getUserCategories(
        {
          ...requestFilters.value,
          tenant_customer_account_ids: [selectedUserAccount.value?.account_id],
        },
        userId.value
      )
        .then((res) => {
          userCategoriesList.value = parseUserCategoriesList(res);
          userCategoriesLoading.value = false;
        })
        .catch((error) => {
          if (error !== "canceled") {
            userCategoriesList.value = {};
            userCategoriesLoading.value = false;
            console.error("error: ", error);
          }
        });
    };

    const getUserTransactions = (reload = true) => {
      userTransactionsLoading.value = reload;
      if (reload) {
        userTransactions.value = {};
      }

      UsersviewAPI.getUserTransactions({
        ...requestFilters.value,
        tenant_customer_id: userId.value,
        tenant_customer_account_ids: [selectedUserAccount.value?.account_id],
        page: 1,
        per_page: 5,
        sort_by: sortObject.value?.field, // amount , transaction_date
        order: sortObject.value?.order == -1 ? "desc" : "asc",
      })
        .then((res) => {
          userTransactions.value = parseTxData(res);
          userTransactionsLoading.value = false;
        })
        .catch((error) => {
          if (error !== "canceled") {
            userTransactions.value = {};
            userTransactionsLoading.value = false;
            console.error("error: ", error);
          }
        });
    };

    const onSortChange = (sortObj) => {
      //[1] Set new sort option
      sortObject.value = { ...sortObj };

      //[2] Fetch new tx's
      getUserTransactions(true);
    };

    const fetchData = () => {
      if (selectedUserAccount.value.account_id) {
        // getUserBalances();
        getUserCashflow();
        getUserBrands();
        getUserCategories();
        getUserTransactions();
      }
    };
    const routeToUserTxs = () => {
      //Route to user transactions screen with userID
      if (route.params?.userId)
        router.push({
          name: "MainUserTransactions",
          params: {
            userId: route.params?.userId,
            cloudId: route.params?.cloudId,
            // filters: JSON.stringify({
            //   account_id: [], //reset account id
            //   user_currency: [], //reset user_currency
            //   transaction_types: [], //reset transaction_types
            //   transfer_purposes: [], //reset transfer_purposes
            // }),
          },
        });
    };

    //hooks

    onMounted(() => {
      getUserAccounts();
      fetchData();
    });
    onUnmounted(() => {
      store.dispatch("CANCEL_PENDING_REQUESTS");
      store.commit("updateSelectedAccount", {});
    });
    //watchers
    watch(filters, (newVal, oldVal) => {
      if (
        newVal?.tenant_transaction_from_date &&
        oldVal?.tenant_transaction_from_date
      ) {
        //[1] Abort all previous pending requests
        store.dispatch("CANCEL_PENDING_REQUESTS");

        //[2] Fetch new requests
        getUserAccounts();
        fetchData();
      }
    });
    watch(selectedUserAccount, (newVal, oldVal) => {
      if (newVal?.account_id !== oldVal?.account_id) fetchData();
    });

    return {
      filters,
      userId,
      selectedUserAccount,
      userBalances,
      userBalancesLoading,
      userCashflow,
      userCashflowLoading,
      userBrandsList,
      userBrandsLoading,
      brandsList,
      userCategoriesList,
      userCategoriesLoading,
      userTransactionsLoading,
      userTransactions,
      userAccountsLoading,
      userAccounts,
      updateTable,
      routeToUserTxs,
      customersDetails,
      customersDetailsLoading,
      onSortChange,
    };
  },
};
</script>
<style></style>
