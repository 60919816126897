<template>
  <div class="d-flex flex-row flex-wrap gap-3">
    <ExportPDFButton
      v-if="['Overview', 'Categories', 'Brands'].includes($route.name)"
      :loading="exportLoading"
      :metric="metricFilters?.key"
      :filters="filters"
      :time-period="timeFilters?.dropDownLabel ?? 'Last 30 days'"
    />

    <!-- BaseDashboardMetricFilter -->
    <BaseDashboardMetricFilter />
    <!-- BaseDashboardMetricFilter -->

    <TimePeriodDropdownFilter
      v-show="
        !'hiddenFilters' in $route.meta ||
        !$route.meta?.hiddenFilters?.includes('from_date')
      "
      @period-change="handlePeriodChange"
    />
  </div>
</template>
<script>
import BaseDashboardMetricFilter from "./BaseDashboardMetricFilter.vue";
import ExportPDFButton from "../ExportPDFButton.vue";
import { computed, ref, watch } from "vue";
import TimePeriodDropdownFilter from "../helpers/TimePeriodDropdownFilter.vue";
import { useStore } from "vuex";
import { useRoute } from "vue-router";

export default {
  components: {
    TimePeriodDropdownFilter,
    ExportPDFButton,
    BaseDashboardMetricFilter,
  },
  setup() {
    const store = useStore();
    const route = useRoute();

    //computed
    const appliedFilters = computed(
      () => store.state.overViewStore.appliedFilters
    );

    const metricFilters = computed(
      () => store.state.overViewStore.metricFilters
    );
    const filters = computed(() => store.getters.filters);
    const timeFilters = computed(() => store.state.overViewStore.timeFilters);

    const exportLoading = computed(() => {
      let currentRoute = route.name;

      switch (currentRoute) {
        case "Dashboard":
        case "Overview":
          return store.state.loadingDahboardData;

        case "Categories":
          return store.state.loadingCategoriesData;

        case "Brands":
          return store.state.loadingBrandsData;

        default:
          return true;
      }
    });

    function handlePeriodChange(selectedTime) {
      setTimeFilter(selectedTime);
    }

    function setTimeFilter(timeFilter) {
      store.commit("updateTimeFilters", {
        ...(timeFilter ?? timeFilters.value),
      });

      store.commit("updateAppliedFilters", {
        ...appliedFilters.value,
        from_date:
          timeFilter?.rangePeriodFilter?.from ??
          appliedFilters.value?.from_date,
        to_date:
          timeFilter?.rangePeriodFilter?.to ?? appliedFilters.value?.to_date,
      });
    }

    return {
      handlePeriodChange,
      exportLoading,
      filters,
      timeFilters,
      metricFilters,
    };
  },
};
</script>
<style></style>
