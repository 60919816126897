<template>
  <img
    v-if="catIcon"
    :src="catIcon"
    alt="Route Icon"
    class="route-title-icon"
  />
</template>

<script>
import { computed } from "vue";
import { categories } from "@/data/options";
import { useRoute } from "vue-router";

export default {
  props: {
    icon: {
      type: String,
      default: "",
    },
  },
  setup(props) {
    const route = useRoute();

    const catIcon = computed(() => {
      let icon = props.icon;
      //[1] If specific cat route
      if (route?.name === "UserCategoryDetails") {
        icon =
          categories[route.params?.catId]?.icon ??
          require("@/assets/images/svg/components/no_brand_logo.svg");
      }

      return icon;
    });

    return { catIcon };
  },
};
</script>

<style lang="scss" scoped>
.route-title-icon {
  width: 43px;
  height: 43px;
  padding: 5px;
  border-radius: 50%;
  border: 1px solid #edf5f4;
}
</style>
