<template>
  <BaseBreadCrumpList :bread-crumb-list="breadCrumbList" />
</template>

<script>
import BaseBreadCrumpList from "./BaseBreadCrumpList.vue";
import { computed, watch } from "vue";
import { useRoute } from "vue-router";
import { categories } from "@/data/options";
import { useStore } from "vuex";

export default {
  components: { BaseBreadCrumpList },
  setup() {
    const route = useRoute();
    const store = useStore();

    const customersDetails = computed(
      () => store.state.usersViewStore.specificCustomersNBFDetails
    );
    const brandDetails = computed(
      () => store.state.allBrandsStore.specificBrandDetails
    );
    const specificBrandLoading = computed(
      () => store.state.allBrandsStore.specificBrandLoading
    );

    const breadCrumbList = computed(() => {
      let list = route.meta?.breadCrumbList ?? [];

      list = list.map((item) => {
        return { ...item, label: getBreadCrumpList(item) };
      });

      return list;
    });

    const getCustomerDetails = () => {
      //[1] Reset
      store.commit("updateSpecificCustomersNBFDetails", {});
      store.commit("updateSpecificCustomersNBFDetailsLoading", false);

      //[2] Fetch
      const cloudId = route.params?.cloudId;
      store.dispatch("getCustomerDetails", cloudId);
    };

    function getUserName() {
      let name = "Loading...";

      if (customersDetails.value?.CustomerName) {
        name = customersDetails.value?.CustomerName;
      }

      return name;
    }

    function getBrandName() {
      let name = "Loading...";

      if (brandDetails.value?.name) {
        name = brandDetails.value?.name;
      } else if (!specificBrandLoading.value) {
        name = "--"; //if loading is done and error occured
      }

      return name;
    }

    function getBreadCrumpList(item) {
      const routeName = item?.name ?? "--";
      let routeLabel = item?.label ?? "--";

      switch (true) {
        case routeName === "SpecificBrand": //Brand Name
          routeLabel = getBrandName();
          break;

        case routeName === "UserDetails": //User Name
          routeLabel = getUserName();
          break;

        case routeName === "UserCategoryDetails": // Category Name
          routeLabel =
            categories[route.params?.catId ?? 1]?.label ?? item?.label;
          break;

        case "param" in item: // Param Name Directly
          routeLabel = route.params?.[item?.param];
          break;
      }

      return routeLabel;
    }

    watch(
      () => route.name,
      (currentRoute) => {
        if (
          [
            "UserDetails",
            "UserCategoryDetails",
            "MainUserTransactions",
            "UserDetailsFromTransactions",
          ].includes(currentRoute)
        ) {
          //[1] fetch customer name
          getCustomerDetails();
        }
      },
      {
        immediate: true,
      }
    );

    return {
      breadCrumbList,
      customersDetails,
    };
  },
};
</script>

<style lang="scss" scoped></style>
