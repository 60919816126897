<template>
  <div
    v-if="totalRecords !== 0 && !isSummarizedTable"
    class="tx-table-header pb-3"
  >
    <!-- Users Transactions -->
    <h5 v-if="$route.name === 'MainUserTransactions' && !isUserFiltersApplied">
      Showing all <span>{{ compactNumber(totalRecords) }}</span>
      transactions for the user
    </h5>

    <!-- Transactions -->
    <h5 v-else>
      Showing <span>{{ compactNumber(totalRecords) }}</span> Transactions
    </h5>
  </div>

  <div
    class="overflow-hidden pb-4"
    :class="{
      'base-summarized-table': isSummarizedTable,
      'base-table-outer': showColumn('all'),
    }"
  >
    <DataTable
      v-model:first="first"
      :paginator="showPaginator && !loading && sortedData?.length !== 0"
      :value="rows ? [...sortedData].slice(0, rows) : [...sortedData]"
      breakpoint="20px"
      :rows="rows"
      :lazy="true"
      :total-records="totalRecords"
      table-class="base-table"
      @sort="onSort($event)"
      @page="onPage($event)"
    >
      <template #empty>
        <div class="h-100 w-100">
          <LoadingBox v-if="loading" />
          <BaseEmptyState v-else /></div
      ></template>
      <Column
        v-if="showColumn('raw_description')"
        field="raw_description"
        header="Raw Transaction"
        header-class="base-table-header"
        body-class="base-table-cell"
      >
        <!-- <template #header>
          <BaseTableCustomSortHeader
            header="Raw Transaction"
            :order="sortObject?.raw_description?.order ?? null"
            @click="triggerSort($event)"
          />
        </template> -->
        <template #body="{ data, index }">
          <TableCellTruncatedText
            class="text-underline cp"
            :text="data?.raw_description"
            :toggle="
              showFull[data?.raw_description + '-raw_decription-' + index]
            "
            @click="routeToCustomer(data)"
            @mouseenter="
              showFull[
                data?.raw_description + '-raw_decription-' + index
              ] = true
            "
            @mouseleave="
              showFull[
                data?.raw_description + '-raw_decription-' + index
              ] = false
            "
          />
        </template>
      </Column>
      <Column
        v-if="showColumn('amount')"
        field="amount"
        header-class="base-table-header"
        sortable
        body-class="base-table-cell"
      >
        <template #header>
          <BaseTableCustomSortHeader
            header="Amount"
            :order="sortObject?.amount?.order ?? null"
            @click="triggerSort($event)"
          />
        </template>

        <template #body="{ data, index }">
          <TableCellTruncatedText
            :disable="!data?.external"
            :show-copy="false"
            :custom-body="true"
            :class="{ cp: data?.external }"
            text="External Transaction"
            :toggle="showFull[data?.amount + '-amount-' + index]"
            @mouseenter="showFull[data?.amount + '-amount-' + index] = true"
            @mouseleave="showFull[data?.amount + '-amount-' + index] = false"
          >
            <template #body>
              <div
                class="d-flex align-items-center gap-2"
                :class="{ 'external-tx': data?.external }"
              >
                <div>
                  {{ compactNumber(data?.amount) }}
                  <small>{{ originalAmountsCurrency }}</small>
                </div>
                <img
                  v-if="data?.external"
                  src="@/assets/images/svg/components/external_tx.svg"
                  alt="External Transaction"
                  width="18"
                />
              </div>
            </template>
          </TableCellTruncatedText>
        </template>
      </Column>
      <Column
        v-if="showColumn('txCurrency')"
        field="txCurrency"
        header="Txn Currency"
        header-class="base-table-header"
        body-class="base-table-cell"
        style="width: 7%"
      >
        <template #body="{ data }">{{ data?.currency_code }}</template>
      </Column>

      <Column
        v-if="showColumn('transferFrom')"
        field="transferFrom"
        header="Transfer From"
        header-class="base-table-header"
        body-class="base-table-cell"
      >
        <template #body="{ data, index }">
          <TableCellTruncatedText
            v-if="data?.transfer_from?.name"
            :custom-body="true"
            :text="data?.transfer_from?.name"
            :toggle="
              showFull[data?.transfer_from?.name + '-transfer_from-' + index]
            "
            @mouseenter="
              showFull[
                data?.transfer_from?.name + '-transfer_from-' + index
              ] = true
            "
            @mouseleave="
              showFull[
                data?.transfer_from?.name + '-transfer_from-' + index
              ] = false
            "
          >
            <template #body>
              <div class="d-flex align-items-center gap-2">
                <LogoIcon
                  :src="getIcon(data?.transfer_from?.logo)"
                  :is-brand-icon="true"
                />
                <div class="text-truncate">
                  {{ data?.transfer_from?.name }}
                </div>
              </div>
            </template>
          </TableCellTruncatedText>
          <div v-else>--</div>
        </template>
      </Column>

      <Column
        v-if="showColumn('transferTo')"
        field="transferTo"
        header="Transfer To"
        header-class="base-table-header"
        body-class="base-table-cell"
      >
        <template #body="{ data, index }">
          <TableCellTruncatedText
            v-if="data?.transfer_to?.name"
            :custom-body="true"
            :text="data?.transfer_to?.name"
            :toggle="
              showFull[data?.transfer_to?.name + '-transfer_to-' + index]
            "
            @mouseenter="
              showFull[data?.transfer_to?.name + '-transfer_to-' + index] = true
            "
            @mouseleave="
              showFull[
                data?.transfer_to?.name + '-transfer_to-' + index
              ] = false
            "
          >
            <template #body>
              <div class="d-flex align-items-center gap-2">
                <LogoIcon
                  :src="getIcon(data?.transfer_to?.logo)"
                  :is-brand-icon="true"
                />
                <div class="text-truncate">
                  {{ data?.transfer_to?.name }}
                </div>
              </div>
            </template>
          </TableCellTruncatedText>
          <div v-else>--</div>
        </template>
      </Column>

      <Column
        v-if="showColumn('tenant_transaction_date')"
        field="tenant_transaction_date"
        header-class="base-table-header"
        body-class="base-table-cell"
        sortable
      >
        <template #header>
          <BaseTableCustomSortHeader
            header="Date & Time"
            :order="sortObject?.tenant_transaction_date?.order ?? null"
            @click="triggerSort($event)"
          />
        </template>
        <template #body="{ data }">
          {{ formatDate(data?.transaction_date) }}
        </template>
      </Column>

      <Column
        v-if="showColumn('brand_name')"
        field="brand_name"
        header="Brand"
        header-class="base-table-header"
        body-class="base-table-cell"
      >
        <template #body="{ data, index }">
          <TableCellTruncatedText
            v-if="data?.brand_name"
            :text="data?.brand_name"
            :toggle="showFull[data?.brand_name + '-brand_name-' + index]"
            @mouseenter="
              showFull[data?.brand_name + '-brand_name-' + index] = true
            "
            @mouseleave="
              showFull[data?.brand_name + '-brand_name-' + index] = false
            "
          />
          <span v-else>--</span>
        </template>
      </Column>

      <Column
        v-if="showColumn('category_id')"
        field="category_id"
        header="Category"
        header-class="base-table-header"
        body-class="base-table-cell"
      >
        <template #body="{ data, index }">
          <TableCellTruncatedText
            v-if="data?.category_id"
            :text="getCategoryLabel(data?.category_id)"
            :toggle="showFull[data?.category_id + '-category_id-' + index]"
            @mouseenter="
              showFull[data?.category_id + '-category_id-' + index] = true
            "
            @mouseleave="
              showFull[data?.category_id + '-category_id-' + index] = false
            "
          />
          <span v-else>--</span>
        </template>
      </Column>
      <Column
        v-if="showColumn('purposeOfTransfer')"
        field="purposeOfTransfer"
        header="Purpose of Transfer"
        header-class="base-table-header"
        body-class="base-table-cell"
      >
        <template #body="{ data, index }">
          <div>
            <TableCellTruncatedText
              v-if="data?.transfer_purpose?.name"
              :text="data?.transfer_purpose?.name"
              :toggle="
                showFull[
                  data?.transfer_purpose?.name + '-transfer_purpose-' + index
                ]
              "
              @mouseenter="
                showFull[
                  data?.transfer_purpose?.name + '-transfer_purpose-' + index
                ] = true
              "
              @mouseleave="
                showFull[
                  data?.transfer_purpose?.name + '-transfer_purpose-' + index
                ] = false
              "
            />
            <span v-else>--</span>
          </div>
        </template>
      </Column>

      <Column
        v-if="showColumn('logo')"
        field="logo"
        header="Logo"
        header-class="base-table-header"
        body-class="base-table-cell"
        style="width: 5%"
      >
        <template #body="{ data }">
          <LogoIcon
            :src="getIcon(data?.brand_logo_url)"
            :is-brand-icon="true"
          />
        </template>
      </Column>
      <Column
        v-if="showColumn('report')"
        field="report"
        header=""
        header-class="base-table-header"
        body-class="base-table-cell"
        style="width: 8%"
      >
        <template #body="{ data }">
          <TxStatusLabel
            :data="data"
            @on-report="onReport"
            @on-view-status="viewTxFeedback"
        /></template>
      </Column>
    </DataTable>
  </div>

  <ReportTransactionsForm
    id="report-transactions-modal"
    :show-modal="showReportForm"
    :data="modalData ?? {}"
    @closeModal="closeModal"
    @updateTable="$emit('updateTable')"
  />
  <TransactionFeedbackPopup
    id="reported-transactions-feecback-modal"
    :show-modal="showTxFeedbackPopup"
    :data="feedbackModalData ?? {}"
    @closeModal="closeTxFeedbackModal"
    @updateTable="$emit('updateTable')"
    @reportAgain="(tx) => onReport(tx)"
  />
</template>
<script>
import TableCellTruncatedText from "../assets/images/svg/components/TableCellTruncatedText.vue";
import TransactionFeedbackPopup from "@/components/TransactionFeedbackPopup.vue";
import ReportTransactionsForm from "./ReportTransactionsForm.vue";
import TxStatusLabel from "./TxStatusLabel.vue";
import DataTable from "primevue/datatable";
import Column from "primevue/column";
import LoadingBox from "./helpers/LoadingBox.vue";
import BaseEmptyState from "./helpers/BaseEmptyState.vue";
import BaseTableCustomSortHeader from "./helpers/BaseTableCustomSortHeader.vue";
import LogoIcon from "./LogoIcon";
import { ref, computed, inject, watch, reactive } from "vue";
import moment from "moment";
import { useRouter } from "vue-router";
import { useStore } from "vuex";

export default {
  components: {
    DataTable,
    Column,
    LoadingBox,
    BaseEmptyState,
    BaseTableCustomSortHeader,
    LogoIcon,
    TxStatusLabel,
    ReportTransactionsForm,
    TransactionFeedbackPopup,
    TableCellTruncatedText,
  },
  props: {
    loading: {
      type: Boolean,
      default: false,
    },
    showPaginator: {
      type: Boolean,
      default: true,
    },
    isSummarizedTable: {
      type: Boolean,
      default: false,
    },
    tableRows: {
      type: Array,
      default: () => [],
    },
    rows: {
      type: Number,
      default: 0,
    },
    firstRow: {
      type: Number,
      default: 0,
    },
    totalRecords: {
      type: Number,
      default: 0,
    },
    columns: {
      type: Array,
      default: () => ["all"],
    },
  },
  emits: ["page-change", "update:firstRow", "updateTable", "sort-change"],
  setup(props, { emit }) {
    const compactNumber = inject("compactNumber");
    const getCategoryLabel = inject("getCategoryLabel");

    // ==== States ====
    const showReportForm = ref(false);
    const showTxFeedbackPopup = ref(false);
    const feedbackModalData = ref({});
    const showFull = reactive({});
    const router = useRouter();

    // Feedback
    const closeTxFeedbackModal = () => {
      showTxFeedbackPopup.value = false;
    };
    const viewTxFeedback = (tx) => {
      // as a param tx
      feedbackModalData.value = { ...(tx ?? {}) };
      if (showTxFeedbackPopup.value) {
        showTxFeedbackPopup.value = false;
        return;
      }
      showTxFeedbackPopup.value = true;
    };

    const modalData = ref({});
    const currentSortState = ref({
      field: "amount",
      order: 1,
      type: "Number",
    });
    const sortObject = ref({
      // raw_description: { order: null, type: "String" },
      // brand_name: { order: null, type: "String" },
      amount: { order: null, type: "Number" },
      tenant_transaction_date: { order: null, type: "Date" },
    });

    // === Computed ===
    const store = useStore();

    //ref states
    const appliedFilters = computed(
      () => store.state.overViewStore.appliedFilters
    );
    const originalAmountsCurrency = computed(
      () => store.state.originalAmountsCurrency
    );

    // used to check if the user applioed any filters, to control the label based on the filters
    const isUserFiltersApplied = computed(() => {
      const userFilters = [
        ...(appliedFilters.value.account_id ?? []),
        ...(appliedFilters.value.user_currency ?? []),
        ...(appliedFilters.value.transaction_types ?? []),
      ];

      return userFilters.length !== 0;
    });

    const sortedData = computed(() => {
      const dataRows = props.tableRows; //assign by array original refrence ("sort will also apply on original array (in-place) algorithm")
      // console.log(dataRows);
      return dataRows;

      // //[1] exit if no mode is provided
      // if (dataRows.length === 0) return [];
      // // console.log("Apply sort for: ", currentSortState.value);

      // // eslint-disable-next-line vue/no-side-effects-in-computed-properties
      // //[2] reverse sort "-1" data
      // if (currentSortState.value?.order === -1) return dataRows.reverse();

      // //[3] check mode and sort the data by it
      // return dataRows.sort((a, b) => {
      //   let res = 0;

      //   switch (currentSortState.value?.field) {
      //     case "raw_description":
      //       res = ("" + a.raw_description).localeCompare(b.raw_description);
      //       break;
      //     case "brand_name":
      //       res = ("" + a.brand_name).localeCompare(b.brand_name);
      //       break;
      //     case "amount":
      //       res = Math.abs(b?.amount) - Math.abs(a?.amount);
      //       break;
      //     case "transaction_date":
      //       res = new Date(b?.transaction_date) - new Date(a?.transaction_date);
      //       break;
      //     default:
      //       res = 0;
      //       break;
      //   }

      //   return res;
      // });
    });

    // === Methods ===
    const showColumn = (colName) => {
      const columns = props.columns ?? [];

      if (columns.includes("all")) return true;

      return columns.includes(colName);
    };

    const triggerSort = (event) => {
      //Function to trigger parent header click event, cuz library by default is not triggering the parent event when child div is clicked
      const header = event.target.closest(".p-sortable-column");
      header.click();
    };
    const onSort = (sortEvent) => {
      //[0] emit sort
      emit("sort-change", {
        field: sortEvent?.sortField,
        order: sortEvent?.sortOrder,
      });

      //[1] Reset all previous sorting states
      for (let sortOption in sortObject.value) {
        sortObject.value[sortOption]["order"] = null;
      }

      //[2] set new selected sort state
      sortObject.value[sortEvent?.sortField]["order"] = sortEvent?.sortOrder;

      //[3] sort action
      currentSortState.value = {
        field: sortEvent?.sortField,
        order: sortEvent?.sortOrder,
        type: sortObject.value[sortEvent?.sortField]["type"],
      };
    };
    const onPage = (page) => {
      emit("page-change", page);
    };
    const formatDate = (date) => {
      return moment(date).format("D MMM, YYYY");
    };
    const onViewStatus = (tx) => {
      console.log("View Tx Status: ", tx);
    };
    const closeModal = () => {
      showReportForm.value = false;
    };
    const onReport = (tx) => {
      modalData.value = { ...tx };
      if (showReportForm.value) {
        showReportForm.value = false;
        return;
      }
      showReportForm.value = true;
    };
    const getIcon = (url) => {
      return `${process.env.VUE_APP_IMG_ORIGINAL_BASE_URL}${url}`;
    };
    const routeToCustomer = (data) => {
      if (data?.tenant_customer_id && data?.tenant_customer_cloud_id)
        router.push({
          name: "UserDetailsFromTransactions",
          params: {
            userId: data?.tenant_customer_id,
            cloudId: data?.tenant_customer_cloud_id,
          },
          // query: {
          //   user_name: "Cloud Id: " + data?.tenant_customer_cloud_id,
          // },
        });
    };

    //Custom v-model
    const first = ref(0);

    watch(first, (newVal) => {
      emit("update:firstRow", newVal);
    });
    watch(
      () => props.firstRow,
      (newVal) => {
        first.value = newVal;
      }
    );

    return {
      showReportForm,
      closeModal,
      onReport,
      first,
      compactNumber,
      sortObject,
      sortedData,
      currentSortState,
      onSort,
      onPage,
      triggerSort,
      formatDate,
      modalData,
      onViewStatus,
      showTxFeedbackPopup,
      feedbackModalData,
      closeTxFeedbackModal,
      viewTxFeedback,
      getIcon,
      routeToCustomer,
      showFull,
      getCategoryLabel,
      isUserFiltersApplied,
      originalAmountsCurrency,
      showColumn,
    };
  },
};
</script>
<style lang="scss">
.base-summarized-table {
  box-shadow: none !important;

  .p-datatable .p-datatable-tbody tr:last-child .base-table-cell {
    border: none !important;
  }
}
.tx-table-header {
  h5 {
    font-size: 1.1rem;
    color: #6f6f6f;
    font-weight: 400;
    span {
      color: #000000;
      font-weight: 600;
    }
  }
}
.base-table-outer .p-paginator {
  padding: 1.45rem 0 !important;
}
.external-tx {
  color: var(--sea);
}
</style>
