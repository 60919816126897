<template>
  <div
    class="switch-input d-flex justify-content-between align-items-center gap-3"
  >
    <label v-if="label" :for="id">{{ label }}</label>
    <InputSwitch v-model="checked" :input-id="id" />
  </div>
</template>

<script>
import InputSwitch from "primevue/inputswitch";
import { ref, watch } from "vue";

export default {
  components: { InputSwitch },
  props: {
    modelValue: {
      type: Boolean,
      default: false,
    },
    id: {
      type: String,
      required: true,
    },
    label: {
      type: String,
      default: "",
    },
  },
  emits: ["update:modelValue"],
  setup(props, { emit }) {
    const checked = ref(false);

    watch(checked, (currentState) => {
      emit("update:modelValue", currentState);
    });

    return { checked };
  },
};
</script>

<style lang="scss" scoped>
.switch-input {
  color: var(--green);

  label {
    cursor: pointer;
    font-size: 0.9rem;
    font-weight: 500;
    padding: 0;
    margin: 0;
  }
}
</style>

<style lang="scss">
.switch-input {
  .p-inputswitch.p-inputswitch-checked .p-inputswitch-slider {
    background: var(--sea);
  }

  .p-inputswitch.p-inputswitch-checked:not(.p-disabled):hover
    .p-inputswitch-slider {
    background: var(--sea);
  }

  .p-inputswitch.p-focus .p-inputswitch-slider {
    box-shadow: none;
  }
}
</style>
