<template>
  <div
    class="
      d-flex
      flex-grow-1 flex-row flex-wrap
      justify-content-start
      widget-area
      overflow-auto
      h-100
      position-relative
    "
  >
    <div class="p-2 col-xl-4 col-lg-6 col-md-12 col-sm-12">
      <div class="card p-3 h-100 position-relative">
        <BankTransfersCard
          title="Outbound - Entities"
          :metric="metric"
          :data="outBoundBanks"
          @toggleModal="toggleModal"
        />

        <div v-if="loadingOutboundBanks" class="loader px-auto py-auto">
          <span class="position-absolute top-50 start-50 translate-middle">
            <div class="spinner-border" role="status">
              <span class="sr-only">Loading...</span>
            </div>
          </span>
        </div>
      </div>
    </div>

    <div class="p-2 col-xl-8 col-lg-12 col-md-12 col-sm-12">
      <div class="card p-3 h-100 position-relative">
        <CountryTransfersChart
          title="Outbound - Countries"
          :period="periodFilter"
          :data="outBoundcountryDist"
          :metric="metric"
          :summary="userData"
          :time-period="timePeriod"
          @toggleCountryModal="toggleCountryModal"
        />

        <div v-if="loadingOutboundCountries" class="loader px-auto py-auto">
          <span class="position-absolute top-50 start-50 translate-middle">
            <div class="spinner-border" role="status">
              <span class="sr-only">Loading...</span>
            </div>
          </span>
        </div>
      </div>
    </div>

    <div class="p-2 col-xl-4 col-lg-6 col-md-12 col-sm-12">
      <div class="card p-3 h-100 position-relative">
        <BankTransfersCard
          title="Inbound - Entities"
          :metric="metric"
          :data="inBoundBanks"
          @toggleModal="toggleModal"
        />

        <div v-if="loadingInboundBanks" class="loader px-auto py-auto">
          <span class="position-absolute top-50 start-50 translate-middle">
            <div class="spinner-border" role="status">
              <span class="sr-only">Loading...</span>
            </div>
          </span>
        </div>
      </div>
    </div>

    <div class="p-2 col-xl-8 col-lg-12 col-md-12 col-sm-12">
      <div class="card p-3 h-100 position-relative">
        <CountryTransfersChart
          title="Inbound - Countries"
          :period="periodFilter"
          :data="InBoundcountryDist"
          :metric="metric"
          :summary="userData"
          :time-period="timePeriod"
          @toggleCountryModal="toggleCountryModal"
        />

        <div v-if="loadingInboundCountries" class="loader px-auto py-auto">
          <span class="position-absolute top-50 start-50 translate-middle">
            <div class="spinner-border" role="status">
              <span class="sr-only">Loading...</span>
            </div>
          </span>
        </div>
      </div>
    </div>

    <div class="p-2 col-xl-6 col-lg-6 col-md-6 col-sm-12">
      <div class="card p-3 h-100 position-relative">
        <TransfersPerCurrencyCard
          title="Inbound - Currencies"
          :metric="metric"
          :data="inBoundCurrencies"
          @toggleModal="toggleCurrenciesModal"
        />

        <div v-if="loadingInboundCurrencies" class="loader px-auto py-auto">
          <span class="position-absolute top-50 start-50 translate-middle">
            <div class="spinner-border" role="status">
              <span class="sr-only">Loading...</span>
            </div>
          </span>
        </div>
      </div>
    </div>

    <div class="p-2 col-xl-6 col-lg-6 col-md-6 col-sm-12">
      <div class="card p-3 h-100 position-relative">
        <TransfersPerCurrencyCard
          title="Outbound - Currencies"
          :metric="metric"
          :data="outBoundCurrencies"
          @toggleModal="toggleCurrenciesModal"
        />

        <div v-if="loadingOutboundCurrencies" class="loader px-auto py-auto">
          <span class="position-absolute top-50 start-50 translate-middle">
            <div class="spinner-border" role="status">
              <span class="sr-only">Loading...</span>
            </div>
          </span>
        </div>
      </div>
    </div>

    <!--  === Transfers transactions Table ===  -->
    <div class="p-2 pt-3 pb-5 mb-4 col-12">
      <BaseCardView class="col-12 position-relative" title="Transactions">
        <template #content>
          <UserTransactionsTable
            style="min-width: 700px; overflow: auto"
            :data="transactionsData ?? {}"
            :loading="transactionsLoading"
            @updateTable="updateTable"
            @onSortChange="onSortChange"
            @view-all-clicked="viewAllTransfersTxs"
          />
        </template>
      </BaseCardView>
    </div>
    <!--  ====== XXXXX =====  -->

    <div
      v-if="banksCardIsFullscreen"
      class="bg-transcluscent d-flex fixed-top h-100"
    >
      <div
        class="
          mx-auto
          top-50
          start-50
          w-40
          h-75
          rounded
          bg-white
          row
          justify-content-center
          align-self-center
          p-2
        "
      >
        <BankTransfersCard
          :fullscreen="true"
          :title="fullBankCardTitle"
          :metric="metric"
          :data="
            fullBankCardTitle == 'Inbound - Entities'
              ? inBoundBanks
              : outBoundBanks
          "
          @toggleModal="toggleModal"
        />
      </div>
    </div>

    <div
      v-if="countryChartIsFullscreen"
      class="bg-transcluscent d-flex fixed-top h-100"
    >
      <div
        class="
          mx-auto
          top-50
          start-50
          w-75
          h-75
          rounded
          bg-white
          row
          justify-content-center
          align-self-center
          p-2
        "
      >
        <CountryTransfersChart
          :title="fullChartTitle"
          :period="periodFilter"
          :data="
            fullChartTitle == 'Inbound - Countries'
              ? InBoundcountryDist
              : outBoundcountryDist
          "
          :metric="metric"
          :summary="userData"
          :time-period="timePeriod"
          :fullscreen="true"
          @toggleCountryModal="toggleCountryModal"
        />
      </div>
    </div>

    <div
      v-if="currenciesCardIsFullscreen"
      class="bg-transcluscent d-flex fixed-top h-100"
    >
      <div
        class="
          mx-auto
          top-50
          start-50
          w-40
          h-75
          rounded
          bg-white
          row
          justify-content-center
          align-self-center
          p-2
        "
      >
        <TransfersPerCurrencyCard
          :fullscreen="true"
          :title="fullCurrenciesCardTitle"
          :metric="metric"
          :data="
            fullCurrenciesCardTitle == 'Inbound - Currencies'
              ? inBoundCurrencies
              : outBoundCurrencies
          "
          @toggleModal="toggleCurrenciesModal"
        />
      </div>
    </div>
  </div>
</template>

<script>
import UserTransactionsTable from "../components/UserTransactionsTable.vue";
import BaseCardView from "../components/helpers/BaseCardView.vue";
import CountryTransfersChart from "@/components/CountryTransfersChart.vue";
import BankTransfersCard from "@/components/BankTransfersCard.vue";
import TransfersPerCurrencyCard from "@/components/TransfersPerCurrencyCard.vue";
import TransfersAPI from "@/services/api/TransfersAPI";
import OverviewAPI from "@/services/api/OverviewAPI";

export default {
  components: {
    CountryTransfersChart,
    BankTransfersCard,
    TransfersPerCurrencyCard,
    BaseCardView,
    UserTransactionsTable,
  },
  inject: ["getCountry"],
  props: {
    fetchKey: {
      default: 1,
    },
    timePeriod: {
      type: String,
      default: "Last 30 days",
    },
    metric: {
      default: "average",
    },
    filters: {
      default: {},
    },
    periodFilter: {
      default: 30,
    },
    selectedProfile: {
      default: {},
    },
  },
  data() {
    return {
      userData: {},
      userPayload: {},
      //initialFetchKey: null,
      InBoundcountryDist: [],
      outBoundcountryDist: [],
      inBoundBanks: [],
      outBoundBanks: [],
      inBoundCurrencies: [],
      outBoundCurrencies: [],
      transactionsData: {},
      sortObject: {
        field: "amount",
        order: -1,
      },

      fullChartTitle: "",
      fullBankCardTitle: "",
      fullCurrenciesCardTitle: "",

      // ----------------------
      loadingInboundCountries: false,
      loadingOutboundCountries: false,
      loadingInboundBanks: false,
      loadingOutboundBanks: false,
      loadingInboundCurrencies: false,
      loadingOutboundCurrencies: false,
      transactionsLoading: false,

      countryChartIsFullscreen: false,
      currenciesCardIsFullscreen: false,
      banksCardIsFullscreen: false,
    };
  },
  watch: {
    metric() {
      this.fetchData();
    },
    fetchKey() {
      this.fetchData();
      this.getTransfersTransactions();
    },
  },
  mounted() {
    this.$nextTick(this.onScrolled);

    //fetch onMount
    if (
      this.filters?.tenant_transaction_from_date ||
      this.filters?.tenant_transaction_to_date
    ) {
      this.fetchData();
      this.getTransfersTransactions();
    }
  },
  unmounted() {
    this.$store.dispatch("CANCEL_PENDING_REQUESTS");
  },
  methods: {
    onSortChange(sortObj) {
      //[1] Set new sort option
      this.sortObject = { ...sortObj };

      //[2] Fetch new tx's
      this.getTransfersTransactions();
    },
    updateTable() {
      this.getTransfersTransactions(false);
    },
    viewAllTransfersTxs() {
      //[1] set transfer category
      this.$store.commit("updateSelectedCategoriesFilter", [
        {
          category_id: 13,
          name: "Transfer",
          id: 13,
        },
      ]);

      //[2] Re-direct
      this.$router.push({ name: "MainTransactions" });
    },
    parseCountriesData(countries, type) {
      if (countries.length === 0 || !Array.isArray(countries)) return [];

      let data = [];
      countries.forEach((item) => {
        data.push({
          nationality: this.getCountry(item?.country),
          amount:
            this.metric === "average"
              ? type === "inbound"
                ? item?.tenant_customers_avg_deposit
                : item?.tenant_customers_avg_spend
              : type === "inbound"
              ? item?.tenant_customers_total_deposit
              : item?.tenant_customers_total_spend,
          count: item?.count,
        });
      });

      return data;
    },
    toggleCountryModal(state, title) {
      this.fullChartTitle = title;
      this.countryChartIsFullscreen = state ?? !this.countryChartIsFullscreen;
    },
    toggleModal(state, title) {
      this.fullBankCardTitle = title;
      this.banksCardIsFullscreen = state ?? !this.banksCardIsFullscreen;
    },
    toggleCurrenciesModal(state, title) {
      this.fullCurrenciesCardTitle = title;
      this.currenciesCardIsFullscreen =
        state ?? !this.currenciesCardIsFullscreen;
    },
    setUpUserData(payload) {
      payload = payload ?? this.userPayload;

      let _avg = this.metric == "average";

      this.userData.total_users = payload.data.total_users_count;
      this.userData.delta_total_users = 0; //! missing

      this.userData.delta_avg_exp = payload.data.rel_precent_total_users_spend;

      let _divisor = _avg ? payload.data.total_users_count ?? 1 : 1;

      this.userData.period_avg =
        (payload.data.total_users_spend ?? 0) / _divisor;

      this.userData.frequency =
        this.periodFilter == 7 // ? or '7-day'
          ? "weekly"
          : this.periodFilter == 30
          ? "monthly"
          : this.periodFilter == 90
          ? "3-month"
          : "yearly";

      switch (this.periodFilter) {
        case 7:
        case 30:
        case 90:
          this.userData.unit = _avg ? "Avg/day" : "Total spend";

          this.userData.avg_exp = _avg
            ? payload.data.total_users_spend_daily_avg
            : payload.data.total_users_spend;
          this.userData.rel_avg_exp = _avg
            ? payload.data.rel_total_users_spend_daily_avg
            : payload.data.rel_total_users_spend;
          break;
        default:
          this.userData.unit = _avg ? "Avg/month" : "Total spend";
          this.userData.frequency = "yearly";

          this.userData.avg_exp = _avg
            ? payload.data.total_users_spend_monthly_avg
            : payload.data.total_users_spend;
          this.userData.rel_avg_exp = _avg
            ? payload.data.rel_total_users_spend_monthly_avg
            : payload.data.rel_total_users_spend;
          break;
      }
    },
    parseTxData(res) {
      const reponse = res.data;

      return {
        transactions: reponse?.tenant_customers_transactions ?? [],
        total_records: reponse?.tenant_customers_transactions_count ?? 0,
      };
    },
    getTransfersTransactions(reload = true) {
      this.$store.dispatch("CANCEL_PENDING_REQUESTS");
      this.transactionsLoading = reload;

      if (reload) {
        this.transactionsData = {};
      }

      OverviewAPI.getAllUsersTransactions({
        ...this.filters,
        category_ids: [13], //Filter Txs by transfers category
        page: 1,
        per_page: 5,
        sort_by: this.sortObject?.field, // amount , transaction_date
        order: this.sortObject?.order == -1 ? "desc" : "asc",
      })
        .then((res) => {
          this.transactionsData = this.parseTxData(res);
          this.transactionsLoading = false;
        })
        .catch((error) => {
          if (error !== "canceled") {
            this.transactionsData = {};
            this.transactionsLoading = false;
            console.error("error: ", error);
          }
        });
    },
    fetchData() {
      this.$store.dispatch("CANCEL_PENDING_REQUESTS");
      let filters = { ...this.filters };

      this.loadingInboundCountries = true;
      TransfersAPI.getCountriesTransfers(filters, this.metric, "inbound")
        .then((response) => {
          if (this.metric === "average")
            this.InBoundcountryDist = this.parseCountriesData(
              response.data?.tenant_customers_transfers ?? [],
              "inbound"
            );
          else {
            this.InBoundcountryDist = this.parseCountriesData(
              response.data?.tenant_customers_transfers ?? [],
              "inbound"
            );
          }

          this.loadingInboundCountries = false;
        })
        .catch((error) => {
          if (error !== "canceled") {
            this.InBoundcountryDist = [];
            this.loadingInboundCountries = false;
            console.error("error: ", error);
          }
        });

      this.loadingOutboundCountries = true;
      TransfersAPI.getCountriesTransfers(filters, this.metric, "outbound")
        .then((response) => {
          this.outBoundcountryDist = response.data;

          if (this.metric === "average")
            this.outBoundcountryDist = this.parseCountriesData(
              response.data?.tenant_customers_transfers ?? [],
              "outbound"
            );
          else {
            this.outBoundcountryDist = this.parseCountriesData(
              response.data?.tenant_customers_transfers ?? [],
              "outbound"
            );
          }
          this.loadingOutboundCountries = false;
        })
        .catch((error) => {
          if (error !== "canceled") {
            this.outBoundcountryDist = [];
            this.loadingOutboundCountries = false;
            console.error("error: ", error);
          }
        });

      this.loadingInboundBanks = true;
      TransfersAPI.getBanksTransfers(filters, this.metric, "inbound")
        .then((response) => {
          if (this.metric === "average")
            this.inBoundBanks = response.data?.tenant_customers_transfers ?? [];
          else {
            this.inBoundBanks = response.data?.tenant_customers_transfers ?? [];
          }

          this.loadingInboundBanks = false;
        })
        .catch((error) => {
          if (error !== "canceled") {
            this.inBoundBanks = [];
            this.loadingInboundBanks = false;
            console.error("error: ", error);
          }
        });

      this.loadingOutboundBanks = true;
      TransfersAPI.getBanksTransfers(filters, this.metric, "outbound")
        .then((response) => {
          if (this.metric === "average")
            this.outBoundBanks =
              response.data?.tenant_customers_transfers ?? [];
          else {
            this.outBoundBanks =
              response.data?.tenant_customers_transfers ?? [];
          }
          this.loadingOutboundBanks = false;
        })
        .catch((error) => {
          if (error !== "canceled") {
            this.outBoundBanks = [];
            this.loadingOutboundBanks = false;
            console.error("error: ", error);
          }
        });

      this.loadingInboundCurrencies = true;
      TransfersAPI.getCurrenciesTransfers(filters, this.metric, "inbound")
        .then((response) => {
          //[1] check if empty
          if (
            Object.keys(response.data?.tenant_customers_transfers)?.length === 0
          ) {
            this.inBoundCurrencies = [];
          } else {
            this.inBoundCurrencies =
              response.data?.tenant_customers_transfers ?? [];
          }

          //[2] update loading
          this.loadingInboundCurrencies = false;
        })
        .catch((error) => {
          if (error !== "canceled") {
            this.inBoundCurrencies = [];
            this.loadingInboundCurrencies = false;
            console.error("error: ", error);
          }
        });

      this.loadingOutboundCurrencies = true;
      TransfersAPI.getCurrenciesTransfers(filters, this.metric, "outbound")
        .then((response) => {
          this.outBoundCurrencies =
            response.data?.tenant_customers_transfers ?? [];
          this.loadingOutboundCurrencies = false;
        })
        .catch((error) => {
          if (error !== "canceled") {
            this.outBoundCurrencies = [];
            this.loadingOutboundCurrencies = false;
            console.error("error: ", error);
          }
        });
    },
  },
};
</script>
<style>
.bg-transcluscent {
  background: rgba(100, 100, 100, 0.2);
}
</style>
