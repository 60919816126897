<template>
  <div
    :class="{ cp: isClickable }"
    class="
      d-flex
      justify-content-start
      align-items-center
      gap-3
      lu-base-summ-card
    "
  >
    <div
      class="
        lu-base-summ-card-img
        d-flex
        justify-content-center
        align-items-center
      "
    >
      <img v-if="icon" :src="icon" alt="Users summary icon" />
    </div>
    <div class="d-flex flex-column">
      <h6>{{ title }}</h6>
      <div class="d-flex gap-3 flex-row align-items-center">
        <h5 class="p-0 m-0" :class="{ clickable: isClickable }">
          {{ amount }}
          <span v-if="showCurrency && $store.state.currency">{{
            " " + (currency ?? $store.state.currency)
          }}</span>
        </h5>
        <template v-if="showTrendingIcon">
          <img
            v-if="!isTrendingPositively"
            class="trending-icon"
            src="@/assets/images/svg/components/Trending-Down.svg"
            alt="trneding icon" />
          <img
            v-else
            class="trending-icon"
            src="@/assets/images/svg/components/Trending-UP.svg"
            alt="trneding icon"
        /></template>
      </div>
    </div>
  </div>
</template>
<script>
export default {
  props: {
    title: {
      type: String,
      required: true,
    },
    amount: {
      type: String,
      required: true,
    },
    icon: {
      type: String,
      required: true,
      default: "",
    },
    isTrendingPositively: {
      type: Boolean,
      required: true,
      default: true,
    },
    showCurrency: {
      type: Boolean,
      default: false,
    },
    currency: {
      type: String,
      default: null,
    },
    isClickable: {
      type: Boolean,
      default: false,
    },
    showTrendingIcon: {
      type: Boolean,
      default: true,
    },
  },
};
</script>
<style scoped lang="scss">
.lu-base-summ-card {
  h6 {
    font-size: var(--fs-input) !important;
    color: var(--dark-green-50, #809795);
    font-weight: 400 !important;
    padding-bottom: 0.15rem;
  }
  h5 {
    color: var(--sea);
    font-size: 1.4rem;
    font-weight: 400 !important;
  }
  .trending-icon {
    width: 25px;
  }
  .lu-base-summ-card-img {
    border-radius: 10px;
    border: 1px solid var(--dark-green-10, #e6eaea);
    padding: 0.5rem;
  }
  .lu-base-summ-card-img img {
    width: 35px;
  }
}
</style>
