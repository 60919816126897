import RestService from "../services/RestService";
import UsersviewAPI from "@/services/api/UsersviewAPI";
import store from "@/store/index";

export default {
  state() {
    return {
      appliedFilters: {},
      timeFilters: {},
      metricFilters: { key: "total", value: "Total spend" },
      segments: [],
      loadingSegments: false,
      categories: [],
      loadingCategories: false,
      transactionTypes: [],
      loadingTransactionTypes: false,
      transferPurposes: [],
      loadingTransferPurposes: false,
      userAccounts: [],
      loadingUserAccounts: false,
      showFiltersBar: true,

      // == brands ==
      clearSelectedBrands: false,
      brandsFilterCurrentState: [],
      selectedBrandsFilter: [],

      // == categories ==
      clearSelectedCategories: false,
      categoriesFilterCurrentState: [],
      selectedCategoriesFilter: [],
    };
  },
  getters: {
    timeDaysCount(state) {
      const daysCount = state.timeFilters?.periodFilter ?? 30;
      let data = { days: daysCount, key: "monthly" };

      switch (true) {
        case daysCount <= 31:
          data.key = "daily";
          break;

        case daysCount > 31 && daysCount < 365:
          data.key = "weekly";
          break;

        case daysCount >= 365:
          data.key = "monthly";
          break;
      }

      return data;
    },
    filters(state) {
      const filtersObject = state.appliedFilters;
      const filtersRequest = {
        tenant_transaction_from_date: filtersObject?.from_date,
        tenant_transaction_to_date: filtersObject?.to_date,
      };

      //[1] Map Nationalities
      if (
        "nationality" in filtersObject &&
        filtersObject?.nationality?.length !== 0
      ) {
        filtersRequest["tenant_customer_nationalities"] =
          filtersObject?.nationality.map((item) => item?.key);
      }

      //[2] Map Genders
      if ("gender" in filtersObject && filtersObject?.gender?.length !== 0) {
        filtersRequest["tenant_customer_genders"] = filtersObject?.gender.map(
          (item) => (item?.key ?? " ")[0].toUpperCase()
        );
      }

      //[3] Map Segments
      if ("segment" in filtersObject && filtersObject?.segment?.length !== 0) {
        filtersRequest["tenant_customer_account_segment_ids"] =
          filtersObject?.segment.map((item) => item?.key);
      }

      //[4] Map Categories
      if (
        "account_category" in filtersObject &&
        filtersObject?.account_category?.length !== 0
      ) {
        filtersRequest["tenant_customer_account_category_ids"] =
          filtersObject?.account_category.map((item) => item?.key);
      }

      //[5] Map Currency
      if (
        "currency" in filtersObject &&
        filtersObject?.currency?.length !== 0
      ) {
        filtersRequest["tenant_transaction_currency_codes"] =
          // filtersObject?.currency[0]?.key;
          filtersObject?.currency.map((item) => item?.key.trim());
      } else {
        filtersRequest["tenant_transaction_currency_codes"] = [
          store.state.currency.trim(),
        ];
      }

      //[6] Map Ages
      if ("age" in filtersObject && filtersObject?.age?.length !== 0) {
        filtersRequest["tenant_customers_ages"] = [
          { ...filtersObject?.age[0] },
        ];
      }

      //[7] Map Incomes
      if ("income" in filtersObject && filtersObject?.income?.length !== 0) {
        filtersRequest["tenant_customers_incomes"] = [
          { ...filtersObject?.income[0] },
        ];
      }

      return filtersRequest;
    },
    multiCurrencyFilters(state) {
      const filtersObject = { ...state.appliedFilters };
      const filtersRequest = {};

      //[1] Map Multi Currencies
      if (
        "multi_currency" in filtersObject &&
        filtersObject?.multi_currency?.length !== 0
      ) {
        filtersRequest["tenant_transaction_currency_codes"] =
          filtersObject?.multi_currency.map((item) => item?.key.trim());
      }

      return filtersRequest;
    },
    transactionTypesFilters(state) {
      const filtersObject = { ...state.appliedFilters };
      const filtersRequest = {};

      //[1] Map transaction types keys
      if (
        "transaction_types" in filtersObject &&
        filtersObject?.transaction_types?.length !== 0
      ) {
        filtersRequest["transaction_types"] = [
          ...filtersObject?.transaction_types.map((item) => item?.key),
        ];
      }
      return filtersRequest;
    },

    transferPurposesFilters(state) {
      const filtersObject = { ...state.appliedFilters };
      const filtersRequest = {};

      //[1] Map transaction types keys
      if (
        "transfer_purposes" in filtersObject &&
        filtersObject?.transfer_purposes?.length !== 0
      ) {
        filtersRequest["transfer_purposes"] = [
          ...filtersObject?.transfer_purposes.map((item) => item?.key),
        ];
      }
      return filtersRequest;
    },

    userFilters(state) {
      const filtersObject = state.appliedFilters;
      const filtersRequest = {
        tenant_transaction_from_date: filtersObject?.from_date,
        tenant_transaction_to_date: filtersObject?.to_date,
      };

      //[1] Map User Accounts Types - account_id
      if (
        "account_id" in filtersObject &&
        filtersObject?.account_id?.length !== 0
      ) {
        filtersRequest["tenant_customer_account_ids"] =
          filtersObject?.account_id.map((item) => item?.key);
      }

      //[2] Map User Currencies
      if (
        "user_currency" in filtersObject &&
        filtersObject?.user_currency?.length !== 0
      ) {
        filtersRequest["tenant_transaction_currency_codes"] =
          filtersObject?.user_currency.map((item) => item?.key.trim());
      }

      return filtersRequest;
    },
    brandsFilter(state) {
      return state.selectedBrandsFilter?.map((item) => item?.id);
    },
    categoriesFilter(state) {
      return state.selectedCategoriesFilter?.map((item) => item?.id);
    },
  },
  mutations: {
    updateClearSelectedBrands(state, payload) {
      state.clearSelectedBrands = payload;
    },
    updateClearSelectedCategories(state, payload) {
      state.clearSelectedCategories = payload;
    },
    updateBrandsFilterCurrentState(state, payload) {
      state.brandsFilterCurrentState = [...payload];
    },
    updateCategoriesFilterCurrentState(state, payload) {
      state.categoriesFilterCurrentState = [...payload];
    },
    updateSelectedBrandsFilter(state, payload) {
      state.selectedBrandsFilter = [...payload];
    },
    updateSelectedCategoriesFilter(state, payload) {
      state.selectedCategoriesFilter = [...payload];
    },
    updateSegments(state, payload) {
      state.segments = [...payload];
    },
    updateUserAccounts(state, payload) {
      state.userAccounts = [...payload];
    },
    updateLoadingSegments(state, payload) {
      state.loadingSegments = payload;
    },
    updateCategories(state, payload) {
      state.categories = [...payload];
    },
    updateLoadingCategories(state, payload) {
      state.loadingCategories = payload;
    },
    updateTransactionTypes(state, payload) {
      state.transactionTypes = [...payload];
    },
    updateTransferPurposes(state, payload) {
      state.transferPurposes = [...payload];
    },
    updateLoadingTransactionTypes(state, payload) {
      state.loadingTransactionTypes = payload;
    },
    updateLoadingTransferPurposes(state, payload) {
      state.loadingTransferPurposes = payload;
    },
    updateLoadingUserAccounts(state, payload) {
      state.loadingUserAccounts = payload;
    },
    updateShowFiltersBar(state, payload) {
      state.showFiltersBar = payload;
    },
    updateAppliedFilters(state, payload) {
      state.appliedFilters = { ...payload };
    },
    updateTimeFilters(state, payload) {
      state.timeFilters = { ...payload };
    },
    updateSelectedMetrics(state, payload) {
      state.metricFilters = { ...payload };
    },
  },
  actions: {
    getFilterSegements({ commit }) {
      const cachedSegments = JSON.parse(localStorage.getItem("segments"));
      if (cachedSegments?.length) {
        commit("updateSegments", cachedSegments);
        commit("updateLoadingSegments", false);
      } else {
        commit("updateLoadingSegments", true);
      }

      RestService.getFilterSegements()
        .then((res) => {
          let data = res.data?.tenant_customer_account_segments ?? [];
          let segmentsList = [];

          if (data.length !== 0)
            data.forEach((item) => {
              segmentsList.push({
                key: item?.id,
                value: item?.name,
              });
            });
          localStorage.setItem("segments", JSON.stringify(segmentsList));
          commit("updateSegments", segmentsList);
        })
        .catch((error) => {
          console.error("Failed to fetch segment", error);
          commit("updateSegments", []);
        })
        .finally(() => {
          commit("updateLoadingSegments", false);
        });
    },
    getFilterCategories({ commit }) {
      const cachedCategories = JSON.parse(localStorage.getItem("categories"));
      if (cachedCategories?.length) {
        commit("updateCategories", cachedCategories);
        commit("updateLoadingCategories", false);
      } else {
        commit("updateLoadingCategories", true);
      }

      RestService.getFilterCategories()
        .then((res) => {
          let data = res.data?.tenant_customer_account_categories ?? [];
          let categoriesList = [];

          if (data.length !== 0)
            data.forEach((item) => {
              categoriesList.push({
                key: item?.id,
                value: item?.name,
              });
            });
          localStorage.setItem("categories", JSON.stringify(categoriesList));
          commit("updateCategories", categoriesList);
        })
        .catch((error) => {
          console.error("Failed to fetch: ", error);
          commit("updateCategories", []);
        })
        .finally(() => {
          commit("updateLoadingCategories", false);
        });
    },
    getFilterTransactionTypes({ commit }) {
      const cachedTransactionTypes = JSON.parse(
        localStorage.getItem("transactionTypes")
      );
      if (cachedTransactionTypes?.length) {
        commit("updateTransactionTypes", cachedTransactionTypes);
        commit("updateLoadingTransactionTypes", false);
      } else {
        commit("updateLoadingTransactionTypes", true);
      }

      RestService.getFilterTransactionTypes()
        .then((res) => {
          let data = res.data?.data ?? res?.data ?? [];
          let typesList = [];

          if (data.length !== 0)
            data.forEach((item) => {
              typesList.push({
                key: String(item?.[0] ?? ""),
                value: String(item?.[1] ?? ""),
              });
            });

          localStorage.setItem("transactionTypes", JSON.stringify(typesList));
          commit("updateTransactionTypes", typesList);
        })
        .catch((error) => {
          console.error("Failed to fetch transaction types: ", error);
          commit("updateTransactionTypes", []);
        })
        .finally(() => {
          commit("updateLoadingTransactionTypes", false);
        });
    },

    getFilterTransferPurposes({ commit }) {
      const cachedTransactionTypes = JSON.parse(
        localStorage.getItem("transferPurposes")
      );
      if (cachedTransactionTypes?.length) {
        commit("updateTransferPurposes", cachedTransactionTypes);
        commit("updateLoadingTransferPurposes", false);
      } else {
        commit("updateLoadingTransferPurposes", true);
      }

      RestService.getFilterTransferPurposes()
        .then((res) => {
          let data = res.data?.data ?? res?.data ?? [];
          let optionsList = [];

          if (data.length !== 0)
            data.forEach((item) => {
              optionsList.push({
                key: String(item?.id ?? ""),
                value: String(item?.name ?? ""),
              });
            });

          localStorage.setItem("transferPurposes", JSON.stringify(optionsList));
          commit("updateTransferPurposes", optionsList);
        })
        .catch((error) => {
          console.error("Failed to fetch transaction types: ", error);
          commit("updateTransferPurposes", []);
        })
        .finally(() => {
          commit("updateLoadingTransferPurposes", false);
        });
    },

    getUserAccounts({ commit, getters }, userID) {
      commit("updateLoadingUserAccounts", true);
      const { tenant_transaction_from_date, tenant_transaction_to_date } =
        getters?.filters ?? {};

      UsersviewAPI.getUserAccounts(
        { tenant_transaction_from_date, tenant_transaction_to_date },
        userID,
        {
          isAbortable: false,
        }
      )
        .then((res) => {
          let data = res.data?.tenant_customer;
          let accountsList = [];

          if ((data?.accounts ?? []).length !== 0) {
            data.accounts.forEach((item, index) => {
              accountsList.push({
                key: item?.account_id,
                value:
                  `(${item.account_currency}) ` +
                  (item?.account_name || `Account ${index + 1}`), //if empty account name, show account index
                currency: item.account_currency,
              });
            });
          }

          commit("updateUserAccounts", accountsList);
        })
        .catch((error) => {
          console.error("Failed to fetch: ", error);
          commit("updateUserAccounts", []);
        })
        .finally(() => {
          commit("updateLoadingUserAccounts", false);
        });
    },
  },
};
