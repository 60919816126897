<template>
  <div class="d-flex justify-content-start align-items-center">
    <PageTitleCategoryIcon
      v-if="route?.name === 'UserCategoryDetails'"
      :icon="icon"
    />
    <PageTitleBrandIcon v-if="route?.name === 'SpecificBrand'" />
  </div>
</template>

<script>
import PageTitleBrandIcon from "./PageTitleBrandIcon.vue";
import PageTitleCategoryIcon from "./PageTitleCategoryIcon.vue";
import { useRoute } from "vue-router";

export default {
  components: { PageTitleCategoryIcon, PageTitleBrandIcon },
  props: {
    icon: {
      type: String,
      default: "",
    },
  },
  setup(props) {
    const route = useRoute();

    return { route };
  },
};
</script>

<style lang="scss" scoped></style>
