<template>
  <div class="d-flex flex-column">
    <div v-if="'total_records' in data" class="pb-3">
      <h6 class="py-1">
        <strong>Showing {{ (data?.transactions ?? []).length }}</strong> out of
        {{ compactNumber(data?.total_records ?? 0) }} transactions
      </h6>
    </div>

    <UsersTransactions
      :table-rows="data?.transactions ?? []"
      :rows="rows"
      :total-records="data?.total_records ?? 0"
      :show-paginator="false"
      :is-summarized-table="true"
      :loading="loading"
      :columns="columns"
      @page-change="onPageChange"
      @sort-change="($event) => $emit('onSortChange', $event)"
      @updateTable="$emit('updateTable')"
    />

    <div
      v-if="showViewAllBtn"
      class="w-100 mb-2 d-flex justify-content-end align-items-start"
    >
      <BaseSmallButton
        class="text-decoration-underline"
        label="All Transactions"
        :end-icon="require('@/assets/images/svg/components/right-arrow.svg')"
        @click="$emit('view-all-clicked')"
      />
    </div>
  </div>
</template>
<script>
import { inject, ref } from "vue";
import UsersTransactions from "@/components/UsersTransactions.vue";
import BaseSmallButton from "../components/helpers/BaseSmallButton.vue";

export default {
  components: { UsersTransactions, BaseSmallButton },
  props: {
    data: {
      type: Object,
      default: () => {},
    },
    showViewAllBtn: {
      type: Boolean,
      default: true,
    },
    loading: {
      type: Boolean,
      default: false,
    },
    columns: {
      type: Array,
      default: () => ["all"],
    },
    rows: {
      type: Number,
      default: 5,
    },
  },
  emits: ["updateTable", "view-all-clicked", "onSortChange"],
  setup() {
    const compactNumber = inject("compactNumber");
    const selectedPage = ref(1);

    const onPageChange = (pageObj) => {
      selectedPage.value = pageObj?.page + 1 ?? 1;
    };

    return {
      onPageChange,
      compactNumber,
    };
  },
};
</script>
<style scoped>
div h6 {
  color: var(--dark-green-50, #809795);
  font-size: 0.9rem !important;
  font-weight: 400;
}
div h6 strong {
  font-weight: 600;
}
</style>
