<template>
  <nav class="w-100 mb-2 px-4">
    <div
      class="
        w-100
        nav-container
        d-flex
        justify-content-center
        align-items-center
        position-relative
      "
    >
      <div
        class="
          ms-auto
          end-container
          d-flex
          justify-content-center
          align-items-center
          gap-3
          py-3
        "
      >
        <div>
          <NavProfile />
        </div>
      </div>

      <div class="bottom-line"></div>
    </div>
  </nav>
</template>

<script>
import NavProfile from "./NavProfile.vue";

export default {
  components: { NavProfile },
};
</script>

<style>
.nav-container {
  background: white;
}
.nav-container .bottom-line {
  position: absolute;
  bottom: 0;
  right: 0;
  background: #ccd5d4;
  height: 1px;
  width: 100%;
}
.nav-bar-icon {
  width: 20px;
  opacity: 0.7;
  transition: var(--d-ease);
}
.nav-bar-icon:hover {
  opacity: 1;
}
</style>
