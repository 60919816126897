<template>
  <div class="d-flex flex-column justify-content-start align-items-start">
    <!-- Main top nav -->
    <BaseDashboardNavTopBar />
    <!-- Main top nav -->

    <!-- Main dashboard header -->
    <BaseDashboardHeader />
    <!-- Main dashboard header -->

    <!-- Main dashboard filters -->
    <BaseDashboardFilters />
    <!-- Main dashboard filters -->

    <!-- Main dashboard template -->
    <BaseMainDashboardTemplate />
    <!-- Main dashboard template -->
  </div>
</template>

<script>
import BaseMainDashboardTemplate from "../components/helpers/BaseMainDashboardTemplate.vue";
import BaseDashboardFilters from "../components/helpers/BaseDashboardFilters.vue";
import BaseDashboardHeader from "../components/helpers/BaseDashboardHeader.vue";
import BaseDashboardNavTopBar from "@/components/helpers/BaseDashboardNavTopBar.vue";

export default {
  components: {
    BaseDashboardNavTopBar,
    BaseDashboardHeader,
    BaseDashboardFilters,
    BaseMainDashboardTemplate,
  },
};
</script>

<style scoped></style>
