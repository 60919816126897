<template>
  <div class="h-100 pb-4 px-4 hide-scrollbar">
    <div
      class="
        page-header
        mt-2
        d-flex
        justify-content-start
        align-items-center
        gap-5
      "
    >
      <h6 v-if="!userAccountsLoading">
        Account: <span>{{ selectedUserAccount?.account_name || "--" }}</span>
      </h6>
      <h6 v-if="!userAccountsLoading">
        Currency:
        <span>{{ selectedUserAccount?.account_currency || "--" }}</span>
      </h6>
    </div>

    <BaseCardView
      class="col-12 mt-4 py-4"
      title="Overview"
      loading-height="9vh"
      loading-icon="1.8rem"
      :loading="userAccountsLoading || userCategoriesLoading"
    >
      <template #content>
        <CategoryDetailsOverview
          :data="categoryData"
          :currency="selectedUserAccount?.account_currency ?? 'AED'"
          @view-txs-clicked="routeToUserTxs"
        />
      </template>
    </BaseCardView>

    <!-- User Spend Chart -->
    <BaseCardView
      class="col-12 p-4 px-0"
      title="Category Spend"
      :loading="userAccountsLoading || userCategorySpendLoading"
    >
      <template #header>
        <div
          v-if="!userCategorySpendLoading"
          class="
            flex-grow-1
            d-flex
            align-items-center
            justify-content-end
            flex-nowrap
            gap-3
          "
        >
          <BaseAmountTitle
            :show-currency="true"
            :currency="selectedUserAccount?.account_currency || 'AED'"
            :show-trend-sign="true"
            :amount="chartSum?.value"
            :is-trending-positively="chartSum?.diffSign"
            :is-clickable="false"
            @click="null"
          />
        </div>
      </template>

      <template #content>
        <CategorySpendChart
          :data="userCategorySpend"
          :currency="selectedUserAccount?.account_currency ?? 'AED'"
        />
      </template>
    </BaseCardView>
    <!-- ---- ---- ---- -- -->

    <!--  === Top Brand and Sub-category cards ===  -->
    <!-- <div class="w-100 d-flex flex-wrap justify-content-between"> -->
    <!-- Top Brands Table -->
    <!-- <BaseCardView
        class="col-6 p-4 px-0 pe-4"
        title="Top Brand"
        loading-height="6vh"
        loading-icon="1.6rem"
        :loading="userCategoriesLoading"
      >
        <template #content>
          <BaseTopEntryCard
            :label="topBrand?.brand_name"
            :icon="topBrand?.brand_logo_url"
            :amount="topBrand?.amount"
            :amount-diff-sign="topBrand?.amountDiffSign?.diffSign"
            :tx-count="topBrand?.tx_count"
            :tx-count-diff-sign="topBrand?.txCountDiffSign?.diffSign"
            :is-brand-card="true"
            :currency="selectedUserAccount?.account_currency ?? 'XXX'"
            @amount-clicked="null"
          />
        </template>
      </BaseCardView> -->
    <!-- ---- ---- ---- -- -->

    <!-- Top Cats Table -->
    <!-- <BaseCardView
        class="col-6 p-4 px-0 ps-4"
        title="Top Sub Category"
        loading-height="6vh"
        loading-icon="1.6rem"
        :loading="userCategoriesLoading"
      >
        <template #content>
          <BaseTopEntryCard
            :label="topSubCategory?.sub_category_name"
            :amount="topSubCategory?.amount"
            :amount-diff-sign="topSubCategory?.amountDiffSign?.diffSign"
            :tx-count="topSubCategory?.tx_count"
            :tx-count-diff-sign="topSubCategory?.txCountDiffSign?.diffSign"
            :currency="selectedUserAccount?.account_currency ?? 'XXX'"
            @amount-clicked="null"
          />
        </template>
      </BaseCardView> -->
    <!-- ---- ---- ---- -- -->
    <!-- </div> -->
    <!--  ====== XXXXX =====  -->

    <!-- Sub-Categories Chart -->
    <!-- <BaseCardView
      class="col-12 p-4 px-0"
      title="Sub-Categories"
      :loading="userCategoriesLoading || loadingSubCatIcons"
    >
      <template #content>
        <SubCategoriesGroupedBarChart :data="subCatsChartList ?? []" />
      </template>
    </BaseCardView> -->
    <!-- ---- ---- ---- -- -->

    <!--  === User transactions Table ===  -->
    <div class="pb-5">
      <BaseCardView class="col-12 py-4 pb-2" title="Transactions">
        <template #content>
          <UserTransactionsTable
            :data="userTransactions ?? {}"
            style="min-width: 700px; overflow: auto"
            :show-view-all-btn="false"
            :loading="userAccountsLoading || userTransactionsLoading"
            @updateTable="updateTable"
            @view-all-clicked="routeToUserTxs"
            @onSortChange="onSortChange"
          />
        </template>
      </BaseCardView>
    </div>
    <!--  ====== XXXXX =====  -->
  </div>
</template>

<script>
// import SubCategoriesGroupedBarChart from "@/components/SubCategoriesGroupedBarChart.vue";
// import BaseTopEntryCard from "../components/helpers/BaseTopEntryCard.vue";
import UserTransactionsTable from "../components/UserTransactionsTable.vue";
import CategorySpendChart from "../components/CategorySpendChart.vue";
import BaseAmountTitle from "../components/helpers/BaseAmountTitle.vue";
import BaseCardView from "../components/helpers/BaseCardView.vue";
import CategoryDetailsOverview from "../components/CategoryDetailsOverview.vue";
import { ref, computed, onMounted, onUnmounted, watch, inject } from "vue";
import UsersviewAPI from "@/services/api/UsersviewAPI";
// import { categories } from "@/data/options";
import { useDiffCalculator, useDivideCalculator } from "@/composables/Helpers";
import { useStore } from "vuex";
import { useRoute, useRouter } from "vue-router";

export default {
  components: {
    CategoryDetailsOverview,
    BaseCardView,
    BaseAmountTitle,
    CategorySpendChart,
    UserTransactionsTable,
    // BaseTopEntryCard,
    // SubCategoriesGroupedBarChart,
  },
  props: {
    userId: {
      type: String,
      default: "",
    },
    catId: {
      type: String,
      default: "",
    },
  },
  setup(props) {
    const checkIfImageExists = inject("checkIfImageExists");

    const sortObject = ref({
      field: "amount",
      order: -1,
    });

    const userAccountsLoading = ref(false);
    const userAccounts = ref({});

    const subCatsChartList = ref([]);
    const loadingSubCatIcons = ref(false);

    const userCategoriesList = ref({});
    const userCategoriesLoading = ref(false);

    const userCategorySpend = ref([]);
    const userCategorySpendLoading = ref(false);

    const userTransactions = ref({});
    const userTransactionsLoading = ref(false);

    const store = useStore();
    const route = useRoute();
    const router = useRouter();

    // === Computed ===
    const filters = computed(() => {
      return store.getters.filters;
    });
    const requestFilters = computed(() => {
      const reqFilters = { ...filters.value };
      delete reqFilters.tenant_transaction_currency_codes;

      return reqFilters;
    });
    const metric = computed(() => store.state.overViewStore.metricFilters);
    const timeFilters = computed(() => store.state.overViewStore.timeFilters);
    const routeParams = computed(() => route.params);
    const categoryData = computed(() => {
      return userCategoriesList.value;
    });
    const chartSum = computed(() => {
      let data = { value: 0, diffSign: true };
      const values = userCategorySpend.value ?? [];

      if (values.length !== 0) {
        //[1] current sum
        const currSum = values.reduce((acc, curr) => {
          if (metric.value?.key === "average") {
            const currAvg = useDivideCalculator(
              curr?.tenant_customer_total_spend,
              curr?.tenant_customer_transactions_count
            );
            return acc + Math.abs(currAvg ?? 0);
          } else return acc + Math.abs(curr?.tenant_customer_total_spend ?? 0);
        }, 0);

        //[2] rel sum
        const relSum = values.reduce((acc, curr) => {
          if (metric.value?.key === "average") {
            const oldAvg = useDivideCalculator(
              curr?.comparison_period_tenant_customer_total_spend,
              curr?.comparison_period_tenant_customer_transactions_count
            );

            return acc + Math.abs(oldAvg ?? 0);
          } else
            return (
              acc +
              Math.abs(curr?.comparison_period_tenant_customer_total_spend ?? 0)
            );
        }, 0);

        //[3] sum diff
        const diff = useDiffCalculator(currSum, relSum);

        data = { value: currSum, diffSign: diff?.diffSign };
      }

      return data;
    });

    const selectedUserAccount = computed(() => {
      const accounts = userAccounts.value?.accounts ?? [];
      if (accounts.length === 0) return {};

      return accounts.find(
        (item) => item?.account_id == routeParams.value.accountId
      );
    });

    const topSubCategory = computed(() => {
      //[1] exit if empty with {}
      if ((categoryData.value?.sub_categories ?? []).length === 0) return {};

      //[2] return the top sub-cat depending on the selected metric Avg/Total

      //[2.1] define the amount key
      let topAmountKey = "avg_tx_spend";
      if (metric.value?.key === "average") topAmountKey = "avg_tx_spend";
      else topAmountKey = "total_spend";

      //[2.2] get the top sub-cat
      const topSubCat = categoryData.value?.sub_categories.reduce(
        (acc, curr) => {
          return (curr[topAmountKey] ?? 0) > (acc[topAmountKey] ?? 0)
            ? curr
            : acc;
        }
      );

      //[2.3] calc the amount and diffSign
      const currAmount = topSubCat?.[topAmountKey] ?? 0;
      const relAmount = topSubCat?.["rel_" + topAmountKey] ?? 0;

      const amountDiffSign = useDiffCalculator(currAmount, relAmount);
      const txCountDiffSign = useDiffCalculator(
        topSubCat?.tx_count ?? 0,
        topSubCat?.rel_tx_count ?? 0
      );

      return {
        ...topSubCat,
        amount: currAmount,
        amountDiffSign,
        txCountDiffSign,
      };
    });
    const topBrand = computed(() => {
      if (Object.keys(topSubCategory.value) === 0) return {};

      //[1] get amount key
      let amountKey = "avg_tx_spend";
      if (metric.value?.key === "average") amountKey = "avg_tx_spend";
      else amountKey = "total_spend";

      //[2] amountKey
      const currAmount = topSubCategory.value?.top_brand?.[amountKey] ?? 0;
      const relAmount =
        topSubCategory.value?.top_brand?.["rel_" + amountKey] ?? 0;

      const amountDiffSign = useDiffCalculator(currAmount, relAmount);
      const txCountDiffSign = useDiffCalculator(
        topSubCategory.value?.top_brand?.tx_count ?? 0,
        topSubCategory.value?.top_brand?.rel_tx_count ?? 0
      );

      return {
        ...topSubCategory.value?.top_brand,
        amount: currAmount,
        amountDiffSign,
        txCountDiffSign,
      };
    });

    // === Parsers ===
    const parseUserCategoriesList = (res) => {
      const response = res?.data;
      const daysCount = timeFilters.value?.periodFilter;

      if (Object.keys(response)?.length !== 0) {
        const singleCat = response;

        const catObject = {
          ...singleCat,

          total_spend: Math.abs(singleCat?.tenant_customer_total_spend),
          rel_total_spend: Math.abs(
            singleCat?.comparison_period_tenant_customer_total_spend
          ),

          avg_spend_per_tx: Math.abs(singleCat?.tenant_customer_avg_spend),

          rel_avg_spend_per_tx: Math.abs(
            singleCat?.comparison_period_tenant_customer_avg_spend
          ),

          avg_spend_per_day: Math.abs(
            useDivideCalculator(
              singleCat?.tenant_customer_total_spend,
              daysCount
            )
          ),

          rel_avg_spend_per_day: Math.abs(
            useDivideCalculator(
              singleCat?.comparison_period_tenant_customer_total_spend,
              daysCount
            )
          ),

          tx_count: singleCat?.tenant_customer_transactions_count,
          rel_tx_count:
            singleCat?.comparison_period_tenant_customer_transactions_count,

          sub_categories: [...(singleCat?.sub_categories ?? [])],
        };

        return {
          ...catObject,
        };
      }

      return {};
    };
    const parseTxData = (res) => {
      const reponse = { ...res.data };

      return {
        transactions: reponse?.tenant_customers_transactions ?? [],
        total_records: reponse?.tenant_customers_transactions_count ?? 0,
      };
    };

    // === Methods ===
    const updateTable = () => {
      getUserTransactions(false);
    };

    const getUserAccounts = () => {
      userAccountsLoading.value = true;

      UsersviewAPI.getUserAccounts(
        requestFilters.value,
        routeParams.value?.userId
      )
        .then((res) => {
          userAccounts.value = { ...res.data?.tenant_customer };
          userAccountsLoading.value = false;
        })
        .catch((error) => {
          if (error !== "canceled") {
            userAccounts.value = {};
            userAccountsLoading.value = false;

            //[1] check if user does not exists route to users table
            if (error.message === "User not found") {
              router.push({ name: "UsersView" });
              console.error("error: ", error);
            }
          }
        });
    };

    const getUserSepcificCategory = () => {
      userCategoriesLoading.value = true;

      UsersviewAPI.getUserSepcificCategory(
        {
          ...requestFilters.value,
          tenant_customer_account_ids: [routeParams.value?.accountId],
        },
        props.userId,
        props.catId
      )
        .then((res) => {
          userCategoriesList.value = parseUserCategoriesList(res);
          userCategoriesLoading.value = false;
        })
        .catch((error) => {
          if (error !== "canceled") {
            userCategoriesList.value = {};
            userCategoriesLoading.value = false;
            console.error("error: ", error);
          }
        });
    };
    const getUserCategorySpend = () => {
      userCategorySpendLoading.value = true;

      UsersviewAPI.getUserCategorySpend(
        {
          ...requestFilters.value,
          tenant_customer_account_ids: [routeParams.value?.accountId],
        },
        timeFilters.value?.periodFilter,
        props.catId,
        props.userId
      )
        .then((res) => {
          userCategorySpend.value = res.data?.category ?? [];
          userCategorySpend.value = userCategorySpend.value.map((item) => {
            const avg_txn_value = useDivideCalculator(
              Math.abs(item.tenant_customer_total_spend),
              Math.abs(item.tenant_customer_transactions_count)
            );

            const rel_avg_txn_value = useDivideCalculator(
              Math.abs(item.comparison_period_tenant_customer_total_spend),
              Math.abs(
                item.comparison_period_tenant_customer_transactions_count
              )
            );

            return { ...item, avg_txn_value, rel_avg_txn_value };
          });
          userCategorySpendLoading.value = false;
        })
        .catch((error) => {
          if (error !== "canceled") {
            userCategorySpend.value = [];
            userCategorySpendLoading.value = false;
            console.error("error: ", error);
          }
        });
    };
    const getUserTransactions = (reload = true) => {
      userTransactionsLoading.value = reload;
      if (reload) {
        userTransactions.value = {};
      }

      UsersviewAPI.getUserTransactions({
        ...requestFilters.value,
        tenant_customer_id: props.userId,
        tenant_customer_account_ids: [routeParams.value?.accountId],
        category_ids: [props.catId],
        page: 1,
        per_page: 5,
        sort_by: sortObject.value?.field, // amount , transaction_date
        order: sortObject.value?.order == -1 ? "desc" : "asc",
      })
        .then((res) => {
          userTransactions.value = parseTxData(res);
          userTransactionsLoading.value = false;
        })
        .catch((error) => {
          if (error !== "canceled") {
            userTransactions.value = {};
            userTransactionsLoading.value = false;
            console.error("error: ", error);
          }
        });
    };
    const onSortChange = (sortObj) => {
      //[1] Set new sort option
      sortObject.value = { ...sortObj };

      //[2] Fetch new tx's
      getUserTransactions(true);
    };

    const routeToUserTxs = () => {
      //Route to user transactions screen with userID
      // if (route.params?.userId && route.query?.user_name)
      //   router.push({
      //     name: "MainUserTransactions",
      //     params: {
      //       userId: route.params?.userId,
      //     },
      //     query: {
      //       user_name: route.query?.user_name,
      //     },
      //   });
    };

    const fetch = () => {
      if (selectedUserAccount.value?.account_currency?.length) {
        getUserSepcificCategory();
        getUserCategorySpend();
        getUserTransactions();
      }
    };

    //=== Watchers + Hooks ===
    onMounted(() => {
      getUserAccounts();
      fetch();
    });
    onUnmounted(() => {
      store.dispatch("CANCEL_PENDING_REQUESTS");
    });

    watch(filters, (newVal, oldVal) => {
      if (
        newVal?.tenant_transaction_from_date &&
        oldVal?.tenant_transaction_from_date
      ) {
        //[1] Abort all previous pending requests
        store.dispatch("CANCEL_PENDING_REQUESTS");

        //[2] Fetch
        getUserAccounts();
        fetch();
      }
    });
    watch(selectedUserAccount, (newVal, oldVal) => {
      const previousId = oldVal?.account_id;
      const currentId = newVal?.account_id;
      if (currentId && currentId !== previousId) fetch();
    });

    watch(categoryData, (category) => {
      //[0] Empty Case
      if ((category?.sub_categories ?? []).length === 0) {
        subCatsChartList.value = [];
        return;
      }

      //[1] Set the sub-cat list
      loadingSubCatIcons.value = true;
      subCatsChartList.value = [...category.sub_categories];

      // //[2] Get the brand logo URL
      subCatsChartList.value.forEach((cat, index) => {
        let iconSource = require("@/assets/images/svg/components/no_brand_logo.svg");
        const imageURL = `${process.env.VUE_APP_IMG_ORIGINAL_BASE_URL}${cat?.top_brand?.brand_logo_url}`;

        //[2.1] chech if the icon exists
        checkIfImageExists(imageURL, (exists) => {
          if (exists) {
            iconSource = imageURL;
          }

          //[2.2] set the output icon to the sub-cat top_brand object
          subCatsChartList.value[index] = {
            ...subCatsChartList.value[index],
            top_brand: {
              ...subCatsChartList.value[index]?.top_brand,
              logo: iconSource,
            },
          };

          //[2.3] disable chart loading after last sub-cat item is covered
          if (index === subCatsChartList.value.length - 1) {
            loadingSubCatIcons.value = false;
          }
        });
      });
    });

    return {
      userCategoriesList,
      userCategoriesLoading,
      userCategorySpend,
      userCategorySpendLoading,
      userTransactions,
      userTransactionsLoading,
      categoryData,
      chartSum,
      updateTable,
      topSubCategory,
      topBrand,
      subCatsChartList,
      loadingSubCatIcons,
      routeToUserTxs,
      selectedUserAccount,
      userAccountsLoading,
      onSortChange,
    };
  },
};
</script>

<style lang="scss" scoped>
.page-header h6 {
  color: var(--dark-green-60, #66827f);
  font-size: 0.88rem !important;
  font-weight: 400;

  span {
    color: var(--dark-green-100, #002e2a);
  }
}
</style>
