function storeMainFilters(filters) {
  const appliedFilters = { ...filters };

  Object.keys(appliedFilters).forEach((key) => {
    if (
      appliedFilters[key] === undefined ||
      appliedFilters[key] === null ||
      appliedFilters[key].length === 0 ||
      Object.keys(appliedFilters[key]).length === 0
    ) {
      delete appliedFilters[key];
    }
  });

  //store in localstorage
  const jsonString = JSON.stringify(appliedFilters);
  // Store the JSON string in localStorage
  localStorage.setItem("mainFilters", jsonString);

  return appliedFilters;
}

function retrieveMainFilters() {
  // Retrieve the JSON string from localStorage
  const retrievedJsonString = localStorage.getItem("mainFilters");

  // Convert the JSON string back to an object
  const retrievedObject = JSON.parse(retrievedJsonString);

  return retrievedObject ?? {};
}

function getSpecificCachedFilter(filterKey) {
  const filters = retrieveMainFilters();
  return filters[filterKey];
}

export { storeMainFilters, retrieveMainFilters, getSpecificCachedFilter };
